import React, { FC } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Select,
  MenuItem,
  IconButton,
  TableContainer,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { styled } from "@mui/system";
import Digital from "../../assets/image-carnes/digital.png";
import Fisico from "../../assets/image-carnes/fisico.png";

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "232px",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
  "&::-webkit-scrollbar": {
    width: "6px",
    display: "flex",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#D9D9D9",
    borderRadius: "4px",
  },
});

const StyledTableBody = styled(TableBody)({
  maxHeight: "350px",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#D9D9D9",
    borderRadius: "4px",
  },
});

type Props = {
  arr: any[];
  removeLine: any;
  qtd: string;
  handle: any;
};

const TableProduct: FC<Props> = ({ arr, removeLine, qtd, handle }) => {
  const theme = useTheme();

  return (
    <Paper elevation={3} sx={{ width: "700px", height: "285px" }}>
      <StyledTableContainer>
        <Table size="small" stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow
              style={{
                height: "20px",
              }}
            >
              <TableCell style={{ width: `120px`, backgroundColor: "#1C1C7B" }}>
                {""}
              </TableCell>
              <TableCell style={{ width: `365px`, backgroundColor: "#1C1C7B" }}>
                <Typography
                  variant="subtitle1"
                  color="#FFFFFF"
                  fontWeight={theme.typography.fontWeightBold}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Parcelas
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{ width: "100px", backgroundColor: "#1C1C7B" }}
              >
                <Typography
                  variant="subtitle1"
                  color="#FFFFFF"
                  fontWeight={theme.typography.fontWeightBold}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Total
                </Typography>
              </TableCell>
              <TableCell style={{ backgroundColor: "#1C1C7B" }} align="right">
                <Typography
                  variant="subtitle1"
                  color="#FFFFFF"
                  fontWeight={theme.typography.fontWeightBold}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Remover
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </StyledTableContainer>
      <StyledTableContainer>
        <Table size="small" stickyHeader aria-label="a dense table">
          <TableBody>
            {arr.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <img
                    src={item?.tipoCarne === "Físico" ? Fisico : Digital}
                    style={{ width: `60px`, height: `60px` }}
                    alt="Carnê do Baú"
                  />
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="subtitle2"
                      color="#000000"
                      sx={{
                        fontSize: "12px",
                        marginTop: "15px",
                      }}
                    >
                      {`${item.numero}ª Parcela carnê: série ${item.serie} | número ${item.numeroCarne}-${item.digito}`}
                    </Typography>
                    {item.type === "carne" && (
                      <div style={{ marginLeft: "20px" }}>
                        <Typography
                          variant="subtitle2"
                          color="#1C1C7B"
                          fontWeight={theme.typography.fontWeightBold}
                          sx={{
                            fontSize: "10px",
                          }}
                        >
                          Antecipação de parcelas
                        </Typography>
                        <Select
                          value={qtd}
                          onChange={handle}
                          variant="outlined"
                          style={{
                            height: "30px",
                            width: "65px",
                            marginLeft: "25%",
                          }}
                        >
                          {[...Array(12)].map((_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color="#000000"
                    fontWeight={theme.typography.fontWeightBold}
                  >
                    {item.valor.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    sx={{ color: "#001C47" }}
                    onClick={() => removeLine(item.id)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Paper>
  );
};

export default TableProduct;
