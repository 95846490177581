import React from "react";
import {
  StyledSkeletonTableBody,
  StyledSkeletonTableHeader,
} from "./SkeletonParcelas.styled";

const SkeletonParcelas = () => {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <StyledSkeletonTableHeader variant="text" />
          </th>
          <th>
            <StyledSkeletonTableHeader variant="text" />
          </th>
          <th>
            <StyledSkeletonTableHeader variant="text" />
          </th>
        </tr>
      </thead>
      <tbody>
        {[...Array(2)].map((_, index) => (
          <tr key={index}>
            <td>
              <StyledSkeletonTableBody variant="text" />
            </td>
            <td>
              <StyledSkeletonTableBody variant="text" />
            </td>
            <td>
              <StyledSkeletonTableBody variant="text" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SkeletonParcelas;
