import React, { useState } from "react";
import { Box, Modal, Button, Typography } from "@mui/material";

import { baseURLWithoutToken } from "../../../../../api/axios";

interface ModalCancelRecurringPaymentProps {
  modalRemove: boolean;
  setModalRemove(value: boolean): void;
  ticketNumber: string;
  token: string;
  getCarnes: () => Promise<void>;
}

export const ModalCancelRecurringPayment: React.FC<
  ModalCancelRecurringPaymentProps
> = ({ modalRemove, setModalRemove, ticketNumber, token, getCarnes }) => {
  const handleDeleteRecurring = async () => {
    try {
      await baseURLWithoutToken.delete(
        `api/v01/carne/cancelar-pagamento-recorrente?numeroCarne=${ticketNumber}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
            "Content-Type": "application/json",
            "token-user": token,
          },
        }
      );
      getCarnes();
    } catch (error: any) {
      console.log("erro ao remover pagamento recorrente");
    }
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={modalRemove}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 300,
          height: 350,
          backgroundColor: "#FFFFFF",
          boxShadow: 24,
          p: 1,
        }}
      >
        <Typography
          component="p"
          variant="subtitle1"
          color="#000000"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            lineHeight: "20px",
            textAlign: "center",
            fontWeight: 700,
            margin: "20px 27px 17px 27px",
          }}
        >
          Remover pagamento em recorrência no cartão
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          color="#000000"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            lineHeight: "20px",
            textAlign: "center",
            fontWeight: 400,
            margin: "0 27px 17px 27px",
          }}
        >
          Deseja remover o pagamento mensal de suas parcelas em seu cartão de
          crédito?
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          color="#000000"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            lineHeight: "20px",
            textAlign: "center",
            fontWeight: 400,
            margin: "0 27px 17px 27px",
          }}
        >
          Atenção: após a remoção, as parcelas não serão mais pagas
          automaticamente a cada mês.
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          color="#000000"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            lineHeight: "20px",
            textAlign: "center",
            fontWeight: 400,
            margin: "0 27px 17px 27px",
          }}
        >
          Deseja confirmar o cancelamento da assinatura desse carnê?
        </Typography>
        <div
          style={{
            margin: "0 27px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => setModalRemove(false)}
            sx={{
              width: 104,
              height: 33,
              fontSize: "12px",
              background: "#FFFFFF",
              color: "#F08C10",
              border: "1px solid #F08C10",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteRecurring}
            sx={{
              width: 104,
              height: 33,
              fontSize: "12px",
              background: "#F08C10",
              color: "#FFFFFF",
              "&:hover": {
                background: "#F08C10",
                color: "#FFFFFF",
              },
            }}
          >
            Confirmar
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
