import React, { FC, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination, Box } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F08C10",
    },
  },
});

interface Props {
  rows: any[];
  columns: any[];
}

const pageSize = 5;

const DataTable: FC<Props> = ({ rows, columns }) => {
  const [page, setPage] = useState(1);

  const totalPages = rows ? Math.ceil(rows.length / pageSize) : 0;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const startRow = (page - 1) * pageSize;
  const endRow = page * pageSize;
  const paginatedRows =
    rows && rows.length > 0 ? rows.slice(startRow, endRow) : [];

  const CustomPagination: FC = () => null;

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <DataGrid
        sx={{
          fontSize: "12.5px",
          color: "#000000",

          "& .MuiDataGrid-cell--withRenderer": {
            display: "flex",
            justifyContent: "center",
          },
          "& .MuiDataGrid-cellContent": {
            whiteSpace: "break-spaces",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            display: "flex",
            justifyContent: "center",
            whiteSpace: "break-spaces",
          },
        }}
        rows={paginatedRows}
        columns={columns}
        loading={paginatedRows.length === 0}
        autoHeight
        slots={{
          pagination: CustomPagination,
        }}
        hideFooterSelectedRowCount
        disableColumnSelector
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "30px 0",
        }}
      >
        <ThemeProvider theme={theme}>
          <Pagination          
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
            size="small"
          />
        </ThemeProvider>
      </div>
    </Box>
  );
};

export default DataTable;
