import { FC } from "react";
import { Typography, Grid } from "@mui/material";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useIsMobile } from "../../utils/utils";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContainerBox from "../ContainerBox";

type Props = {
  handleDrawer: any;
};

const MinhaContaPerfil: FC<Props> = ({ handleDrawer }) => {
  const auth = useAuth();
  const user = auth?.user;
  const isMobile = useIsMobile();

  return (
    <ContainerBox>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isMobile && (
            <ArrowBackIosIcon
              onClick={handleDrawer}
              sx={{ fontSize: "32px", color: "#1C1C7B" }}
            />
          )}
          <Typography variant="h1" sx={{ padding: "0 15px" }}>
            Olá, {user && user.nome}
          </Typography>
        </div>
        <div>
          <Typography
            variant="subtitle1"
            color="#000000"
            sx={{
              padding: "0 15px",
              "& a": {
                color: "red",
              },
            }}
          >
            <p>
              Aqui é onde você poderá visualizar e editar todos os dados da sua
              conta, como:
              <a href="/" style={{ color: "#F08C10", fontWeight: 700 }}>
                {" "}
                seus pedidos
              </a>
              ,
              <a href="/" style={{ color: "#F08C10", fontWeight: 700 }}>
                {" "}
                atualizar suas informações
              </a>{" "}
              ou
              <a href="/" style={{ color: "#F08C10", fontWeight: 700 }}>
                {" "}
                acompanhar
              </a>{" "}
              e
              <a href="/" style={{ color: "#F08C10", fontWeight: 700 }}>
                {" "}
                gerenciar seus carnês
              </a>
              , e muito mais!
            </p>
          </Typography>
          <Typography
            variant="subtitle1"
            color="#000000"
            sx={{
              padding: "0 15px",
            }}
          >
            <p>
              Minha Conta: onde você pode consultar as informações da sua conta.
            </p>
            <p>
              Carrinho. Veja os produtos que você já adicionou ao seu carrinho
              de compras.
            </p>
            <p>E muito mais!</p>
          </Typography>
        </div>
      </div>
    </ContainerBox>
  );
};

export default MinhaContaPerfil;
