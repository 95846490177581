import axios from 'axios'

// export const API = axios.create({
//   baseURL: `${process.env.REACT_APP_URL_API}`
// });

// export const Instance = axios.create({
//   baseURL: `${process.env.REACT_APP_URL_AUTHENTICATION}`,
//   headers: {
//     Authorization: `Basic SW50ZWdyYWNhb01hZ2VudG9BcGk6Vlg0SEdENkw1WDUzMTMwR0RNOFYxOFhKTzI3RlFRMExaSDVJWVVQRkI5OEM2`
//   }
// });

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`
});

export const apiProd = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROD}`
});

// export const apiTokenProd = async () => {
//   const authorization = axios.create({
//     baseURL: `${process.env.REACT_APP_API_PROD}`,
//   });

//   const data = {
//     chave: "VX4HGD6L5X53130GDM8V18XJO27FQQ0LZH5IYUPFB98C6",
//     nomeDaAplicacao: "IntegracaoMagentoApi"
//   }

//   try {
//     const response = await authorization.get('busca-access-token', data);
//     const tokenSystem = response.data;
//     return response.data;

//   } catch (error: any) {
//     return error.message;
//   }
// };

export const apiToken = async () => {
  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': 'Basic SW50ZWdyYWNhb01hZ2VudG9BcGk6Vlg0SEdENkw1WDUzMTMwR0RNOFYxOFhKTzI3RlFRMExaSDVJWVVQRkI5OEM2'
    },
  });

  try {
    const response = await authorization.get('busca-access-token');
    return response.data;

  } catch (error: any) {
    return error.message;
  }
};


