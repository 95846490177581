import React from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Header, Content } from "./styles";

interface ModalAlertProps {
  open: boolean;
  setOpen(value: boolean): void;
}

export const ModalAlert: React.FC<ModalAlertProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog onClose={handleClose} open={open} className="p-3">
      <Header>
        <WarningAmberIcon />
      </Header>
      <Content>
        <Box mb={3}>
          <Typography variant="h4" color="#000000">
            <strong>Atenção</strong>
          </Typography>
        </Box>
        <Typography variant="subtitle1" color="#000000">
          Você não precisa mais enviar o seu cupom impresso, porque o Baú irá
          imprimir e colocar nas urnas do SBT para você!
        </Typography>
        <button
          className="px-6 py-2 border-2 border-[#F08C10] text-[#F08C10] rounded-md mt-5"
          onClick={handleClose}
        >
          OK
        </button>
      </Content>
    </Dialog>
  );
};
