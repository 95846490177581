export const regulamento = [
  {
    label: 'Carnê do Baú',
    id: '0',
    doc: 'institucional/regulamento-novo-carne-bau.pdf',
    description: ` <p>
                        <strong>
                          REGULAMENTO PARA VENDA DE MERCADORIAS A VAREJO, MEDIANTE OFERTA
                          PÚBLICA E RECEBIMENTO PARCIAL OU TOTAL, DO RESPECTIVO PREÇO DENOMINADO
                          “CARNÊ DO BAÚ DA FELICIDADE JEQUITI”.
                        </strong>
                      </p>
                      <p>
                        <strong>
                          SECAP/ME Nº
                        </strong>
                      </p>
                      <p>
                        <strong>
                          1. NOME, END. E INSCRIÇÃO NO CNPJ/ME.
                        </strong><br>
                        SS Comércio de Cosméticos e Produtos de Higiene Pessoal Ltda. – <strong>“JEQUITI” </strong><br>
                        Avenida das Comunicações, 927, Pavilhão B (4) - Galpão 01- Bairro Industrial.<br>
                        Osasco/ SP – CEP 06276-906 – CNPJ/MF: 07.278.350/0001-63<br>
                        FONE: 0800 718 9700 e 4004-3846 – SITE: <strong><a href='https://bau.jequiti.com.br/' target='_blank'>bau.jequiti.com.br</a></strong><br>
                      </p>
                      <p>
                        <strong>2. NOME DA PROMOÇÃO:</strong> “Carnê do Baú da Felicidade Jequiti”.
                      <p>
                      <p>
                        <strong>3. ÁREA DE EXECUÇÃO DA PROMOÇÃO:</strong>  Todo o território nacional.
                      <p>
                      <p>
                        <strong>4. PERÍODO DE EXECUÇÃO DA OPERAÇÃO: </strong> 01/12/2022 A 01/12/2024.
                      <p>
                      <p>
                        4.1. Os prazos do presente regulamento serão computados com base nos pagamentos de <br>
                        todas as mensalidades que deverão ser efetuados dentro do período de validade do <br>
                        carnê, ou seja, dentro dos 12 (dez) meses a contar da data do pagamento da 1ª (primeira) <br>
                        parcela do carnê.
                      </p>
                      <p>
                        <strong>5. NÚMERO TOTAL DE CARNÊ</strong>
                      <p>
                      <p>
                        5.1. Para essa promoção, serão utilizados 2.500.000 (dois milhões e quinhentos mil) <br>
                        carnês, com 25 (vinte e cinco) séries diferentes, tendo em vista que cada série permite <br>
                        gerar 100.000 (cem mil carnês), ressaltando que até o dia 30 de abril de 2023, serão <br>
                        impressos 886.00 (oitocentos e oitenta e seis mil) carnês, sendo que cada carnê terá 12 <br>
                        parcelas e cada parcela terá o valor unitário de R$ 15,00 (quinze reais), terá o carnê o valor <br>
                        total de R$ 180,00 (cento e oitenta reais) e que a partir do dia 01 de maio de 2023 serão <br>
                        impressos 1.614.000 (um milhão e seiscentos e quatorze mil) carnês, sendo que cada <br>
                        carnê terá 12 parcelas e cada parcela terá o valor unitário de R$ 18,00 (dezoito reais), terá <br>
                        o carnê o valor total de R$ 216,00 (duzentos e dezesseis reais) com prazo de validade de <br>
                        12 (doze) meses, a contar da data do pagamento da 1ª (primeira) parcela.
                      <p>
                      <p>
                        <strong>6. DO OBJETO E CADASTRO DA PROMOÇÃO</strong>
                      <p>
                      <p>
                        6.1. O titular do plano poderá, ao final do pagamento das 12 (doze) parcelas, resgatar em <br>
                        produtos o valor pago, devidamente corrigido pela TR, tendo como sugestão <strong>“Um kit <br>
                        contendo duas Colônias Jequiti (masculina e/ou feminina) de 100 ml”</strong> ou outra <br>
                        mercadoria escolhida, desde que disponível em estoque e que não esteja alocado pela <br>
                        vendedora para cumprimento de estratégias promocionais ou kits de datas <br>
                        comemorativas direcionadas às suas consultoras de venda.
                      <p>
       `,
  },
  {
    label: 'Pião da Felicidade',
    img: true,
    id: '1',
    doc: 'institucional/Regulamento-Piao-da-Felicidade.pdf',
    numeroCertificado: 'SPA/ME Nº 06.035110/2024',
    nameRegulamento: 'Pião da Felicidade do Baú',
    description: `
                      <p>
                        1 - EMPRESAS PROMOTORAS:
                      </p>
                      <p>
                      1.1 - Empresa Mandatária:
                      Razão Social: SS COMERCIO DE COSMETICOS E PRODUTOS DE HIGIENE PESSOAL LTDA<br>
                      Endereço: DAS COMUNICACOES Número: 927 Complemento: GALPAOINDUSTRIAL PAVLH B (4)<br>
                      Bairro: INDUSTRIAL ANHANGUERA Município: OSASCO UF: SP CEP:06276-906<br>
                      CNPJ/MF nº: 07.278.350/0001-63
                      </p>
                      <p>
                        2 - MODALIDADE DA PROMOÇÃO:<br>
                        Assemelhado a Concurso
                      </p>
                      <p>
                        3 - ÁREA DE ABRANGÊNCIA:<br>
                        Todo o território nacional.
                      <p>
                      <p>
                        4 - PERÍODO DA PROMOÇÃO:<br>
                        05/07/2024 a 15/09/2024
                      <p>
                      <p>
                        5 - PERÍODO DE PARTICIPAÇÃO:<br>
                        05/07/2024 a 14/09/2024
                      <p>
                      <p>
                        6 - CRITÉRIO DE PARTICIPAÇÃO:<br>
                        Promoção "Pião da Felicidade"
                      </p>
                      <p>
                      Para participar da promoção o interessado deverá efetuar a compra de um Carnê do Baú da Felicidade Jequiti, efetuar em dia o pagamento das parcelas, preencher o cupom e depositá-lo nas urnas localizadas nos pontos de comercialização do Carnê. Após a identificação do pagamento das parcelas, a empresa promotora irá imprimir os cupons do cliente (modalidade digital) e depositar na urna de sorteio.
                      </p>
                      <p>
                      Os cupons retirados nos dias dos sorteios voltarão à urna. <br/> O período para envio dos respectivos cupons a partir do dia 07/07/2024 até 25/08/2024.
                      </p>
       `,
  },
  {
    label: 'MEGA SORTEIO',
    img: true,
    id: '2',
    doc: 'institucional/Regulamento-Mega-Sorteio-do-Bau.pdf',
    nameRegulamento: 'Show de Prêmios do Baú – Mega sorteio da Felicidade',
    numeroCertificado: 'SPA/ME Nº 06.035105/2024',
    description: `
                      <p>
                        1 - EMPRESAS PROMOTORAS:
                      </p>
                      <p>
                      1.1 - Empresa Mandatária:
                      Razão Social: SS COMERCIO DE COSMETICOS E PRODUTOS DE HIGIENE PESSOAL LTDA<br>
                      Endereço: DAS COMUNICACOES Número: 927 Complemento: GALPAOINDUSTRIAL PAVLH B (4)<br>
                      Bairro: INDUSTRIAL ANHANGUERA Município: OSASCO UF: SP CEP:06276-906<br>
                      CNPJ/MF nº: 07.278.350/0001-63
                      </p>
                      <p>
                        2 - MODALIDADE DA PROMOÇÃO:<br>
                        Assemelhado a Concurso
                      </p>
                      <p>
                        3 - ÁREA DE ABRANGÊNCIA:<br>
                        Todo o território nacional.
                      <p>
                      <p>
                        4 - PERÍODO DA PROMOÇÃO:<br>
                        07/07/2024 a 22/09/2024
                      <p>
                      <p>
                        5 - PERÍODO DE PARTICIPAÇÃO:<br>
                       07/07/2024 a 20/09/2024
                      <p>
                      <p>
                        6 - CRITÉRIO DE PARTICIPAÇÃO
                      </p>
                      <p>
                      Para participar da promoção o interessado deverá efetuar a compra de um Carnê do Baú da Felicidade Jequiti, efetuar em dia o pagamento
                      das parcelas, preencher o cupom e depositá-lo nas urnas localizadas nos pontos de comercialização do Carnê.  No caso de carnês comercializados na modalidade digital, após a identificação do pagamento das respectivas parcelas, os cupons serão impressos pela empresa promotora e serão depositados na urna do sorteio.
                      </p>
                      <p>
                      Os cupons retirados nos dias dos sorteios voltarão à urna. <br/> O período para envio dos respectivos cupons a partir do dia 07/07/2024 até 20/09/2024, sendo o sorteio realizado no dia 22/09/2024 e a dinâmica para concorrer ao prêmio entre os ganhadores será realizada presencialmente no dia 29/09/2024.
                      </p>
       `,
  },
  {
    label: 'Show de Prêmios',
    img: true,
    id: '3',
    doc: 'institucional/Regulamento_Show_Prêmios.pdf',
    numeroCertificado: 'SRE/ME Nº 06.032569/2024',
    nameRegulamento: 'Show de Prêmios do Baú',
    description: `
                      <p>
                        1 - EMPRESAS PROMOTORAS:
                      </p>
                      <p>
                      1.1 - Empresa Mandatária:
                      Razão Social: SS COMERCIO DE COSMETICOS E PRODUTOS DE HIGIENE PESSOAL LTDA<br>
                      Endereço: DAS COMUNICACOES Número: 927 Complemento: GALPAOINDUSTRIAL PAVLH B (4)<br>
                      Bairro: INDUSTRIAL ANHANGUERA Município: OSASCO UF: SP CEP:06276-906<br>
                      CNPJ/MF nº: 07.278.350/0001-63
                      </p>
                      <p>
                        2 - MODALIDADE DA PROMOÇÃO:<br>
                        Assemelhado a Concurso
                      </p>
                      <p>
                        3 - ÁREA DE ABRANGÊNCIA:<br>
                        Todo o território nacional.
                      <p>
                      <p>
                        4 - PERÍODO DA PROMOÇÃO:<br>
                        01/03/2024 a 29/06/2024
                      <p>
                      <p>
                        5 - PERÍODO DE PARTICIPAÇÃO:<br>
                        01/03/2024 a 28/06/2024
                      <p>
                      <p>
                        6 - CRITÉRIO DE PARTICIPAÇÃO:<br>
                        Promoção "Show de Prêmios do Baú"
                      </p>
                      <p>
                      Para participar da promoção o interessado deverá efetuar a compra de um Carnê do Baú da Felicidade Jequiti, efetuar em dia o pagamento
                      das parcelas, preencher o cupom e depositá-lo nas urnas localizadas nos pontos de comercialização do Carnê.
                      </p>
                      <p>
                      Os cupons retirados nos dias dos sorteios voltarão à urna. <br/> O período para envio dos respectivos cupons a partir do dia 01/03/2024 até 28/06/2024.
                      </p>
       `,
  },

]