import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { map, size } from "lodash";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { ICarne } from "../../../../../types/carne";
import { BehindTheCarnet } from "../BehindTheCarnet";
import { CardImage } from "../CardImage";

import ErroSemResultados from "../../../../ErroSemResultados";
import { CustomPagination } from "../../../../pagination";

interface ICarnetsProps {
  paginatedItems: ICarne[];
  loading: boolean;
  setRecurringPayment(value: boolean): void;
  recurringPayment: boolean;
  setView: any;
  setCode: any;
  setTicketNumber(value: string): void;
  setOpenModalCancelRecurring(value: boolean): void;
  handleParcelDetails(item: any): void;
}

export const Carnets: React.FC<ICarnetsProps> = ({
  paginatedItems,
  loading,
  setRecurringPayment,
  recurringPayment,
  setView,
  setCode,
  setTicketNumber,
  setOpenModalCancelRecurring,
  handleParcelDetails,
}) => {
  const [isView, setIsView] = useState<number | null>(null);
  const [dataPage, setDataPage] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const startIndex = (page - 1) * itemsPerPage;
  const mockLoading = Array(5);
  const totalPages = Math.ceil(paginatedItems.length / itemsPerPage);

  const carnets = paginatedItems.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Box className="grow">
      {map(carnets, (item, index) => (
        <Box
          width={{ xs: "20rem", md: "35rem" }}
          height={{ xs: "14rem", md: "11rem" }}
          display="flex"
          gap={2}
          key={item.numero}
          mb={3}
          onMouseMove={() => setIsView(index)}
          onMouseLeave={() => setIsView(-1)}
        >
          {isView === index ? (
            <>
              <BehindTheCarnet
                setRecurringPayment={setRecurringPayment}
                recurringPayment={recurringPayment}
                data={item}
                dataPage={dataPage}
                setView={setView}
                setCode={setCode}
                setTicketNumber={setTicketNumber}
                setOpenModalCancelRecurring={setOpenModalCancelRecurring}
              />
              <Box
                className="flex justify-center items-center border-[#293EA8] w-10 rounded cursor-pointer border-[1px]"
                onClick={() => handleParcelDetails(item)}
              >
                <ArrowForwardIosIcon />
              </Box>
            </>
          ) : (
            <Box
              width={{ xs: "20rem", md: "38.5rem" }}
              height={{ xs: "14rem", md: "11rem" }}
              display="flex"
              gap={2}
            >
              <CardImage status={item.status} type={item?.tipoCarne} />
              <Box className="flex justify-center items-center border-[#293EA8] w-10 rounded cursor-pointer border-[1px]">
                <ArrowForwardIosIcon />
              </Box>
            </Box>
          )}
        </Box>
      ))}
      {loading &&
        map(mockLoading, (_, index) => (
          <Skeleton variant="rounded" key={index} className="mt-10">
            <Box
              width={{ xs: "20rem", md: "35rem" }}
              height={{ xs: "14rem", md: "11rem" }}
            ></Box>
          </Skeleton>
        ))}

      {!loading && !paginatedItems.length && (
        <div className="flex items-center justify-center">
          <ErroSemResultados />
        </div>
      )}
      {size(paginatedItems) > 5 && (
        <Box className="flex justify-center  md:w-[36rem]">
          <CustomPagination
            numberOfPages={totalPages}
            page={page}
            setPage={setPage}
          />
        </Box>
      )}
    </Box>
  );
};
