import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Container } from "./Endereco.styled";
import { cepMask, useIsMobile } from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";

interface EnderecoCardProps {
  endereco: any;
  setEditCard: any;
  setInsertCard: any;
  setFormData: any;
}

const EnderecoCard: React.FC<EnderecoCardProps> = ({
  endereco,
  setEditCard,
  setInsertCard,
  setFormData,
}) => {
  const auth = useAuth();
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null);
  const [name, setName] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      handleDescript(token);
    }
  }, []);

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(item);
        handleData(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleData = async (token: any) => {
    setName(token.nome + " " + token.sobrenome);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, address: any) => {
    const target = event.currentTarget as HTMLElement;
    setAnchorEl(target);
    setSelectedAddress(address);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setFormData((prevState: any) => ({
      ...prevState,
      id: selectedAddress?.id,
      cep: cepMask(selectedAddress?.cep),
      endereco: selectedAddress?.rua,
      bairro: selectedAddress?.bairro,
      cidade: selectedAddress?.cidade,
      uf: selectedAddress?.uf,
      numero: selectedAddress?.numero,
      complemento: selectedAddress?.complemento,
      referencia: selectedAddress?.referencia,
      principal: selectedAddress?.principal,
    }));
    setEditCard(true);
  };

  const handleSetClick = () => {
    setFormData((prevState: any) => ({
      ...prevState,
      id: "",
      cep: "",
      endereco: "",
      bairro: "",
      cidade: "",
      uf: "",
      numero: "",
      complemento: "",
      referencia: "",
      principal: "",
    }));
    setInsertCard(true);
  };

  return (
    <Container
      style={{
        height: `${isMobile ? "500px" : "450px"}`,
        width: `${isMobile ? "300px" : "454px"}`,
      }}
    >
      <Box
        sx={{
          maxHeight: "469px",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "4px",
          },
        }}
      >
        {endereco
          ?.sort((a: any, b: any) =>
            a.principal === true ? -1 : b.principal === true ? 1 : 0
          )
          .map((items: any, index: any) => (
            <Card
              key={index}
              sx={{
                marginBottom: "4px",
                boxShadow: "none",
                border: "none",
                backgroundColor: `${items.principal ? "#DBDDE1" : "#FFFFFF"}`,
                borderLeft: `${items.principal ? "3px solid #000000" : "none"}`,
                borderBottom: "0.3px solid rgba(0, 0, 0, 0.20)",
                "&: hover": {
                  boxShadow: "0px 0px 5px 1px #DBDDE1",
                  borderLeft: "3px solid #000000",
                  transition: "box-shadow 0.3s ease",
                  backgroundColor: " #DBDDE1",
                },
              }}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography variant="h6">{`${name}`}</Typography>
                    <Typography
                      variant="body1"
                      className="body1"
                      color="#231F20"
                    >
                      {items.rua}, {items.numero} - {items.complemento}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="body1"
                      color="#231F20"
                    >
                      {items.referencia}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="body1"
                      color="#231F20"
                    >
                      {items.bairro} - {items.cidade} - {items.uf}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="body1"
                      color="#231F20"
                    >
                      {items.cep}
                    </Typography>
                    {items.principal && (
                      <Typography
                        color="#F08C10"
                        variant="body1"
                        sx={{ fontWeight: 600, fontSize: 14 }}
                      >
                        {"Endereço Principal"}
                      </Typography>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div>
                      <IconButton
                        aria-label="settings"
                        style={{
                          width: "fit-content",
                          color: "#1C1C7B",
                        }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleClick(event, items)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </div>
                    <div>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            height: 30,
                            width: 85,
                            backgroundColor: "#FFF",
                            overflow: "visible",
                            boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.25)",
                            margin: "auto",
                            mt: 1,
                            "&:hover": {
                              backgroundColor: "#F08C10",
                              overflow: "visible",
                              mt: 1,
                            },
                          },
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                      >
                        <MenuItem
                          onClick={handleEditClick}
                          className="menu-item"
                          sx={{
                            maxHeight: 0,
                            cursor: "pointer",
                            "&:hover": {
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#231F20",
                              fontWeight: 400,
                              fontSize: 14,
                            }}
                          >
                            Editar
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
      </Box>
      <Button
        onClick={handleSetClick}
        sx={{
          width: `${isMobile ? "330px" : "455px"}`,
          height: 48,
          fontSize: "14px",
          margin: "35px 0",
          color: "#F08C10",
          background: "#FFFFFF",
          border: "1px solid #F08C10",
          "&: hover": {
            background: "#FFFFFF",
          },
        }}
      >
        Adicionar endereço
      </Button>
    </Container>
  );
};

export default EnderecoCard;
