import styled from 'styled-components';

export const RodapeSocialMedia = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-around;
  height: min-content;
  img {
    margin-top: 10px;
    width: 23px;
    height: 23px;
  }
`

export const Container = styled.div`
  div {
    display: initial;
  }
  .p-social-media {
      display: initial;
      font-family: Red Hat Display;
      font-size: 12px;
      font-style: italic;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      color: #1C1C7B;
  }
  @media(max-width: 1199px) {
    margin: 20px 0px 10px 0px;
    .p-social-media {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 23px;
    height: 23px;
    }
  }
`