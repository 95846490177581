import { makeStyles, createStyles } from "@mui/styles";
import styled, { css } from 'styled-components'

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 60,
      backgroundColor: "#fff",
    },

    title: {
      fontFamily: "Red Hat Display, sans-serif",
      fontSize: 34,
      fontWeight: 700,
      textAlign: "center",
      color: "#001C47"
    },

    subtitle: {
      fontFamily: "Red Hat Display, sans-serif",
      fontSize: 15,
      color: "#001C47",
      textAlign: "center",
    },

    row: {
      display: "flex",
      gap: 28,
      marginTop: 60
    },
    subRow: {
      display: "flex",
      gap: 14
    },

    checkbox: {
      marginTop: 30
    },

    footerRow: {
      display: "flex",
      gap: 28,
    },

    footer: {
      marginTop: 45
    },

    textFooter: {
      display: "flex",
      justifyContent: "start",
      marginBottom: 45,
    },

    footerButtons: {
      display: "flex",
      gap: 40
    }


  })
);

interface ButtonMaterialProps {
  disabled: boolean;
}

export const ButtonMaterial = styled.button <ButtonMaterialProps>`
font-size: 16px;
line-height: 10px;
background-color: #F08C10;
color: #fff;
text-transform:uppercase;
width: 100%;
border-radius: 5px;
border: none;
height: 52px;

${props => props.disabled && css`
background-color:#BDBDBD ;
color: #fff;

`

  }



                
`