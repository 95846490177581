import React from "react";
import { Button, Typography, Grid } from "@mui/material";
import {
  formatCarneResgatado,
  formatarValorEmReais,
  useIsMobile,
} from "../../utils/utils";
import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../DataTable";
import { resgate } from "../../utils/meuscarnes";

const ResgatarProdutos = () => {
  const isMobile = useIsMobile();

  const renderTypography = (headerName: any) => (
    <Typography
      component="p"
      variant="subtitle1"
      fontWeight={700}
      color="#000000"
      sx={{
        fontSize: "10px",
        textTransform: "uppercase",
      }}
    >
      {headerName}
    </Typography>
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Série | Número-DV",
      width: 150,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "tipo",
      headerName: "tipo",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "valorPago",
      headerName: "Valor Pago",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "valorResgate",
      headerName: "Valor Resgate",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "status",
      headerName: "status",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "button",
      headerName: " ",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              width: "80px",
              height: "30px",
              color: "#F08C10",
              fontSize: "12px",
              border: "1px solid #F08C10",
              borderRadius: 0,
              background: "#FFFFFF",
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
          >
            Resgatar
          </Button>
        </div>
      ),
    },
  ];

  
  const rows: any[] = resgate.map((row) => ({
    id: formatCarneResgatado(row.codigoBarras),
    tipo: row.tipo,
    valorPago: formatarValorEmReais(row.valorPago),
    valorResgate: formatarValorEmReais(row.valorResgate),
    status: row.status,
    button: "",
  }));

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "center", md: "left" },
        }}
      >
        <div
          style={{
            flexDirection: "column",
            display: `${isMobile && "flex"}`,
            alignItems: `${isMobile && "center"}`,
            justifyContent: `${isMobile && "center"}`,
          }}
        >
          <div>
            <Typography variant="h1">Resgatar Produtos</Typography>
            <Typography
              variant="subtitle1"
              color="#000000"
              sx={{
                margin: "0 0 30px 0",
                width: { xs: "360px", sm: "360px", md: "454px" },
              }}
            >
              <b>Resgate</b> seus carnês quitados.
            </Typography>
          </div>
          <DataTable rows={rows} columns={columns} />
        </div>
      </Grid>
    </Grid>
  );
};

export default ResgatarProdutos;
