import React, { useEffect, useState } from "react";
import { Box, Switch, Typography } from "@mui/material";
import { Card } from "react-bootstrap";
import { CustomButton, useStyles } from "./styles";
import { ICarne } from "../../../../../types/carne";
import { CustomTooltip } from "../../../Parcelas.styled";
import { useTicket } from "../../../../../store/Ticket/Ticket";
import { useTheme } from "@mui/styles";
import { userStore } from "../../../../../store/user/user";
import { baseURL } from "../../../../../api/axios";
import axios from "axios";

interface BehindTheCarnetProps {
  recurringPayment: boolean;
  setRecurringPayment(value: boolean): void;
  data: ICarne;
  dataPage: string;
  setView(value: boolean): void;
  setCode: (value: string) => void;
  setTicketNumber: (value: string) => void;
  setOpenModalCancelRecurring: (value: boolean) => void;
}

export const BehindTheCarnet: React.FC<BehindTheCarnetProps> = ({
  recurringPayment,
  setRecurringPayment,
  data,
  dataPage,
  setView,
  setCode,
  setTicketNumber,
  setOpenModalCancelRecurring,
}) => {
  const classes = useStyles();
  const setExibirDetalhe = useTicket((state) => state.setExibirDetalhe);
  const { user } = userStore();

  const handleChange = () => {
    setTicketNumber(data?.codigoBarras);
    if (data.possuiAssinatura) {
      setOpenModalCancelRecurring(true);
    } else {
      setRecurringPayment(true);
    }
  };

  function handleParcelDetails() {
    setView(true);
    setCode(data?.codigoBarras);
    setExibirDetalhe(true);
  }

  function disabledButton() {
    return (
      data?.status === "Resgate Iniciado" ||
      data?.status === "Resgatado" ||
      data?.status === "Desistência"
    );
  }
  function StatusName(item: string) {
    if (item === "Resgate Iniciado") return "Resgatando...";
    if (item === "Quitado") return "Resgatar";
    if (item === "Desistência" || item === "Desistência Resgatado")
      return "Ver Pedido";

    if (
      item === "Vinculado ao Cliente" ||
      item === "Enviados ao Cliente" ||
      item === "Suspeito" ||
      item === "Enviados aos Correios" ||
      item === "Pré Tesouro" ||
      item === "Reservado" ||
      item === "Vinculado ao Cliente"
    )
      return "Pagar";
  }

  async function handleRescue() {
    const request = axios.create({
      baseURL: `${process.env.REACT_APP_API}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
        "Content-Type": "application/json",
      },
    });
    try {
      const response = await request.post("api/v01/carne/gerarUrlResgate", {
        codigoBarras: data.codigoBarras,
        cpf: user?.userFiscalPerson,
      });
      window.open(`${response.data.url}`, "_blank");
      console.log("response", response.data.url);
    } catch (error) {
      console.log("error ao gerar url de resgate", error);
    }
  }

  function ButtonAction(item: string) {
    return (
      <CustomTooltip
        disableHoverListener={item !== "Resgate Iniciado"}
        placement="left"
        title={
          <>
            <Typography
              style={{
                display: "flex",
                textAlign: "center",
                margin: "auto",
                justifyContent: "center",
                paddingBottom: 10,
                color: "#000",
                fontWeight: 700,
              }}
              fontSize={16}
            >
              Atenção!
            </Typography>
            <p
              style={{
                fontSize: 14,
                alignItems: "center",
              }}
            >
              <strong>Seu Resgate foi iniciado!</strong> Caso o mesmo não seja
              concluído, <strong>aguarde 30 minutos</strong> que ele voltará a
              ser disponibilizado. Em caso de dúvida ou ajuda, entre em contato
              conosco através da nossa central de atendimento
              <strong> 0800 545 2121</strong>
            </p>
          </>
        }
      >
        <CustomButton
          onClick={() =>
            data.status === "Quitado" ? handleRescue() : handleParcelDetails()
          }
          disabled={disabledButton()}
        >
          {StatusName(item)}
        </CustomButton>
      </CustomTooltip>
    );
  }

  const isShowRecurrence = (item: ICarne): boolean => {
    return (
      (item.quantidadeParcelasPagas < 11 && item.aceitaPagamento) ||
      item.possuiAssinatura
    );
  };

  return (
    <Box
      width={{ xs: "20rem", md: "35rem" }}
      height={{ xs: "14rem", md: "11rem" }}
      display="flex"
      gap={2}
    >
      <Card className={classes.cardDetails}>
        <Box className={classes.main}>
          <Box className={classes.containerNumbers}>
            <Box>
              <p className={classes.title}>SÉRIE</p>
              <Box className={classes.number}>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                  className={classes.subTitle}
                >
                  {data.serie}
                </Typography>
              </Box>
            </Box>
            <Box>
              <p className={classes.title}>NUMERO</p>
              <Box className={classes.number}>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                  className={classes.subTitle}
                >
                  {data.numero}
                </Typography>
              </Box>
            </Box>
            <Box>
              <p className={classes.title}>DV</p>
              <Box className={classes.number}>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                  className={classes.subTitle}
                >
                  {data?.digito}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <p className={classes.title}>TIPO</p>
            <Box className={classes.text}>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                }}
                className={classes.subTitle}
              >
                {data.tipoCarne}
              </Typography>
            </Box>
          </Box>

          <Box className="d-none d-md-block">
            <p className={classes.title}>RECORRÊNCIA</p>
            <Switch
              checked={data.possuiAssinatura}
              onChange={handleChange}
              disabled={!isShowRecurrence(data)}
            />
          </Box>
        </Box>
        <Box className={classes.main}>
          <Box className={classes.containerNumbers}>
            <Box>
              <p className={classes.title}>STATUS</p>
              <Box
                className={classes.status}
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                  className={classes.subTitle}
                >
                  {data.status}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: {
                xs: 5,
              },
            }}
          >
            <p className={classes.title}>CONSULTAR PARCELAS</p>
            <Box
              className={classes.details}
              onClick={() => handleParcelDetails()}
            >
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                }}
                className={classes.subTitle}
              >
                Detalhes
              </Typography>
            </Box>
          </Box>
          <Box className="d-none d-md-block">
            {data.status !== "Tesouro Nacional" &&
              data.status !== "1 e 2 Baixado" &&
              data.status !== "Resgate Devolvido" &&
              data.status !== "Resgatado" &&
              ButtonAction(data.status)}
          </Box>
        </Box>
        <Box className="d-flex align-items-center justify-content-between d-md-none">
          <Box>
            <p className={classes.title}>RECORRÊNCIA</p>
            <Switch
              checked={data.possuiAssinatura}
              onChange={handleChange}
              disabled={!isShowRecurrence(data)}
            />
          </Box>

          <Box>
            {data.status !== "Tesouro Nacional" &&
              data.status !== "1 e 2 Baixado" &&
              data.status !== "Resgate Devolvido" &&
              ButtonAction(data.status)}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
