export const produto = [
    {
        "id": 601,
        "name": "Kit Carnê do Baú com Rebeca Abravanel Desodorante Colônia Feminina Jequiti, 25 ml",
        "price": 55.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-portiolli-gold-jequiti-7.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-Rebeca-Abravanel-Desodorante.jpg`
    },
    {
        "id": 602,
        "name": "Kit Carnê do Baú com Carlinhos Maia Desodorante Colônia Masculina Jequiti, 100ml",
        "price": 164.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-carlinhos-maia-desodorante-colonia-masculina.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-Carlinhos-Maia-Desodorante.jpg`
    },

    {
        "id": 603,
        "name": "Kit Carnê do Baú com Patricia Abravanel Essence Desodorante Colônia Feminina Jequiti, 25ml",
        "price": 55.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-patricia-abravanel-essence-desodorante-colonia-feminina.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-Patricia-Abravanel-Essence.jpg`
    },
    {
        "id": 604,
        "name": "Kit Carnê do Baú com Cand Land Marshmellow Jequiti",
        "price": 41.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-portiolli-gold-jequiti-2.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-Cand-Land-Marshmellow-Jequiti.jpg`
    },
    {
        "id": 605,
        "name": "Kit Carnê do Baú com Portiolli Gold Jequiti",
        "price": 55.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-portiolli-gold-jequiti.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-Portiolli-Gold-Jequiti.jpg`
    },
    {
        "id": 606,
        "name": "Kit Carnê do Baú com Estojo Coleção Estrelas Jequiti, 4 x 25 ml 4 x 25 ml",
        "price": 157.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-portiolli-gold-jequiti-11.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-kit-estrelas.jpg`
    },
    {
        "id": 607,
        "name": "Kit Carnê do Baú com Creme Ultra Hidratante Sensi para Áreas Ressecadas Jequiti 75g",
        "price": 44.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-creme-ultra-hidratante-desodorante-sensi-para-areas-ressecadas.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-Creme-Ultra-Hidratante-Desodorante.jpg`
    },
    {
        "id": 608,
        "name": "Kit Carnê do Baú com Bem-Me-Quer Magia Desodorante Colônia Feminina Jequiti, 25ml ",
        "price": 45.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-bem-me-quer-magia-desodorante-colonia-feminina.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-Bem-Me-Quer-Magia.jpg`
    },
    {
        "id": 609,
        "name": "Kit Carnê do Baú com Sabonete Hidratante em Barra Sortidos Sensi Jequiti 5 x 80 g",
        "price": 39.90,
        "url": `${process.env.REACT_APP_API_BAU}/kit-carne-do-bau-com-portiolli-gold-jequiti-8.html`,
        "image": `${process.env.REACT_APP_IMAGE_SERVER_URL}Kit-Carnê-Baú-sabonetes-hidratantes-barra-sortidos.jpg`
    }
]
