import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Modal, Snackbar, Typography } from "@mui/material";
import { Container, LinkButton } from "./VerificacaoEmail.styled";
import ContainerBox from "../../components/ContainerBox";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useIsMobile } from "../../utils/utils";

const VerificacaoEmail = () => {
  const isMobile = useIsMobile();
  const auth = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState<null | any>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [lastSentTime, setLastSentTime] = useState(0);
  const token = localStorage.getItem("dataClient");

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const minInterval = 30000; // intervalo de 30 segundos

  useEffect(() => {
    scrollToTop();

    if (token) {
      handleDescript(token);
    }

    setTimeout(() => {
      localStorage.removeItem("dataClient");
    }, 1200000); // 20 minutos em milissegundos
  }, [token]);

  const handleDescript = (item: any) => {
    const decodedString = atob(item);
    const userDataObject = JSON.parse(decodedString);
    setUserData(userDataObject);
  };

  const handleSendEmail = async () => {
    if (userData === null || userData === "") {
      setAlertMessage("Erro ao enviar o e-mail");
      setAlertStatus("error");
      setAlertOpen(true);
      return;
    }

    const now = Date.now();
    const elapsedTime = now - lastSentTime;
    if (elapsedTime < minInterval) {
      setAlertMessage("Aguarde alguns segundos antes de enviar outro e-mail");
      setAlertStatus("error");
      setAlertOpen(true);
      return;
    }

    const cpf = userData.cpf;
    const dataNascimento = userData.nascimento;
    const email = userData.email;
    try {
      await sendEmail(cpf, dataNascimento, email);
    } catch (error: any) {
      setAlertMessage(`Erro ao enviar o e-mail: ${error.message || error}`);
      setAlertStatus("error");
      setAlertOpen(true);
    }
  };

  const sendEmail = async (cpf: any, dataNasc: any, email: any) => {
    try {
      await auth?.enviaEmail(cpf, dataNasc, email);
      setLastSentTime((prevTime) => Math.max(prevTime, Date.now()));
      setOpenModal(true);
    } catch (error: any) {
      throw error;
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <ContainerBox>
      <Modal
        open={openModal}
        disableEnforceFocus
        disableAutoFocus
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "300px",
            height: "210px",
            background: "#FFFFFF",
            border: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "58px",
            }}
          >
            <div>
              <Typography
                variant="h2"
                color="#000000"
                sx={{
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                E-mail enviado com sucesso!
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  width: 60,
                  height: 40,
                  backgroundColor: "#F08C10",
                  marginTop: "20px",
                  fontSize: "14px",
                  color: "#FFFFFF",
                  background: "#F08C10",
                  "&: hover": {
                    background: "#F08C10",
                  },
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Container
        style={{
          margin: `${isMobile ? "50px 0px" : "130px 0"}`,
        }}
      >
        <div>
          <Typography variant="h4">Validação de Cadastro</Typography>
          <Typography variant="body1" className="body1">
            Informamos que seu cadastro foi realizado com sucesso!
          </Typography>
          <Typography variant="body1" className="body1">
            Ficamos felizes em ter você conosco :)
          </Typography>
          <Typography variant="body1" className="body1">
            Agora, para a criação de sua senha, precisamos que você acesse o
            link de validação que enviamos no e-mail informado neste cadastro, e
            siga as instruções.
          </Typography>
          <Typography variant="body1" className="body1">
            Não se esqueça de verificar a caixa de Spam.
          </Typography>
          <Typography variant="body1" className="body1"></Typography>
          <Typography
            variant="body1"
            className="body1"
            style={{ fontWeight: 700 }}
          >
            Caso não tenha recebido o link de validação, por favor,
            <LinkButton onClick={() => handleSendEmail()}>
              clique aqui
            </LinkButton>
            para um novo link.
          </Typography>
        </div>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertStatus || "info"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </ContainerBox>
  );
};

export default VerificacaoEmail;
