import React, { useState, FC, useMemo } from "react";
import ContainerBox from "../../components/ContainerBox";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { useIsMobile } from "../../utils/utils";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";
import { useNavigate } from "react-router-dom";
import { baseURLWithoutToken } from "../../api/axios";
import { userStore } from "../../store/user/user";
import { Toast } from "../../components/Toast";

type Props = {
  alert: React.Dispatch<React.SetStateAction<"success" | "error" | null>>;
  message: React.Dispatch<React.SetStateAction<string>>;
  openAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

const Autenticar: FC<Props> = ({ alert, message, openAlert }) => {
  const backOffice = useBackOffice();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [loading, setLoading] = useState(false);
  const setUser = userStore((state) => state.setUser);
  const { user } = userStore();
  const [openSToast, setOpenToast] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const loginSchema = z.object({
    login: z.string().optional(),
    senha: z.string().optional(),
  });

  type LoginFormData = z.infer<typeof loginSchema>;

  const {
    control,
    handleSubmit,
    watch,
    reset,

    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      login: "",
      senha: "",
    },
  });

  const watchPassword = watch("senha");
  const watchLogin = watch("login");

  const isDisabled = useMemo(() => {
    return !watchLogin || !watchPassword;
  }, [watchLogin, watchPassword]);

  const LoginBackOffice = async (login: string, senha: string) => {
    try {
      const request = await baseURLWithoutToken.post(
        "api/v01/login/autenticar",
        { usuario: login, senha: senha }
      );

      const cleanBearerToken = request.data.token.replace(/^Bearer\s+/i, "");
      setUser({ ...user, token: cleanBearerToken });
    } catch (error: any) {
      console.log("error ao fazer login", error);
    }
  };

  const onSubmit = async (data: LoginFormData) => {
    const { login, senha } = data;

    LoginBackOffice(login || "", senha || "");
    try {
      reset();
      setLoading(true);
      const response = await backOffice?.loginBackOffice(login, senha);
      setLoading(false);
      message("Login efetuado com sucesso!");
      alert("success");
      openAlert(true);
      navigate("/autenticar/login");
      return response;
    } catch (error: any) {
      console.log("erro ao fazer login no autenticar", error);
      setOpenToast(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContainerBox
      sx={{
        background: "#1C1C7B",
        width: "100%",
        height: "100vh",
      }}
    >
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "center" },
            margin: "98px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: `${isMobile ? "322px" : "454px"}`,
            }}
          >
            <img
              src={`${process.env.REACT_APP_IMAGE_SERVER_URL}logoJequiti.svg`}
              alt="logo-jequiti"
              className="w-full h-[112px] md:h-[157px] mb-[49px] md:mb-[77px]"
            />
            <Typography
              variant="subtitle1"
              color="#FFFFFF"
              sx={{
                textAlign: "justify",
              }}
            >
              Para realizar o acesso a esta área restrita, para atendimento ao
              cliente, por favor informe seus dados abaixo:
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Typography
                  variant="subtitle2"
                  color="#F08C10"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  Login
                </Typography>

                <Controller
                  name="login"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Username"
                      error={!!errors.login}
                      helperText={errors.login?.message}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "48px",
                        },
                      }}
                      sx={{
                        boxSizing: "border-box",
                        background: "#FFFFFF",
                        border: "1px solid #BDBDBD",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  color="#F08C10"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  Senha
                </Typography>

                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="******"
                      error={!!errors.senha}
                      helperText={errors.senha?.message}
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          height: "48px",
                        },
                      }}
                      sx={{
                        boxSizing: "border-box",
                        background: "#FFFFFF",
                        border: "1px solid #BDBDBD",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Button
                  disabled={isDisabled || loading}
                  type="submit"
                  sx={{
                    width: "100%",
                    height: "48px",
                    marginTop: "30px",
                    fontSize: "14px",
                    color: "#FFFF",
                    background: `${
                      loading || isDisabled ? "#D1D1D1" : "#F08C10"
                    }`,
                    "&: hover": {
                      background: `${
                        loading || isDisabled ? "#D1D1D1" : "#F08C10"
                      }`,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                  ) : (
                    "entrar"
                  )}
                </Button>
                <Typography
                  variant="subtitle1"
                  color="#FFFFFF"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "20px 0",
                  }}
                >
                  Autenticar - área restrita para atendimento
                </Typography>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
      {openSToast && (
        <Toast
          open={openSToast}
          setOpen={setOpenToast}
          type="error"
          title="Credenciais inválidas"
        />
      )}
    </ContainerBox>
  );
};

export default Autenticar;
