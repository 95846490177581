import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Alert, Snackbar } from "@mui/material";
import Home from "./pages/Home";
import Regulamento from "./pages/Regulamento";
import MinhaConta from "./pages/MinhaConta";
import { useHandleSideBar } from "./context/MenuSideBar/useHandleSideBar";
import Login from "./pages/Login/Login";
import Programas from "./pages/Programas";
import SobreBau from "./pages/SobreBau";
import Premios from "./pages/Premios";
import FaleConosco from "./pages/FaleConosco";
import PerguntasFrequentes from "./pages/PerguntasFrequentes";
import QuemSomos from "./pages/QuemSomos";
import PoliticasLegais from "./pages/PoliticasLegais";
import RecuperarSenha from "./pages/RecuperarSenha";
import Checkout from "./pages/Checkout";
import Cadastro from "./pages/Cadastro";
import Autenticar from "./pages/Autenticar/Autenticar";
import VerificacaoEmail from "./pages/VerificacaoEmail";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import NavBarMobile from "./components/NavBarMobile";
import Error404 from "./components/Error404";
import { BackOfficeAutenticated } from "./components/UserAutenticated";
import { useAuth } from "./context/AuthProvider/useAuth";
import { AuthProvider } from "./context/AuthProvider";
import { CartProvider } from "./context/CartProvider";
import { BackOfficeProvider } from "./context/BackOfficeProvider";
import { useBackOffice } from "./context/BackOfficeProvider/useBackOffice";
import { HandleSideBarProvider } from "./context/MenuSideBar/useHandleSideBar";
import { HandleParcelaProvider } from "./context/ContextParcelas/userContextParcelas";
import CadastrarSenha from "./pages/CadastrarSenha";
import { ClientProvider } from "./context/ClientProvider";
import { useIsMobile } from "./utils/utils";
import { theme } from "./styles";
import IdleTimerContainer from "./components/IdleTimer";
import CookieModal from "./components/CookieModal/CookieModal";
import Integracao from "./components/IntegracaoMagento/Integracao";
import Logoff from "./components/IntegracaoMagento/Logoff";
import { useSearchParams } from "react-router-dom";
import { JsxElement } from "typescript";

const NewNotePreview = ({ children }: { children: any }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeSection, setActiveMenu, handleSideBar } = useHandleSideBar();
  const token = searchParams.get("token");
  const rota = searchParams.get("rota");
  const auth = useAuth();
  const navigate = useNavigate();

  if (token != null && rota != null) {
    navigate(`/integracao?token=${token}&rota=${rota}`);
  }
  return children;
};

const App = () => {
  const isMobile = useIsMobile();
  const backOffice = useBackOffice();
  const [alertOpen, setAlertOpen] = useState(false);
  const [tokenBko, setTokenBko] = useState(false);
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const [alertMessage, setAlertMessage] = useState("");
  const backOfficeId = localStorage.getItem("backOfficeId");

  useEffect(() => {
    if (backOfficeId && !backOffice?.isBackOfficeLogged()) setTokenBko(true);
  }, [backOffice, backOfficeId]);

  const Private = ({ children }: { children: any }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (!auth?.isAuthenticated()) {
        navigate("/login");
      }
    }, [auth, navigate]);

    if (!auth?.isAuthenticated()) {
      return null;
    }

    return children;
  };

  const PrivateBackOffice = ({ children }: { children: any }) => {
    const backOffice = useBackOffice();
    const navigate = useNavigate();

    useEffect(() => {
      if (!backOffice?.isBackOfficeLogged()) {
        navigate("/autenticar");
      }
    }, [backOffice, navigate]);

    if (!backOffice?.isBackOfficeLogged()) {
      return null;
    }

    return children;
  };

  const CheckBackOffice = ({ children }: { children: any }) => {
    const backOffice = useBackOffice();
    const navigate = useNavigate();

    useEffect(() => {
      const backOfficeId = localStorage.getItem("backOfficeId");

      // if (backOfficeId && !backOffice?.isBackOfficeLogged()) {
      //   navigate("/404");
      // }
    }, [backOffice, navigate]);

    return children;
  };

  const PrivateAuthBackOffice = ({ children }: { children: any }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (!auth?.isAuthenticated()) {
        navigate("/autenticar/login");
      }
    }, [auth, navigate]);

    if (!auth?.isAuthenticated()) {
      return null;
    }

    return children;
  };

  const FooterStyled = () => {
    const location = useLocation();
    if (
      location.pathname === "/checkout" ||
      location.pathname === "/autenticar" ||
      location.pathname === "/autenticar/login" ||
      location.pathname === "/autenticar/minha-conta" ||
      location.pathname === "/autenticar/cadastro" ||
      location.pathname === "/autenticar/checkout" ||
      location.pathname === "/404"
    ) {
      return null;
    }
    return <Footer />;
  };

  const AuthBackInform = () => {
    const location = useLocation();
    if (location.pathname === "/autenticar") {
      return null;
    }
    return <BackOfficeAutenticated />;
  };

  const NavBarMobileStyled = () => {
    const location = useLocation();
    if (location.pathname === "/autenticar") {
      return null;
    }
    return (
      <NavBarMobile
        alert={setAlertStatus}
        message={setAlertMessage}
        openAlert={setAlertOpen}
      />
    );
  };

  const NavBarStyled = () => {
    const location = useLocation();
    if (location.pathname === "/autenticar") {
      return null;
    }
    return <NavBar />;
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CookieModal />

        <AuthProvider>
          <HandleSideBarProvider>
            <CartProvider>
              <IdleTimerContainer />
              {isMobile ? <NavBarMobileStyled /> : <NavBarStyled />}
              <div>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <NewNotePreview>
                        <CheckBackOffice>
                          <Home />
                        </CheckBackOffice>
                      </NewNotePreview>
                    }
                  />
                  <Route
                    path="/autenticar"
                    element={
                      <BackOfficeProvider>
                        <Autenticar
                          alert={setAlertStatus}
                          message={setAlertMessage}
                          openAlert={setAlertOpen}
                        />
                      </BackOfficeProvider>
                    }
                  />
                  <Route
                    path="/autenticar/login"
                    element={
                      <BackOfficeProvider>
                        <PrivateBackOffice>
                          <AuthBackInform />
                          <div
                            style={{
                              marginTop: isMobile ? 80 : 250,
                              zIndex: 999,
                            }}
                          >
                            <Login
                              alert={setAlertStatus}
                              message={setAlertMessage}
                              openAlert={setAlertOpen}
                            />
                          </div>
                        </PrivateBackOffice>
                      </BackOfficeProvider>
                    }
                  />
                  <Route
                    path="/autenticar/minha-conta"
                    element={
                      <BackOfficeProvider>
                        <PrivateAuthBackOffice>
                          <PrivateBackOffice>
                            <AuthBackInform />
                            <HandleParcelaProvider>
                              <div
                                style={{
                                  marginTop: isMobile ? 80 : 150,
                                  height: "100%",
                                }}
                              >
                                <MinhaConta />
                              </div>
                            </HandleParcelaProvider>
                          </PrivateBackOffice>
                        </PrivateAuthBackOffice>
                      </BackOfficeProvider>
                    }
                  />
                  <Route
                    path="/autenticar/cadastro"
                    element={
                      <BackOfficeProvider>
                        <PrivateBackOffice>
                          <AuthBackInform />
                          <div
                            style={{
                              marginTop: isMobile ? 80 : 80,
                              height: "100%",
                            }}
                          >
                            <Cadastro
                              alert={setAlertStatus}
                              message={setAlertMessage}
                              openAlert={setAlertOpen}
                            />
                          </div>
                        </PrivateBackOffice>
                      </BackOfficeProvider>
                    }
                  />
                  <Route
                    path="/autenticar/checkout"
                    element={
                      <BackOfficeProvider>
                        <PrivateAuthBackOffice>
                          <PrivateBackOffice>
                            <AuthBackInform />
                            <div
                              style={{
                                marginTop: isMobile ? 80 : 80,
                                height: "100%",
                              }}
                            >
                              <Checkout />
                            </div>
                          </PrivateBackOffice>
                        </PrivateAuthBackOffice>
                      </BackOfficeProvider>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <CheckBackOffice>
                        <Login
                          alert={setAlertStatus}
                          message={setAlertMessage}
                          openAlert={setAlertOpen}
                        />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/cadastro"
                    element={
                      <CheckBackOffice>
                        <ClientProvider>
                          <Cadastro
                            alert={setAlertStatus}
                            message={setAlertMessage}
                            openAlert={setAlertOpen}
                          />
                        </ClientProvider>
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/verificacao-email"
                    element={
                      <ClientProvider>
                        <VerificacaoEmail />
                      </ClientProvider>
                    }
                  />
                  <Route
                    path="/checkout"
                    element={
                      <CheckBackOffice>
                        <Private>
                          <Checkout />
                        </Private>
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/minha-conta"
                    element={
                      <CheckBackOffice>
                        <Private>
                          <HandleParcelaProvider>
                            <MinhaConta />
                          </HandleParcelaProvider>
                        </Private>
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/integracao"
                    element={
                      <HandleParcelaProvider>
                        <Integracao />
                      </HandleParcelaProvider>
                    }
                  />

                  <Route
                    path="/logoff"
                    element={
                      <HandleParcelaProvider>
                        <Logoff />
                      </HandleParcelaProvider>
                    }
                  />
                  <Route
                    path="/recuperar-senha"
                    element={
                      <CheckBackOffice>
                        <RecuperarSenha />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/cadastrar-senha/:id"
                    element={
                      <CadastrarSenha
                        alert={setAlertStatus}
                        message={setAlertMessage}
                        openAlert={setAlertOpen}
                      />
                    }
                  />
                  <Route
                    path="/regulamento"
                    element={
                      <CheckBackOffice>
                        <Regulamento />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/sobre-bau"
                    element={
                      <CheckBackOffice>
                        <SobreBau />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/programas-de-tv"
                    element={
                      <CheckBackOffice>
                        <Programas />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/premios"
                    element={
                      <CheckBackOffice>
                        <Premios />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/fale-conosco"
                    element={
                      <CheckBackOffice>
                        <FaleConosco />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/perguntas-frequentes"
                    element={
                      <CheckBackOffice>
                        <PerguntasFrequentes />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/quem-somos"
                    element={
                      <CheckBackOffice>
                        <QuemSomos />
                      </CheckBackOffice>
                    }
                  />
                  <Route
                    path="/politicas-legais"
                    element={
                      <CheckBackOffice>
                        <PoliticasLegais />
                      </CheckBackOffice>
                    }
                  />
                  <Route path="*" element={<Error404 />} />
                  <Route path="/404" element={<Error404 />} />
                </Routes>
              </div>
              <FooterStyled />
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={alertOpen}
                autoHideDuration={5000}
                onClose={() => setAlertOpen(false)}
              >
                <Alert
                  variant="filled"
                  onClose={() => setAlertOpen(false)}
                  severity={alertStatus || "info"}
                >
                  {alertMessage}
                </Alert>
              </Snackbar>
            </CartProvider>
          </HandleSideBarProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
