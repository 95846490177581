import { Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContainerBox from "../ContainerBox";
import { OrangeBoldText } from "./Troubleshooting.styed";

const Troubleshooting = () => {
  const navigate = useNavigate();

  const handleQuestion = () => {
    const id = "";
    navigate("/perguntas-frequentes", { state: { id } });
  };

  return (
    <ContainerBox>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "0 0 100px 0",
        }}
      >
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            margin: "0 15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
            }}
          >
            <Typography
              variant="h1"
              component="p"
              color="#1C1C7B"
              sx={{
                margin: { xs: "0", sm: "0" },
                fontWeight: 400,
                textAlign: "initial",
              }}
            >
              <strong>OPS!&nbsp;</strong> Sua busca não trouxe resultados
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              textAlign: "initial",
              marginTop: 5,
            }}
          >
            <Typography variant="subtitle1" component="p" color="#000000">
              não se preocupe! vamos tentar de outro jeito?
              <OrangeBoldText> {""}:)</OrangeBoldText>
              <br />
              <OrangeBoldText
                onClick={handleQuestion}
                style={{
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
              >
                clique aqui
              </OrangeBoldText>
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
        ></Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" component="p" color="#000000">
            <ul>
              <li>Verifique os termos digitados</li>
              <li>Tente utilizar uma única palavra</li>
              <li>Utilize termos genéricos a busca</li>
              <li>Procure utilizar sinonimos ao termo desejado</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default Troubleshooting;
