import { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TablePedidos from "./TablePedidos";
import { useIsMobile } from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";

const MeusPedidos = () => {
  const isMobile = useIsMobile();
  const { setMenuSidebar } = useHandleParcela();
  const auth = useAuth();
  const [getItems, setGetItems] = useState<null | any>(null);
  const [loading, setLoading] = useState(false);
  const [isErrorTable, setIsErrorTable] = useState(false);

  const handleDrawerClose = () => {
    setMenuSidebar(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      handleDescript(token);
    }
  }, []);

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(item);
        handleData(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleData = async (token: any) => {
    setLoading(true);
    const login = token.login;
    try {
      if (auth) {
        const request = await auth?.pedidos(login);
        handleArray(request);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setIsErrorTable(true);
      throw error;
    }
  };

  const handleArray = (request: any) => {
    setGetItems(request);
  };

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item lg={12} md={12} sm={10} xs={10}>
        <div>
          <Typography
            variant="h1"
            sx={{ margin: "30px 0px", width: "max-content" }}
          >
            {isMobile && (
              <ArrowBackIosIcon
                onClick={handleDrawerClose}
                sx={{ fontSize: "32px" }}
              />
            )}
            Meus Pedidos
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={10}
        xs={10}
        sx={{
          display: "inline-table",
          width: "366px",
        }}
      >
        <TablePedidos
          arr={getItems}
          loading={loading}
          isErrorTable={isErrorTable}
        />
      </Grid>
    </Grid>
  );
};

export default MeusPedidos;
