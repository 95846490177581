import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    z-index: 2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    .content-text {
        font-weight: 400;
        line-height: 20px;
        width: 80%;
    }
    .btn-ok {
        background-color: #F08C10;
        color: #FFFFFF !important;
        width: 198px;
        font-size: 16px;
        height: auto;
    }
    @media (max-width: 767px) {
        flex-wrap: wrap;
        .content-text {
            width: auto;
            margin-bottom: 10px;
        }

    }
`