import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from "react";

export interface ClientData {
  cpf: string;
  email: string;
  nascimento: string;
}

interface ClientContextProps {
  clientData: ClientData | null;
  saveClientData: (data: ClientData) => void;
}

const ClientContext = createContext<ClientContextProps | undefined>(undefined);

export const useClient = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClient must be used within a ClientProvider");
  }
  return context;
};

export const ClientProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [clientData, setClientData] = useState<ClientData | null>(null);

  const saveClientData = (data: ClientData) => {
    setClientData(data);
  };

  const contextValue: ClientContextProps = {
    clientData,
    saveClientData,
  };

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};
