import React, { useState, useEffect, FC } from "react";
import ContainerBox from "../../components/ContainerBox";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useIsMobile } from "../../utils/utils";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";
import { useNavigate } from "react-router-dom";

type Props = {
  alert: React.Dispatch<React.SetStateAction<"success" | "error" | null>>;
  message: React.Dispatch<React.SetStateAction<string>>;
  openAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

const Autenticar: FC<Props> = ({ alert, message, openAlert }) => {
  const backOffice = useBackOffice();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [formData, setFormData] = useState({
    login: "",
    senha: "",
    showPassword: false,
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(!formData.login || !formData.senha || error);
  }, [formData.login, formData.senha, error]);

  const handleInputLogin = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setError(!event.target.value);
  };

  const handleClickShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await backOffice?.loginBackOffice(
        formData.login,
        formData.senha
      );
      setLoading(false);
      message("Login efetuado com sucesso!");
      alert("success");
      openAlert(true);
      navigate("/autenticar/login");
      return response;
    } catch (error) {
      setLoading(false);
      message(JSON.stringify(error));
      alert("error");
      openAlert(true);
      localStorage.removeItem("token_system");
    }
  };

  return (
    <ContainerBox
      sx={{
        background: "#1C1C7B",
        width: "100%",
        height: "100vh",
      }}
    >
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "center" },
            margin: "98px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: `${isMobile ? "322px" : "454px"}`,
            }}
          >
            <img
              src={`${process.env.REACT_APP_IMAGE_SERVER_URL}logoJequiti.svg`}
              alt="logo-jequiti"
              style={{
                width: "100%",
                height: `${isMobile ? "112px" : "157px"}`,
                marginBottom: `${isMobile ? "49px" : "77px"}`,
              }}
            />
            <Typography
              variant="subtitle1"
              color="#FFFFFF"
              sx={{
                textAlign: "justify",
              }}
            >
              Para realizar o acesso a esta área restrita, para atendimento ao
              cliente, por favor informe seus dados abaixo:
            </Typography>
            <form onSubmit={handleSubmit}>
              <div>
                <Typography
                  variant="subtitle2"
                  color="#F08C10"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  Login
                </Typography>
                <TextField
                  onChange={handleInputLogin}
                  value={formData.login}
                  sx={{
                    boxSizing: "border-box",
                    background: "#FFFFFF",
                    border: "1px solid #BDBDBD",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="login"
                  placeholder="Username"
                  required
                  InputProps={{
                    // endAdornment:
                    //   <CheckOutlinedIcon
                    //     sx={{ color: 'orange', marginRight: '8px' }}
                    //   />,
                    style: {
                      height: "48px",
                    },
                  }}
                />
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  color="#F08C10"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  Senha
                </Typography>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "#FFFFFF",
                    border: "1px solid #BDBDBD",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="senha"
                  placeholder="******"
                  type={formData.showPassword ? "text" : "password"}
                  value={formData.senha}
                  onChange={handleInputLogin}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {formData.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      height: "48px",
                    },
                  }}
                />
              </div>
              <div>
                <Button
                  disabled={isDisabled || loading}
                  type="submit"
                  sx={{
                    width: "100%",
                    height: "48px",
                    marginTop: "30px",
                    fontSize: "14px",
                    color: "#FFFF",
                    background: `${
                      isDisabled || loading ? "#D1D1D1" : "#F08C10"
                    }`,
                    "&: hover": {
                      background: `${
                        isDisabled || loading ? "#D1D1D1" : "#F08C10"
                      }`,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                  ) : (
                    "entrar"
                  )}
                </Button>
                <Typography
                  variant="subtitle1"
                  color="#FFFFFF"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "20px 0",
                  }}
                >
                  Autenticar - área restrita para atendimento
                </Typography>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default Autenticar;
