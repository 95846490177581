import ContainerBox from "../ContainerBox";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { useIsMobile } from "../../utils/utils";
import { campanha } from "../../utils/data";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import { CarouselArrows } from "../../components/CarouselArrows/CarouselArrows";
import { Container } from "./CardCampanha.styled";

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CardCampanha = () => {
  const isMobile = useIsMobile();

  const cards = [];
  for (let i = 0; i < campanha.length; i += 4) {
    const row = [];
    for (let j = i; j < i + 4 && j < campanha.length; j++) {
      row.push(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Grid item lg={6} md={3} sm={12} xs={12}>
            <Card
              sx={{
                width: 376,
                height: 382,
                boxShadow: "none",
                backgroundColor: `${
                  j % 2 === 0 ? "#F4F5F7" : "rgba, 140, 16, 0.15)"
                }`,
                borderRadius: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a href="/programas-de-tv">
                  <CardMedia
                    component="img"
                    image={campanha[j].image}
                    alt=""
                    sx={{
                      height: "382px",
                      justifyContent: "center",
                    }}
                  />
                </a>
              </div>
            </Card>
          </Grid>
        </div>
      );
    }
    cards.push(
      <Carousel.Item key={i}>
        <CardsWrapper>{row}</CardsWrapper>
      </Carousel.Item>
    );
  }

  return (
    <ContainerBox
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        flexWrap: "wrap",
        justifyContent: {
          sm: "center",
          md: "space-between",
          lg: "space-between",
        },
        alignItems: "center",
      }}
    >
      <Container>
        <Typography
          variant="h1"
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            margin: " 35px auto 20px  auto",
          }}
        >
          Acompanhe os sorteios e concorra a prêmios
        </Typography>
        {isMobile ? (
          // <CarouselArrows indicators={false} interval={null} variant="dark">
          campanha.map((item: any, key: any) => (
            // <Carousel.Item key={key}>
            // <CardsWrapper>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Card
                  sx={{
                    // minWidth: 320,
                    // minHeight: 330,
                    margin: "5px",
                    boxShadow: "none",
                    borderRadius: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a href="/programas-de-tv">
                      <CardMedia
                        component="img"
                        image={item.image}
                        alt=""
                        sx={{
                          // width: "300px",
                          // height: "290px",
                          justifyContent: "center",
                          // margin: "0px 5px",
                        }}
                      />
                    </a>
                  </div>
                </Card>
              </Grid>
            </div>
            //   </CardsWrapper>
            // </Carousel.Item>
          ))
        ) : (
          // </CarouselArrows>
          <CarouselArrows indicators={false} interval={null} variant="dark">
            {cards}
          </CarouselArrows>
        )}
      </Container>
    </ContainerBox>
  );
};

export default CardCampanha;
