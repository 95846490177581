import { Box, BoxProps } from "@mui/material";

const ContainerBox = (props: BoxProps) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        margin: "auto",
        width: { xs: "320px", sm: "718px", md: "869px", lg: "1129px" },
        backgroundColor: "#0000",
        ...sx,
      }}
      {...other}
    />
  );
};

export default ContainerBox;
