import { Box, BoxProps } from "@mui/material";


const ItemBox = (props: BoxProps) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
          p: 1,
          m: 1,
          display: "flex",
          fontSize: "12px",
          fontWeight: '700',
          cursor: "pointer",
          textTransform: 'uppercase',
          ...sx,
      }}
      {...other}
    />
  )
}

export default ItemBox
