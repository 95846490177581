import React, { useState, useEffect } from "react";
import CarouselItem from "../../components/CarouselItem";
import RodapeEntrega from "../../components/RodapeEntrega";
import CardProduto from "../../components/CardProduto";
import CardCampanha from "../../components/CardCampanha";
import CardBanner from "../../components/CardBanner";
import CardDepoimento from "../../components/CardDepoimento";
import ButtonCarne from "../../components/ButtonCarne";
import BannerCarne from "../../components/BannerCarne";
import { useAuth } from "../../context/AuthProvider/useAuth";
import ContainerBox from "../../components/ContainerBox";
import { UserAutenticated } from "../../components/UserAutenticated";
import { useIsMobile } from "../../utils/utils";

const Home = () => {
  const auth = useAuth();
  const [isUserAutenticatedOpen, setIsUserAutenticatedOpen] = useState(
    localStorage.getItem("bannerUserClose") !== "true"
  );

  useEffect(() => {
    scrollToTop();
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  

  const isMobile = useIsMobile();

  const BannerAuth = ({ children }: { children: any }) => {
    if (!auth?.isAuthenticated()) {
      return null;
    }

    return children;
  };

  // nao me orgulho disso
  const margin = () => {
    let marginTop = isMobile ? 5 : 105;
    if (isUserAutenticatedOpen && auth?.isAuthenticated()) {
      if (isMobile) {
        marginTop = 80;
      } else {
        marginTop = 160;
      }
    }
    if (!isUserAutenticatedOpen && !auth?.isAuthenticated()) {
      if (isMobile) {
        marginTop = 5;
      } else {
        marginTop = 105;
      }
    }
    return {
      marginTop: marginTop,
    };
  };

  const styles = margin();

  const handleCloseUserAutenticated = () => {
    if (isUserAutenticatedOpen) {
      localStorage.setItem("bannerUserClose", "true");
      setIsUserAutenticatedOpen(false);
    }
  };

  return (
    <div style={styles}>
      <BannerAuth>
        {isUserAutenticatedOpen && (
          <UserAutenticated onClose={handleCloseUserAutenticated} />
        )}
      </BannerAuth>
      <ContainerBox>
        {<CarouselItem />}
        <BannerCarne />
        <ButtonCarne />
        <CardProduto />
        <CardCampanha />
        <CardDepoimento />
        <CardBanner />
        <RodapeEntrega />
      </ContainerBox>
    </div>
  );
};

export default Home;
