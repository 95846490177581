import { create, StateCreator } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
interface IUser {
  userName: string;
  token: string;
  userFiscalPerson: string;
  email: string;
  name: string;
  lastName: string;
}

interface IUserProps {
  user: IUser;
  clearUser: () => void;
  setUser: (user: IUser) => void;
}

const userInformation: StateCreator<
  IUserProps,
  [["zustand/persist", unknown]]
> = (set) => ({
  user: {
    userName: "",
    token: "",
    userFiscalPerson: "",
    email: "",
    name: "",
    lastName: "",
  },
  setUser: (user) => set(() => ({ user: user })),
  clearUser: () => {
    set({ user: undefined });
    sessionStorage.removeItem("bau-storageTeste");
    localStorage.removeItem("bau-storage");
  },
});

export const userStore = create<IUserProps>()(
  persist(userInformation, {
    name: "bau-storageTeste",
    storage: createJSONStorage(() => localStorage),
  })
);
