import React, { useEffect, useState } from "react";
import { ContainerOrange, ContentBox } from "./UserAutenticated.styled";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useAuth } from "../../context/AuthProvider/useAuth";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../utils/utils";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useNavigate } from "react-router-dom";


const UserAutenticated = ({ onClose }: { onClose: () => void }) => {
  const auth = useAuth();
  const user = auth?.user;
  const name = user?.cliente?.nome || user?.nome;
  const [validate, setValidate] = useState(false);
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();
  const navigate = useNavigate();
  const [carne, setCarne] = useState(false);
  const [tokenId, setTokenId] = React.useState("");
  const isMobile = useIsMobile();
  const token = localStorage.getItem("backOfficeId");

  useEffect(() => {
    if (token) setValidate(true);
  }, [token]);

  useEffect(() => {
    const data = localStorage.getItem("token");
    const carne = localStorage.getItem("userId");

    if (data) {
      setTokenId(data);
    }
    if (carne) {
      const isCarneQuitado = JSON.parse(carne);
      setCarne(isCarneQuitado?.carneQuitado);
    }
  }, []);

  const handleClick = () => {
    validate ? navigate("/autenticar/minha-conta") : navigate("/minha-conta");
    handleSideBar('Resgatar Produtos');
    toggleSubMenu(true);
  };

  return (
    <ContainerOrange
      style={{
        height: isMobile ? 60 : 50,
        marginTop: isMobile ? 128 : 102,
        position: isMobile ? "absolute" : "fixed",
      }}
    >
      <ContentBox
        sx={{
          width: { xs: "290px", sm: "718px", md: "869px", lg: "1129px" },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <PersonOutlineOutlinedIcon
            sx={{ fontSize: 20, margin: "auto 3px auto auto" }}
          />
          <p
            style={{
              fontSize: isMobile ? 14 : 16,
            }}
          >
            {carne ? (
              <>
                {name}, {""}
                você possui carnês quitados.{" "}
                <Typography
                  variant="subtitle1"
                  component="a"
                  color="#001C47"
                  onClick={() => handleClick()}
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#001C47',
                    },
                  }}
                >
                  Resgatar agora
                </Typography>
              </>
            ) : (
              <>Olá, {name}</>
            )}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginLeft: "auto",
          }}
        >
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon sx={{ fontSize: 20, width: 20 }} />
          </IconButton>
        </div>
      </ContentBox>
    </ContainerOrange>
  );
};

export default UserAutenticated;
