import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  primeiraSecao,
  segundaSecao,
  quartaSecao,
  socialMedia,
} from "../../utils/data";
import { politicas } from "../../utils/politicas";
import { Divider, List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Container } from "../LinkFooter/LinkFooterStyles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const LinkFooterMobile = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handlePoliticas = (value: string) => {
    const id = value;
    navigate("/politicas-legais", { state: { id } });
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      style={{
        margin: "50px 0",
        display: "block",
        zIndex: 1,
      }}
    >
      <Accordion
        expanded={expanded === "section"}
        onChange={handleChange("section")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="section-content"
          id="section-header"
        >
          <Typography
            variant="subtitle1"
            color="#1C1C7B"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            Sobre Baú
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {primeiraSecao.map((item: any, index: number) => (
              <ListItem key={index}>
                <Typography
                  variant="subtitle1"
                  color="#212121"
                  component="a"
                  href={item.url}
                  target={item.target}
                  sx={{
                    textTransform: "uppercase",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontSize: "12px",
                    "&:hover": {
                      color: "#212121",
                    },
                  }}
                >
                  {item.titulo}
                </Typography>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "section2"}
        onChange={handleChange("section2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="section2d-content"
          id="section2d-header"
        >
          <Typography
            variant="subtitle1"
            color="#1C1C7B"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            GRUPO SILVIO SANTOS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {segundaSecao.map((item: any, index: number) => (
              <ListItem key={index}>
                <Typography
                  variant="subtitle1"
                  color="#212121"
                  component="a"
                  href={item.url}
                  target={item.target}
                  sx={{
                    textTransform: "uppercase",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontSize: "12px",
                    "&:hover": {
                      color: "#212121",
                    },
                  }}
                >
                  {item.titulo}
                </Typography>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "section3"}
        onChange={handleChange("section3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="section3d-content"
          id="section3d-header"
        >
          <Typography
            variant="subtitle1"
            color="#1C1C7B"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            POLÍTICAS LEGAIS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {politicas.map((item: any, index: number) => (
              <ListItem key={index}>
                <Typography
                  variant="subtitle1"
                  color="#212121"
                  component="a"
                  onClick={() => handlePoliticas(item.id)}
                  sx={{
                    textTransform: "uppercase",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontSize: "12px",
                    "&:hover": {
                      color: "#212121",
                    },
                  }}
                >
                  {item.titulo}
                </Typography>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "section4"}
        onChange={handleChange("section4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="section4d-content"
          id="section4d-header"
        >
          <Typography
            variant="subtitle1"
            color="#1C1C7B"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            ATENDIMENTO
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {quartaSecao.map((item: any, index: number) => (
              <ListItem key={index}>
                <Typography
                  variant="subtitle1"
                  color="#212121"
                  component="a"
                  href={item.url}
                  target={item.target}
                  sx={{
                    textTransform: "uppercase",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontSize: "12px",
                    "&:hover": {
                      color: "#212121",
                    },
                  }}
                >
                  {item.titulo}
                </Typography>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Container>
        <Divider
          sx={{
            borderColor: "#D1D1D1",
            opacity: "0.5",
            transform: "matrix(1, 0, 0, -1, 0, 0)",
          }}
        />
        <p className="p-social-media" style={{ marginTop: 25 }}>
          Acompanhe o Baú nas redes sociais.
        </p>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            marginBottom: "20px",
          }}
        >
          {socialMedia.map((url: any, index: any) => (
            <a href={url.url} key={index}>
              <img src={url.imagem} alt={url.name} />
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default LinkFooterMobile;
