import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    allVariants: {
      fontStyle: 'normal',
      color: '#1c1c7b',
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '14px',
      lineHeight: '26px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      lineHeight: '26px',
      fontWeight: 400,
    },
    h1: {
      fontSize: '38px',
      lineHeight: '35px',
      fontWeight: 700,
      margin: '32px 0',

      '@media (max-width: 1199px)': {
        fontSize: '28px',
        margin: '32px 0px',
      }
    },
    h2: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '28px',
      lineHeight: '37px',
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    button: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 700,
      width: '100%',
      height: '56px',
      textDecoration: 'none',
      backgroundColor: '#F08C10',
      borderRadius: '4px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        color: '#1c1c7b',
      },
    },
  },
});

export const styles = {
  text: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#000000',

    '@media (max-width: 1199px)': {
      padding: '0 16px !important'
    }
  },

  link: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000000',
    textDecorationLine: 'underline',
    '&:hover': {
      color: '#000000',
    },

    '@media (max-width: 1199px)': {
      padding: '0 16px !important'
    },
  },
  links: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '33px',
    color: ' #1c1c7b',
    width: '300px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  abaBox: {
    display: 'flex',
    '& > :not(style)': {
      width: 279,
      height: 70,
      background: '#E6E9ED',
      borderRadius: '0 50px 0 0',
      border: 'none',
    },
  },
  boxCards: {
    display: 'flex',
    justifyContent: "center",
    '& > :not(style)': {
      width: 400,
      height: 289,
      background: '#E6E9ED',
      border: 'none',
    },
  },
  boxDescription: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#1c1c7b !important',

    p: {
      strong: {
        color: '#F08C10',
        textAlign: 'center',
      },
      a: {
        textDecorationLine: 'underline',
        color: '#1c1c7b !important',
      }
    }
  },
  boxNumber: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '33px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    background: '#F08C10',
    borderRadius: '50%',
    width: '52px',
    height: '52px',
    margin: '26px 45px',
    position: 'absolute',
    zIndex: 1,
  },

  subTitle: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#000000',
    margin: '28px 0 83px 0',

    '@media (max-width: 1199px)': {
      margin: '28px 0 83px 16px',
    },

  },

  titleCard: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '30px',
    lineHeight: '41px',
    color: '#1c1c7b',
    marginTop: '92px',

    '@media (max-width: 1199px)': {
      margin: 0,
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '27px',
      width: '100%'
    },
  },

  cardLink: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    textDecorationLine: 'underline',
    color: '#1c1c7b',
    '&:hover': {
      color: '#1c1c7b',
    },
  },

  description: {
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '26px',
    color: '#000000',
    margin: '32px 0 92px 0',

    p: {
      strong: {
        textAlign: 'center',
      },
      a: {
        textDecorationLine: 'underline',
        color: '#1c1c7b !important',
      }
    },

    '@media (max-width: 1199px)': {
      margin: '22px 0',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  help: {
    margin: '55px 0',
    fontFamily: 'Red Hat Display, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    color: '#1c1c7b',
    fontSize: '20px',
    lineHeight: '27px',
    textAlign: 'center',
  },

}