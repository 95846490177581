// context/MenuSideBar/useHandleSideBar.tsx
import React, { createContext, useContext, useState } from "react";

type HandleSideBarContextType = {
  handleSideBar: (section: string) => void;
  toggleSubMenu: (isOpen: boolean) => void;
  activeSection: string;
  setActiveSection: React.Dispatch<React.SetStateAction<string>>;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  isSubMenuOpen: boolean;
  handleDrawerClose: () => void;
  activeMenu: string;
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>;
  activeMenuItem: string;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string>>;
};

const HandleSideBarContext = createContext<
  HandleSideBarContextType | undefined
>(undefined);

export const HandleSideBarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeSection, setActiveSection] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<string>("");
  const [activeMenuItem, setActiveMenuItem] = useState<string>("");

  const handleSideBar = (section: string) => {
    setActiveSection(section);
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleSubMenu = (isOpen: boolean) => {
    setIsSubMenuOpen(isOpen);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const contextValue: HandleSideBarContextType = {
    handleSideBar,
    toggleSubMenu,
    activeSection,
    setActiveSection,
    setIsDrawerOpen,
    isSubMenuOpen,
    isDrawerOpen,
    handleDrawerClose,
    activeMenu,
    setActiveMenu,
    activeMenuItem,
    setActiveMenuItem,
  };

  return (
    <HandleSideBarContext.Provider value={contextValue}>
      {children}
    </HandleSideBarContext.Provider>
  );
};

export const useHandleSideBar = () => {
  const context = useContext(HandleSideBarContext);
  if (!context) {
    throw new Error(
      "useHandleSideBar deve ser usado dentro de um HandleSideBarProvider"
    );
  }
  return context;
};
