import React from "react";
import styled from "styled-components";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface CustomTooltipProps extends TooltipProps {
  className?: string;
}

export const CustomTooltip = styled(
  ({ className, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className ? className : "" }} />
  )
)`
  & .${tooltipClasses.tooltip} {
    background: #fff;
    color: #000;
    text-align: justify;
    font-family: "Red Hat Display";
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.12px;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
