import {
  Box,
  TableContainer,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import React, { FC } from "react";
import { styled } from "@mui/system";

import Digital from "../../assets/image-carnes/digital.png";
import Fisico from "../../assets/image-carnes/fisico.png";

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "289px",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#D9D9D9",
    borderRadius: "4px",
  },
});

type Props = {
  arr: any[];
  removeLine: any;
  qtd: string;
  handle: any;
};

const TableProductMobile: FC<Props> = ({ arr, removeLine, qtd, handle }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "350px",
        height: "289px",
        border: "1px solid #BDBDBD",
      }}
    >
      <StyledTableContainer>
        {arr.map((item, index) => (
          <List key={index}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <img
                  src={item?.tipoCarne === "Físico" ? Fisico : Digital}
                  style={{ width: `40px`, height: `40px` }}
                  alt="Carnê do Baú"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.fontWeightBold}
                      color="#000000"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                        fontSize: "12px",
                      }}
                    >
                      {`${item.numero}ª Parcela carnê: série ${item.serie} | `}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={theme.typography.fontWeightBold}
                      color="#000000"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                        fontSize: "12px",
                      }}
                    >
                      {`número ${item.numeroCarne}-${item.digito}`}
                    </Typography>
                  </React.Fragment>
                }
              />
              <ListItemText
                primary={
                  <React.Fragment>
                    <IconButton
                      sx={{ color: "#001C47" }}
                      onClick={() => removeLine(item.id)}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              {item.type === "carne" && (
                <div style={{ marginLeft: "60px" }}>
                  <Typography
                    variant="subtitle2"
                    color="#1C1C7B"
                    fontWeight={theme.typography.fontWeightBold}
                    sx={{
                      fontSize: "10px",
                    }}
                  >
                    Antecipação de parcelas
                  </Typography>
                  <Select
                    value={qtd}
                    onChange={handle}
                    variant="outlined"
                    style={{ height: "30px", width: "65px", marginLeft: "25%" }}
                  >
                    {[...Array(12)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </ListItem>
            <ListItem>
              <Box
                sx={{
                  width: "322px",
                  height: "38px",
                  background: "rgba(219, 221, 225, 0.9)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 15px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="#1C1C7B"
                  fontWeight={theme.typography.fontWeightBold}
                >
                  Total
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#1C1C7B"
                  fontWeight={theme.typography.fontWeightBold}
                >
                  {item.valor.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </Box>
            </ListItem>
          </List>
        ))}
      </StyledTableContainer>
    </Box>
  );
};

export default TableProductMobile;
