import { List, Typography, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ListaFooter = (array: any, titulo: string) => {
  const navigate = useNavigate();

  const handleQuestion = () => {
    const id = "";
    navigate("/perguntas-frequentes", { state: { id } });
    scrollToTop();
  };

  const handlePoliticas = (value: string) => {
    const id = value;
    navigate("/politicas-legais", { state: { id } });
    scrollToTop();
  };

  const handleLinkClick = (url: string, id?: string) => {
    if (id !== undefined && id !== "") {
      if (url === "/perguntas-frequentes") {
        handleQuestion();
      } else {
        handlePoliticas(id || "");
      }
    } else {
      if (url.startsWith("https://")) {
        window.open(url, "_blank");
      } else {
        navigate(url);
        scrollToTop();
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
        }}
      >
        {titulo}
      </Typography>
      <List dense>
        {array.map((item: any, index: any) => (
          <ListItem key={index} sx={{ padding: "inherit" }}>
            <Typography
              variant="subtitle1"
              color="#575756"
              component="span"
              onClick={() => handleLinkClick(item.url, item.id)}
              sx={{
                textTransform: "uppercase",
                textDecoration: "none",
                cursor: "pointer",
                fontSize: "14px",
                "&: hover": {
                  color: "#575756",
                },
              }}
            >
              {item.titulo}
            </Typography>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ListaFooter;
