import React, { FC, useEffect, useContext, useState } from "react";
import ContainerBox from "../../components/ContainerBox";
import PixOutlinedIcon from "@mui/icons-material/PixOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Typography,
  SelectChangeEvent,
  Button,
  Box,
  Paper,
  InputBase,
  Modal,
  IconButton,
  Alert, 
  Snackbar
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  TableProduct,
  TableProductMobile,
  TableResume,
  CardResumo,
  CardPayment,
  CardButton,
  CardConfirmPayment,
  CardTicket,
  BannerConfirmation,
} from "../../components/TableProduct";
import { useIsMobile } from "../../utils/utils";
import { alpha, styled } from "@mui/material/styles";
import StepComponent from "../../components/StepComponent";
import { CartContext } from "../../context/CartProvider";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useCart } from "../../context/CartProvider/useCart";
import QRCode from "react-qr-code";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";

const Input = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiTypography-root .MuiFormLabel-root .MuiInputLabel-root": {
    fontFamily: "Red Hat Display, sans-serif",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "2px solid #E5E5E5",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 14,
    padding: "10px 12px",
    marginBottom: theme.spacing(2),
    heigth: "50px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ["Red Hat Display, sans-serif"].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const Checkout = () => {
  const auth = useAuth();
  const cart = useCart();
  const backOffice = useBackOffice();
  const [quantity, setQuantity] = React.useState("1");
  const [value, setValue] = React.useState("0");
  const [tokenId, setTokenId] = React.useState("");
  const [id, setId] = React.useState<null | any>(null);
  const [getItems, setGetItems] = React.useState<null | any>(null);
  const [loading, setLoading] = React.useState(false);
  const [boleto, setBoleto] = React.useState(
    "237912345000874324567890000045663343790000123000"
  );
  const [copied, setCopied] = React.useState(false);
  const [copiedPix, setCopiedPix] = React.useState(false);
  const [context, setContext] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [paymentForm, setPaymentForm] = React.useState(0);
  const [returnPayment, setReturnPayment] = React.useState<null | any>(null);
  const [sendPayment, setSendPayment] = React.useState<null | any>(null);
  const [stepper, setStepper] = React.useState(0);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { cartItems, setCartItems, updateCartFromLocalStorage } =
    useContext(CartContext)!;
  const [alertMessage, setAlertMessage] = useState("");
  const [validate, setValidate] = useState(false);
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const [alertOpen, setAlertOpen] = useState(false);  
  const tokenBko = localStorage.getItem("tokenBackOffice");

  useEffect(() => {
     if (tokenBko) setValidate(true);
   }, [tokenBko]);

  useEffect(() => {
    const data = localStorage.getItem("token");
    const storedItems = localStorage.getItem("selectedItems");

    if (data) {
      setTokenId(data);
      handleDescript(data);
    }

    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setCartItems(parsedItems);
      setGetItems(parsedItems);
      // handlePayment(parsedItems);
    }
  }, [value]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [context]);

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(item);
        setId(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleBoleto = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBoleto(event.target.value);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(boleto);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleCopyPix = () => {
    navigator.clipboard.writeText(returnPayment?.codigoPix);
    setCopiedPix(true);
    setTimeout(() => {
      setCopiedPix(false);
    }, 3000);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setPaymentForm(parseInt(newValue));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setQuantity(event.target.value);
  };

  const handleButtonClick = (context: number) => {
    const newValue = context + 1;

    if (context === 1) {
      handlePayment(cartItems, tokenId, newValue);
    } else {
      setContext(newValue);
      setStepper(newValue);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePayment = async (array: any, token: any, newValue: any) => {
    setLoading(true);

    const filteredItems = array.map((item: any) => {
      return {
        codigoBarras: item.codigoBarras,
        valor: item.valor,
      };
    });

    const total = filteredItems.reduce((acc: any, item: { valor: any }) => {
      return acc + item.valor;
    }, 0);

    const data = {
      valor: total,
      tipoPedido: 1,
      parcelas: filteredItems,
      metodoPagamento: `${
        value === "1" ? "PIX" : value === "3" ? "CARTAO_CREDITO" : ""
      }`,
      cartaoCredito: value === "1" ? {} : value === "3" ? sendPayment : "",
    };

    try {
      
      if (value === "1") {

        if (backOffice?.isBackOfficeLogged()) {
          const request = await backOffice?.pixPayment(data, token, id.login);
          setReturnPayment(request); 
        } else {
          const request = await cart?.pixPayment(data, token);
          setReturnPayment(request);
        }
                
        setLoading(false);
        localStorage.removeItem("selectedItems");
        setCartItems([]);
        setContext(newValue);
        setStepper(newValue);
      }

      if (value === "3") {
        const request = await cart?.creditPayment(data, token);
        setLoading(false);
        setReturnPayment(request);
        localStorage.removeItem("selectedItems");
        setCartItems([]);
        setContext(newValue);
        setStepper(newValue);
      }
    } catch (error: any) {
      setLoading(false);
      setAlertMessage(JSON.stringify(error.message));
      setAlertStatus("error");
      setAlertOpen(true);
      setContext(newValue - 1);
      setStepper(newValue - 1);
    }
  };

  const removeItem = (productId: number) => {
    const updatedCart = cartItems.filter((item: any) => item.id !== productId);
    setCartItems(updatedCart);
    localStorage.setItem("selectedItems", JSON.stringify(updatedCart));

    updateCartFromLocalStorage();
    updateCartFromLocalStorage();
    setAlertMessage("Item removido com sucesso!");
    setAlertStatus("success");
    setAlertOpen(true);
  };

  const calcularValorTotal = (): number => {
    let total = 0;
    for (const item of cartItems) {
      const valorTotalItem = item.valor;
      total += valorTotalItem;
    }
    return total;
  };

  const calcularValorDesconto = (): number => {
    let desconto = 0;
    for (const item of cartItems) {
      const valorDescontoItem = 15;
      desconto += valorDescontoItem;
    }
    return desconto;
  };

  return (
    <div style={{ marginBottom: "200px" }}>
      <ContainerBox
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
          }}
        >
          <Paper
            elevation={2}
            sx={{
              minHeight: "360px",
              width: { xs: "340px", sm: "576px" },
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#FFFFFF",
              boxShadow: 24,
              border: 0,
              p: 4,
              outline: "none",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: theme.spacing(1),
                right: theme.spacing(1),
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h2"
                component="p"
                color="#1C1C7B"
                sx={{
                  textTransform: "uppercase",
                  margin: "30px 0",
                }}
              >
                aNTECIPAÇÃO DE PARCELAS
              </Typography>
              <Typography
                variant="subtitle2"
                component="p"
                color="#575756"
                sx={{
                  textAlign: "center",
                }}
              >
                <p>Aumente suas chances de ser sorteado!!!</p>
                <p>
                  Aproveite para antecipar as parcelas de seu novo carnê, no ato
                  da compra!
                </p>
                <p>
                  Basta inserir a quantidade de parcelas que queira antecipar
                  junto com a 1ª parcela e ao compensar o pagamento, seus cupons
                  serão enviados automaticamente.
                </p>
              </Typography>
              <Button
                onClick={() => handleClose()}
                sx={{
                  width: "263px",
                  color: "#FFFFFF",
                  height: "33px",
                  margin: "15px 0",
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: "#F08C10",
                  },
                }}
              >
                Entendi
              </Button>
            </div>
          </Paper>
        </Modal>
      </ContainerBox>
      <ContainerBox 
        sx={{ 
          display: "flex",          
          justifyContent: {xs: 'center', sm: 'center'},          
          marginBottom: '25px',
        }}
      >
        <StepComponent num={stepper} />
      </ContainerBox>
      {context === 2 && (
        <>
          <BannerConfirmation vlrTotal={returnPayment?.valor} vlr={value} />
          {paymentForm === 1 && (
            <>
              <ContainerBox sx={{ display: "flex", justifyContent: "center" }}>
                <Grid
                  container
                  spacing={3}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 15px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="h2"
                        color="#1C1C7B"
                        sx={{ marginBottom: "15px" }}
                      >
                        Agora basta pagar o PIX de{" "}
                        {returnPayment?.valor.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}{" "}
                        para garantir sua compra
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </ContainerBox>
              {isMobile && (
                <ContainerBox>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 15px",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "266px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Button
                            onClick={handleCopyPix}
                            sx={{
                              height: "40px",
                              color: "#FFFFFF",
                              width: "100%",
                              fontSize: { xs: "12px", sm: "16px" },
                              display: "flex",
                              justifyContent: "center",
                              margin: "15px 0",
                              "&:hover": {
                                background: "#F08C10",
                              },
                            }}
                          >
                            <PixOutlinedIcon />{" "}
                            {copiedPix ? "Copiado" : "cOPIAR CÓDIGO PIX"}
                          </Button>
                          <Input
                            name="pix"
                            value={returnPayment?.codigoPix}
                            multiline
                            sx={{
                              width: "100%",
                              height: "88px",
                              color: "#575756",
                              fontSize: "14px",
                              margin: "12px 0",
                            }}
                          />
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </ContainerBox>
              )}
              <ContainerBox sx={{ display: "flex", justifyContent: "center" }}>
                <Grid
                  container
                  spacing={3}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0 15px",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography variant="h2" color="#575756">
                        Como Pagar?
                      </Typography>
                      <Typography variant="subtitle2" color="#575756">
                        <ol>
                          <li>
                            Acesse seu Internet Banking ou abra o app do seu
                            banco e vá em pagar com Pix;
                          </li>
                          <li>
                            {`${
                              isMobile
                                ? "Escolha  Código Copia e Cola;"
                                : "Escolha se quer Pagar com QR Code ou Código Copia e Cola;"
                            }`}
                          </li>
                        </ol>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </ContainerBox>
              <ContainerBox>
                <Grid
                  container
                  spacing={3}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0 15px",
                    }}
                  >
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="#575756"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: { xs: "350px", sm: "500px" },
                        }}
                      >
                        <QrCodeScannerOutlinedIcon />
                        <span style={{ marginLeft: "10px" }}>
                          <strong>Copia e Cola: </strong> basta copiar o código
                          clicando no botão "Copiar código PIX, colar no app do
                          banco e pagar.
                        </span>
                      </Typography>
                    </Grid>
                    {isMobile ? (
                      ""
                    ) : (
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#575756"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: { xs: "350px", sm: "500px" },
                          }}
                        >
                          <InsertDriveFileOutlinedIcon />
                          <span style={{ marginLeft: "10px" }}>
                            <strong>Pagar com QR Code: </strong> basta escanear
                            o QR Code abaixo com a câmera do seu celular dentro
                            do app do banco.
                          </span>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </ContainerBox>
              <ContainerBox>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0 15px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "266px",
                      }}
                    >
                      {isMobile ? (
                        ""
                      ) : (
                        <>
                          <Box
                            sx={{
                              width: "266px",
                              height: "28px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="subtitle1" color="#575756">
                              QR CODE
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "266px",
                              height: "266px",
                              background: "#D9D9D9",
                            }}
                          >
                            <QRCode value={`${returnPayment?.codigoPix}`} />
                          </Box>
                          <Box
                            sx={{
                              width: "266px",
                              height: "28px",
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              color="#575756"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 700,
                              }}
                            >
                              Total:{" "}
                              {returnPayment?.valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="#575756"
                              sx={{
                                fontSize: "12px",
                                fontWeight: 700,
                              }}
                            >
                              Beneficiário: Jequiti Pay
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "266px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Input
                                name="pix"
                                value={`${returnPayment?.codigoPix}`}
                                multiline
                                sx={{
                                  width: "100%",
                                  height: "88px",
                                  color: "#575756",
                                  fontSize: "14px",
                                  margin: "12px 0",
                                }}
                              />
                              <Button
                                onClick={handleCopyPix}
                                sx={{
                                  height: "40px",
                                  color: "#FFFFFF",
                                  width: "100%",
                                  fontSize: { xs: "12px", sm: "16px" },
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "30px 0",
                                  "&:hover": {
                                    background: "#F08C10",
                                  },
                                }}
                              >
                                <PixOutlinedIcon />{" "}
                                {copiedPix ? "Copiado" : "cOPIAR CÓDIGO PIX"}
                              </Button>
                            </div>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </ContainerBox>
            </>
          )}
          {/* dados retorno pagamento */}
          <ContainerBox sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    marginBottom: "30px",
                    minHeight: "146px",
                    border: isMobile ? "none" : "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <CardTicket
                    pedido={returnPayment?.numeroPedido}
                    data={returnPayment?.dataPedido}
                  />
                </Box>
                <Grid
                  container
                  spacing={3}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      borderBottom: isMobile
                        ? "1px solid rgba(0, 0, 0, 0.2)"
                        : "none",
                    }}
                  >
                    <Box
                      sx={{
                        height: "146px",
                        width: "100%",
                        border: isMobile
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "10px 15px",
                        }}
                      >
                        <Typography
                          variant="h2"
                          color="#1C1C7B"
                          fontWeight={theme.typography.fontWeightBold}
                        >
                          Dados Pessoais
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "0 15px",
                        }}
                      >
                        <Typography variant="subtitle2" color="#000000">
                          {`${id?.email}`}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px 15px",
                        }}
                      >
                        <Typography variant="subtitle2" color="#000000">
                          {`${id?.nome} ${id?.sobrenome}`}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      borderBottom: isMobile
                        ? "1px solid rgba(0, 0, 0, 0.2)"
                        : "none",
                    }}
                  >
                    <Box
                      sx={{
                        height: "146px",
                        width: "100%",
                        border: isMobile
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "10px 15px",
                        }}
                      >
                        <Typography
                          variant="h2"
                          color="#1C1C7B"
                          fontWeight={theme.typography.fontWeightBold}
                        >
                          Pagamento
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "0 15px",
                        }}
                      >
                        <Typography variant="subtitle2" color="#000000">
                          {`${returnPayment?.metodoPagamento}`}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px 15px",
                        }}
                      >
                        <Typography variant="subtitle2" color="#000000">
                          {returnPayment?.valor.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContest: "center" }}
              >
                <Box
                  sx={{
                    height: "325px",
                    width: "100%",
                    border: isMobile ? "none" : "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <CardConfirmPayment
                    arr={getItems}
                    qtd={quantity}
                    vlrTotal={returnPayment?.valor}
                    vlrDesc={calcularValorDesconto}
                  />
                </Box>
              </Grid>
            </Grid>
          </ContainerBox>
        </>
      )}
      <ContainerBox sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "left" },
              alignItems: "center",
            }}
          >
            <Typography
              variant="h2"
              color="#F08C10"
              sx={{
                fontSize: {xs: '21px', sm: '28px'},
                marginBottom: {xs: '0', sm: '15px'}
              }}
            >
              {context === 0 ? 'Meu Carrinho' : context === 1 ? 'Pagamento' : ''}
            </Typography>
          </Grid>
        </Grid>
      </ContainerBox>
      <ContainerBox sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {context === 1 && (
              <CardPayment
                handle={handleChangeTab}
                vlr={value}
                setSendPayment={setSendPayment}
              />
            )}
            {context === 0 && (
              <>
                {isMobile ? (
                  <TableProductMobile
                    arr={cartItems}
                    removeLine={removeItem}
                    qtd={quantity}
                    handle={handleChange}
                  />
                ) : (
                  <TableProduct
                    arr={cartItems}
                    removeLine={removeItem}
                    qtd={quantity}
                    handle={handleChange}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {context === 1 && (
              <Paper
                elevation={3}
                sx={{ height: "320px", width: "360px", margin: "0 0 20px 0" }}
              >
                <CardResumo
                  setContext={setContext}
                  setStepper={setStepper}
                  arr={cartItems}
                  qtd={quantity}
                />
              </Paper>
            )}
            {(context === 0 || context === 1) && (
              <>
                {isMobile ? (
                  <TableResume
                    arr={cartItems}
                    vlrTotal={calcularValorTotal}
                    vlrDesc={calcularValorDesconto}
                  />
                ) : (
                  <Paper elevation={3} sx={{height: '110px', width: '360px'}}>
                    <TableResume arr={cartItems} vlrTotal={calcularValorTotal} vlrDesc={calcularValorDesconto}/>
                  </Paper>
                )}
                <Paper
                  elevation={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "151px",
                    width: "360px",
                    margin: "22px 15px 0 15px",
                  }}
                >
                  <CardButton
                    value={value}
                    context={context}
                    handle={handleButtonClick}
                    loading={loading}
                  />
                </Paper>
              </>
            )}
          </Grid>
        </Grid>
      </ContainerBox>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={5000} 
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertStatus || "info"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Checkout;
