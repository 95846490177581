import styled from "styled-components";

export const ContainerButton = styled.div`
  @media (max-width: 489px) {
    width: 95%;
    padding: 0px 15px 6px 15px;
  }
  @media (min-width: 489px) and (max-width: 1023px) {
    width: 300px;
    padding: 0px 15px 6px 15px;
  }
  @media (min-width: 899px) and (max-width: 1200px) {
    width: 360px;
    margin: auto;
  }
`;
