import { useEffect } from "react";
import ContainerBox from "../../components/ContainerBox";
import { useIsMobile } from "../../utils/utils";
import MinhaContaDesktop from "./MinhaContaDesktop";
import MinhaContaMobile from "./MinhaContaMobile";

const MinhaConta = () => {
  const isMobile = useIsMobile();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  }, [])
  

  return (
    <div
      style={{
        margin: `${isMobile ? "24px 0px" : "120px 0"}`,
        display: "flex",
      }}
    >
      <ContainerBox>
        {isMobile ? <MinhaContaMobile /> : <MinhaContaDesktop />}
      </ContainerBox>
    </div>
  );
};

export default MinhaConta;
