import React, { useState, useEffect, FC, useRef, useCallback } from "react";
import ContainerBox from "../../components/ContainerBox";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { formatDateToYYYYMMDD, useIsMobile } from "../../utils/utils";
import {
  validateName,
  phoneMask,
  validateEmail,
  validateCpf,
  formatCpf,
} from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import { ContentTitleAndSubtitle } from "./Cadastro.styled";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarContainer } from "../../pages/RecuperarSenha/RecuperarSenha.styled";
import EventIcon from "@mui/icons-material/Event";
import { ptBR } from "date-fns/locale";

type Props = {
  alert: React.Dispatch<React.SetStateAction<"success" | "error" | null>>;
  message: React.Dispatch<React.SetStateAction<string>>;
  openAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

registerLocale("ptBR", ptBR);

const Cadastro: FC<Props> = ({ alert, message, openAlert }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const isMobile = useIsMobile();
  const backOffice = useBackOffice();
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErroPhone] = useState(false);
  const [errorDate, setErroDate] = useState(false);
  const [errorEmail, setErroEmail] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorCpf, setErrorCpf] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    !backOffice?.isBackOfficeLogged()
      ? setIsDisabled(!fullName || !email || !cpf || !birthDate || !phone)
      : setIsDisabled(!fullName || !email || !cpf || !birthDate || !phone);
  }, [fullName, email, cpf, birthDate, phone, backOffice]);

  const handleInputName = (event: any) => {
    const value = event.target.value.replace(/[0-9]/g, "");
    const values = event.target.value.trim();
    const [firstName, ...rest] = values.split(" ");
    const secondName = rest.join(" ");
    setFirstName(firstName);
    setSecondName(secondName);
    setFullName(value.slice(0, 50));
    setIsError(!validateName(event.target.value));
  };

  const handleChangeCpf = (event: any) => {
    const value = event.target.value;
    setCpf(value);
    setErrorCpf(!validateCpf(event.target.value) || !event.target.value);
  };

  const handleInputEmail = (event: any) => {
    const value = event.target.value;
    setEmail(value);
    generateContatos();
    setErroEmail(!validateEmail(value));
  };

  const minDate = new Date(1920, 0, 1);

  const toggleCalendarVisibility = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    value = value.replace(/[^0-9]/g, "");

    if (value.length > 2 && value.length <= 4) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length > 4) {
      value =
        value.slice(0, 2) + "/" + value.slice(2, 4) + "/" + value.slice(4, 8);
    }
    setBirthDate(value);
    const validationResult = isValidDate(value);
    setBirthDate(value);
    setErroDate(!validationResult.isValid);
    setErrorMessage(validationResult.message);
  };

  const isValidDate = (dateStr: string) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(dateStr)) {
      return {
        isValid: false,
        message: "Informe uma data de nascimento válida.",
      };
    }

    const [day, month, year] = dateStr.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const minDate = new Date(1920, 0, 1);
    const maxDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (birthDate < minDate || birthDate > maxDate) {
      return {
        isValid: false,
        message: "Informe uma data de nascimento válida.",
      };
    }
    return { isValid: true, message: "" };
  };

  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDateSelect = (date: Date | null) => {
    if (date) {
      const formattedDate = formatLocaleDate(date);

      setBirthDate(formattedDate);
      const validationResult = isValidDate(formattedDate);
      setErroDate(!validationResult.isValid);
      setErrorMessage(validationResult.message);
    }
  };

  const formatLocaleDate = (date: Date) => {
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  const handleInputPhone = (event: any) => {
    const value = event.target.value.replace(/[^\d()-]/g, "");
    setPhone(phoneMask(value));
    generateContatos();
    setErroPhone(value.length !== 14 || value === "");
  };

  const handleEncrypt = async (cpf: any, email: any, nascimento: any) => {
    const data = {
      cpf: cpf,
      email: email,
      nascimento: nascimento,
    };
    try {
      if (auth) {
        const request = await auth?.encryptToken(data);
        handleDataUser(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleDataUser = (data: any) => {
    localStorage.removeItem("dataClient");
    localStorage.setItem("dataClient", data);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    const contatos = generateContatos();
    const documentos = generateDocumentos();
    const formattedBirthDate = formatDateToYYYYMMDD(birthDate);
    const data = {
      nome: firstName,
      sobrenome: secondName,
      nascimento: formattedBirthDate,
      contatos: contatos,
      documentos: documentos,
    };
    try {
      const response = await auth?.register(data);
      handleEncrypt(documentos[0].valor, contatos[0].valor, birthDate);
      backOffice?.isBackOfficeLogged()
        ? navigate("/autenticar/login")
        : navigate("/verificacao-email");
      message(`${response}`);
      setLoading(false);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "button_click",
        click_category: "cadastro",
        click_text: "Criar Conta",
      });
      message("Cadastro efetuado com sucesso!");
      alert("success");
      openAlert(true);
    } catch (error: any) {
      if (error.response.status !== 400) {
        message(
          "Ocorreu um erro ao tentar efetuar o cadastro. Tente novamente mais tarde."
        );
        alert("error");
      } else {
        message(
          "Usuário já existe. Por favor, use um CPF ou e-mail diferente."
        );
        alert("error");
      }
    } finally {
      setLoading(false);
      openAlert(true);
    }
  };

  const generateContatos = () => {
    const contatos = [];

    if (email) {
      contatos.push({
        tipo: 1,
        valor: email,
      });
    }
    if (phone) {
      contatos.push({
        tipo: 2,
        valor: phone.replace(/\D/g, ""),
      });
    }

    return contatos;
  };

  const generateDocumentos = () => {
    const documentos = [];

    if (cpf) {
      documentos.push({
        tipo: 1,
        valor: cpf.replace(/\D/g, ""),
      });
    }
    return documentos;
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text");
    const cleanedDate = pastedData.replace(/[^\d]/g, "");

    if (cleanedDate.length === 8) {
      const formattedDate = `${cleanedDate.slice(0, 2)}/${cleanedDate.slice(
        2,
        4
      )}/${cleanedDate.slice(4, 8)}`;
      setBirthDate(formattedDate);
    }
  };

  return (
    <>
      <ContainerBox>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ContentTitleAndSubtitle
              style={{
                width: `${isMobile ? "300px" : "454px"}`,
                marginTop: `${isMobile ? "20px" : "120px"}`,
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  width: "100%",
                }}
              >
                Crie sua conta no Baú da Felicidade
              </Typography>
              <Typography
                variant="subtitle1"
                color="#000000"
                sx={{
                  width: "100%",
                }}
              >
                Conte pra gente um pouco mais sobre você.
              </Typography>
            </ContentTitleAndSubtitle>
          </Grid>
        </Grid>
      </ContainerBox>
      <ContainerBox>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: `${isMobile ? "center" : null}`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: `${isMobile ? "300px" : "454px"}`,
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  Nome Completo*
                </Typography>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  type="text"
                  name="name"
                  value={fullName}
                  onChange={handleInputName}
                  placeholder="Nome Sobrenome"
                  required
                  error={isError}
                  helperText={
                    isError ? "Nome e sobrenome são obrigatórios" : ""
                  }
                  InputProps={{
                    endAdornment:
                      isError || !fullName ? null : (
                        <CheckOutlinedIcon
                          sx={{
                            width: "18px",
                            height: "18px",
                            color: "#F08C10",
                            marginRight: "6px",
                          }}
                        />
                      ),
                    style: {
                      height: "48px",
                    },
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  CPF*
                </Typography>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="cpf"
                  value={formatCpf(cpf)}
                  onChange={handleChangeCpf}
                  error={errorCpf}
                  helperText={errorCpf ? "CPF inválido" : null}
                  placeholder="000.000.000-00"
                  required
                  InputProps={{
                    endAdornment:
                      errorCpf || cpf === "" ? null : (
                        <CheckOutlinedIcon
                          sx={{ color: "orange", marginRight: "8px" }}
                        />
                      ),
                    style: {
                      height: "48px",
                    },
                  }}
                />
                <div>
                  <div>
                    <Typography
                      variant="subtitle2"
                      color="#000000"
                      sx={{
                        textTransform: "uppercase",
                        marginTop: "20px",
                        fontSize: "10px",
                        fontWeight: 700,
                      }}
                    >
                      data de nascimento*
                    </Typography>
                    <TextField
                      name="birthDate"
                      value={birthDate}
                      inputRef={inputRef}
                      onChange={handleDateChange}
                      onPaste={handlePaste}
                      error={errorDate}
                      placeholder="DD/MM/AAAA"
                      helperText={errorMessage}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={toggleCalendarVisibility}
                              aria-label="toggle date picker"
                            >
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                    {isOpen && (
                      <CalendarContainer>
                        <DatePicker
                          locale="ptBR"
                          onChange={handleDateSelect}
                          inline
                          calendarClassName="custom-calendar"
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          minDate={minDate}
                        />
                      </CalendarContainer>
                    )}
                  </div>
                  <div>
                    <Typography
                      variant="subtitle2"
                      color="#000000"
                      sx={{
                        textTransform: "uppercase",
                        marginTop: "20px",
                        fontSize: "10px",
                        fontWeight: 700,
                      }}
                    >
                      Celular*
                    </Typography>
                    <TextField
                      sx={{
                        boxSizing: "border-box",
                        background: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      type="tel"
                      name="Celular"
                      value={phone}
                      onChange={handleInputPhone}
                      placeholder="(00) 00000-0000"
                      required
                      error={errorPhone}
                      helperText={errorPhone ? "Telefone inválido" : ""}
                      InputProps={{
                        endAdornment:
                          errorPhone || !phone ? null : (
                            <CheckOutlinedIcon
                              sx={{
                                width: "18px",
                                height: "18px",
                                color: "#F08C10",
                                marginRight: "4px",
                              }}
                            />
                          ),
                        style: {
                          height: "48px",
                        },
                      }}
                      inputProps={{
                        maxlength: 15,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: `${isMobile ? "center" : "flex-end"}`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: `${isMobile ? "300px" : "454px"}`,
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  E-MAIL*
                </Typography>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleInputEmail}
                  placeholder="exemplo@dominio.com"
                  required
                  error={errorEmail}
                  helperText={errorEmail ? "E-mail inválido" : ""}
                  InputProps={{
                    endAdornment:
                      errorEmail || !email ? null : (
                        <CheckOutlinedIcon
                          sx={{
                            width: "18px",
                            height: "18px",
                            color: "#F08C10",
                            marginRight: "6px",
                          }}
                        />
                      ),
                    style: {
                      height: "48px",
                    },
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />

                <Button
                  disabled={isDisabled || loading}
                  type="submit"
                  sx={{
                    width: "100%",
                    height: "48px",
                    margin: "20px 0 0 0",
                    fontSize: "14px",
                    color: "#FFFF",
                    background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                    "&: hover": {
                      background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                  ) : (
                    "criar conta"
                  )}
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItens: "center",
                    margin: "20px 0",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="#212121"
                    sx={{
                      fontSize: "14px",
                      textAlign: "center",
                      width: "100%",
                      "& a": {
                        color: "#000000",
                      },
                    }}
                  >
                    Ao se cadastrar você concorda com nossas{" "}
                    <a href="/politicas-legais"> politícas legais </a>e{" "}
                    <a href="/regulamento"> regulamentos </a> e declara que é
                    maior de 18 anos de idade.
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        </form>
      </ContainerBox>
    </>
  );
};

export default Cadastro;
