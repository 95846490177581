import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      display: "flex",
      gap: 10,
      marginBottom: 18,
    },

    cardDetails: {
      width: 449,
      height: 149,
      backgroundColor: "#ff2222",
      padding: 30,
    },
    arrow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #293EA8",
      width: 40,
      borderRadius: 5,
      cursor: "pointer",
    },
    paragraph: {
      fontWeight: 400,
    },
    titleH1: {
      color: "#1C1C7B",
      fontFamily: "Red Hat Display, sans-serif !important",
      fontWeight: 700,
    },
    strong: {
      color: "#4d4d4d",
    },
    strongClick: {
      color: "#F08C10",
      textDecoration: "underline",
    },
    skeleton: {
      marginTop: 20,
    },

    pagination: {
      display: "flex",
      justifyContent: "center",
      width: "64%",
    },
  })
);
