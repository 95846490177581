import styled from "styled-components";
import { cardDepoimento } from "../../utils/data";

export const Container = styled.div`
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
`;

export const ContainerImg = styled.div`
  background-image: url(${cardDepoimento[0].image});
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  width: 260px;
  height: 374px;
  margin: 0px 20px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  box-shadow: none;
  border-radius: 4px;
`;

export const CircleContainer = styled.div`
  margin-top: 32px;
  width: 100px;
  height: 100px;
  border: 5px solid #1c1c7b;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  img {
    width: 100%;
    border-radius: 50%;
  }
`;

export const AwardValue = styled.div`
  text-align: center;
  margin-top: 18px;
  .value {
    animation: blink 1s infinite;
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;
