import React from "react";
import ContainerBox from "../../components/ContainerBox";
import { useIsMobile } from "../../utils/utils";

type VideoPlayerProps = {
  videoLink: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoLink }) => {
  const isMobile = useIsMobile();
  return (
    <ContainerBox>
      <div style={{ margin: "40px 16px" }}>
        <iframe
          width="100%"
          height={isMobile ? "273px" : "551px"}
          src={`https://www.youtube.com/embed/${videoLink}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </ContainerBox>
  );
};

export default VideoPlayer;
