import React from "react";
import { Grid, Typography, Box } from "@mui/material";

const BannerCarne = () => {
  return (
    <Box
      sx={{
        margin: "15px",
      }}
    >
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            flexGroup: 1,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "27px", sm: "38px" },
              fontWeight: 700,
              textTransform: "uppercase",
              margin: "25px 0px 20px 0px",
            }}
          >
            VOCÊ CONHECE O CARNÊ DO
            <span style={{ color: "#F08C10" }}> BAÚ DA FELICIDADE? </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BannerCarne;
