import { FC } from "react";
import { Button, Typography, Skeleton } from "@mui/material";
import {
  formatDate,
  formatarValorEmReais,
  useIsMobile,
} from "../../utils/utils";
import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../DataTable";
import ErroSemResultados from "../ErroSemResultados";

type Props = {
  arr: any[];
  loading: boolean;
  isErrorTable: boolean;
};

const TablePedidos: FC<Props> = ({ arr, loading, isErrorTable }) => {
  const isMobile = useIsMobile();

  const renderTypography = (headerName: any) => (
    <Typography
      component="p"
      variant="subtitle1"
      color="#000000"
      sx={{
        fontSize: "12px",
        textTransform: "uppercase",
        fontWeight: 700,
      }}
    >
      {headerName}
    </Typography>
  );

  const handleRedirectMagento = (item: string) => {
    window.open(`${item}`, "_blank");
  };

  const columns: GridColDef[] = [
    {
      field: "info",
      headerName: "CATEGORIA",
      minWidth: isMobile ? 50 : 160,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "id",
      headerName: "Nº DO PEDIDO",
      minWidth: isMobile ? 50 : 160,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "data",
      headerName: "DATA",
      minWidth: isMobile ? 50 : 160,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "total",
      headerName: "VALOR",
      minWidth: isMobile ? 50 : 160,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "acao",
      headerName: "AÇÃO",
      minWidth: isMobile ? 50 : 110,
      disableColumnMenu: true,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "auto",
          }}
        >
          <Button
            onClick={() => handleRedirectMagento(params.row.acao)}
            sx={{
              width: "100px",
              height: "30px",
              color: "#F08C10",
              fontSize: "12px",
              border: "1px solid #F08C10",
              borderRadius: 0,
              background: "#FFFFFF",
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
          >
            VER DETALHE
          </Button>
        </div>
      ),
    },
  ];

  const rows: any[] = arr?.map((row) => ({
    info: "Resgate",
    id: row.numeroPedido,
    data: formatDate(row.dataPedido),
    total: formatarValorEmReais(row.valorPedido),
    acao: row.urlVerDetalhes,
  }));

  return (
    <div
      style={{
        display: `${isMobile && "flex"}`,
        alignItems: `${isMobile && "left"}`,
        justifyContent: `${isMobile && "left"}`,
        flexDirection: "column",
        width: `${isMobile && "360px"}`,
      }}
    >
      {arr && arr.length > 0 ? (
        <div style={{ margin: `${isMobile ? "0 30px" : 0}` }}>
          <Typography
            variant="h2"
            color="#000000"
            sx={{ margin: "0 5px 30px 0" }}
          >
            Pedidos Recentes
          </Typography>
          <DataTable rows={rows} columns={columns} />
        </div>
      ) : loading ? (
        <Skeleton
          variant="rectangular"
          width={`${isMobile ? "360px" : "775px"}`}
          height={547}
        />
      ) : (arr && arr.length === 0) || isErrorTable ? (
        <ErroSemResultados />
      ) : (
        ""
      )}
    </div>
  );
};

export default TablePedidos;
