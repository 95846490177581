import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import ContainerBox from "../ContainerBox";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const AtualizacaoDados = () => {
  const [openModal, setOpenModal] = useState(true);
  const [userData, setUserData] = useState<null | any>(null);

  const token = localStorage.getItem("dataClient");
  const { activeSection, setActiveMenu, handleSideBar, toggleSubMenu } =
    useHandleSideBar();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
    if (token) {
      handleDescript(token);
    }
    setTimeout(() => {
      localStorage.removeItem("dataClient");
    }, 1200000); // 20 minutos em milissegundos
  }, [token]);

  const handleDescript = (item: any) => {
    const decodedString = atob(item);
    const userDataObject = JSON.parse(decodedString);
    setUserData(userDataObject);
  };

  const navigateMeusDados = () => {
    localStorage.setItem("invalidData", "");
    setActiveMenu(activeSection);
    toggleSubMenu(true);
    return handleSideBar("Meus Dados");
  };

  const dado = localStorage.getItem("invalidData");

  var texto = "";

  if (dado === "invalidEmail")
    texto =
      "Atenção! O seu e-mail ainda não foi cadastrado. Por favor, atualize seu cadastro com um e-mail válido. Assim, garantimos que o seu resgate será realizado com sucesso ao quitar o seu carnê!";

  if (dado === "invalidDataNascimento")
    texto =
      "Atenção! Sua data de nascimento está incorreta. Por favor, atualize seu cadastro com uma data de nascimento válida. Assim, garantimos que o seu resgate será realizado com sucesso ao quitar o seu carnê!";

  if (dado === "invalidEmailAndDataNascimento")
    texto =
      "Atenção! Seus dados estão desatualizados. Por favor, realize a atualização dos seus dados. Assim, garantimos que o seu resgate será realizado com sucesso ao quitar o seu carnê!";

  return (
    <ContainerBox>
      <Modal
        open={openModal}
        disableEnforceFocus
        disableAutoFocus
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "378px",
            height: "auto",
            background: "#FFFFFF",
            borderRadius: "4px",
          }}
        >
          <div>
            <Typography
              variant="h2"
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F08C10",
                borderRadius: "4px",
                height: 63,
              }}
            >
              <div
                style={{
                  height: "auto",
                  margin: "auto",
                }}
              >
                <WarningRoundedIcon
                  fontSize="large"
                  sx={{ color: "white" }}
                ></WarningRoundedIcon>
              </div>
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <div>
              <Typography
                variant="h2"
                color="#000000"
                sx={{
                  textAlign: "center",
                  margin: "auto",
                  fontSize: 18,
                }}
              >
                Atualize seu cadastro!
              </Typography>
            </div>
            <div>
              <Typography
                color="#000000"
                sx={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontSize: 14,
                }}
              >
                {texto}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => navigateMeusDados()}
                sx={{
                  width: 117,
                  height: 40,
                  marginTop: "20px",
                  fontSize: "12px",
                  background: "#FFFFFF",
                  color: "#F08C10",
                  border: "1px solid #F08C10",
                  "&:hover": {
                    background: "#F08C10",
                    color: "#FFFFFF",
                  },
                }}
              >
                Atualizar
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </ContainerBox>
  );
};

export default AtualizacaoDados;
