import React, { useState, useCallback, FC, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  formatDateToYYYYMMDD,
  useIsMobile,
  validarSenha,
} from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useParams } from "react-router-dom";
import {
  ContentValidate,
  StepValidate,
  StyledListValidate,
} from "./CadastrarSenha.styled";

type Props = {
  alert: React.Dispatch<React.SetStateAction<"success" | "error" | null>>;
  message: React.Dispatch<React.SetStateAction<string>>;
  openAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

const CadastrarSenha: FC<Props> = ({ alert, message, openAlert }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [errorSenhaConfirm, setErrorSenhaConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorSenha, setErrorSenha] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [cpf, setCpf] = useState("");
  const [formData, setFormData] = useState({
    senha: "",
    confirmSenha: "",
    showPassword: false,
    showPasswordConfirm: false,
  });

  const validatePasswords = useCallback(() => {
    return (
      formData.senha === "" ||
      formData.confirmSenha === "" ||
      formData.senha !== formData.confirmSenha ||
      errorSenha ||
      errorSenhaConfirm
    );
  }, [formData.senha, formData.confirmSenha, errorSenha, errorSenhaConfirm]);
  const [isDisabled, setIsDisabled] = useState(validatePasswords());
  const [userData, setUserData] = useState<null | any>(null);
  const [dataId, setDataId] = useState("");
  const [dataClient, setDataClient] = useState<null | any>(null);

  const handleOpenModal = () => setOpenModal(true);

  useEffect(() => {
    const idParam = id ?? "";
    localStorage.setItem("tokenId", idParam);
    setDataId(idParam);
    handleDescript(idParam);
    setTimeout(() => {
      localStorage.removeItem("tokenId");
    }, 600000);
  }, []);

  const handleDescript = async (data: string) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(data);
        console.log("descriptToken", data);
        handleDataUser(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleDataUser = async (data: any) => {
    console.log("handleDataUser", data);
    setDataClient(data);
    handleDataUserLogin(data);
  };

  const handleDataUserLogin = async (data: any) => {
    const login = data.login;
    console.log("login", login);
    setCpf(login);
    try {
      if (auth) {
        const request = await auth?.searchClient(login);
        handleDataClient(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleDataClient = async (data: any) => {
    console.log("handleDataClient", data);
    setUserData(data);
  };

  useEffect(() => {
    const validateAndSetIsDisabled = () => {
      setIsDisabled(validatePasswords());
    };

    validateAndSetIsDisabled();
  }, [formData.senha, formData.confirmSenha]);

  const handlePutClient = async (data: any, token: any) => {
    try {
      if (auth) {
        await auth?.putClient(data, token);
        setLoading(false);
      }
    } catch (error: any) {
      message(`${error.response.data.error}`);
      alert("error");
      openAlert(true);
    }
  };

  const handleSubmitPassword = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (senha !== confirmSenha) {
      setPasswordError(true);
      return;
    }
    try {
      const formattedBirthDate = formatDateToYYYYMMDD(userData.nascimento);
      const newData = {
        nome: userData.nome,
        sobrenome: userData.sobrenome,
        nascimento: formattedBirthDate,
        login: cpf,
        senha: formData.senha,
        contatos: [
          { tipo: 1, valor: userData.contatos[0]?.valor || "" },
          { tipo: 2, valor: userData.contatos[1]?.valor || "" },
        ],
        documentos: [{ tipo: 1, valor: userData.documentos[0]?.valor || "" }],
        enderecos: userData.enderecos,
      };
      handlePutClient(newData, dataId);
      handleOpenModal();
      setLoading(false);
    } catch (error: any) {
      message(`${error.response.data.error}`);
      setLoading(false);
      alert("error");
      openAlert(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/login");
  };

  const handleInputPassword = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    const senhaValida = validarSenha(value);

    setErrorSenha(
      value.length < 8 ||
        !/[A-Z]/.test(value) ||
        !/\d/.test(value) ||
        !/[!@#$%^&*(),.?":{}|<>]/.test(value)
    );
    const criteriosAtendidos = [
      value.length >= 8,
      /[A-Z]/.test(value),
      /\d/.test(value),
      /[!@#$%^&*(),.?":{}|<>]/.test(value),
    ].filter(Boolean).length;

    const steps = document.querySelectorAll<HTMLElement>(".step-validate");
    steps.forEach((step, index) => {
      if (index < criteriosAtendidos) {
        step.style.background = "#93b749";
      } else {
        step.style.background = "#D9D9D9";
      }
    });

    const passwordsNotMatch = value !== formData.confirmSenha;
    setIsDisabled(senhaValida || passwordsNotMatch);
  };

  const handleClickShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleClickShowPasswordConfirm = () => {
    setFormData({
      ...formData,
      showPasswordConfirm: !formData.showPasswordConfirm,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          flexDirection: "column",
          margin: `${isMobile ? "0px auto" : "100px auto"}`,
          width: `${isMobile ? "300px" : "454px"}`,
        }}
      >
        <Modal
          open={openModal}
          disableEnforceFocus
          disableAutoFocus
          sx={{
            display: "flex",
            p: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "300px",
              height: "210px",
              background: "#FFFFFF",
              border: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "58px",
              }}
            >
              <div>
                <Typography
                  variant="h2"
                  color="#000000"
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  Senha alterada com sucesso!
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    width: 60,
                    height: 40,
                    backgroundColor: "#F08C10",
                    marginTop: "20px",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    background: "#F08C10",
                    "&: hover": {
                      background: "#F08C10",
                    },
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        <div style={{ padding: `${isMobile ? "0px 5px" : "0px"}` }}>
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              marginBottom: "12px !important",
            }}
          >
            Criar Senha
          </Typography>
        </div>
        <div>
          <Typography
            variant="subtitle1"
            color="#000000"
            sx={{
              width: "100%",
              padding: `${isMobile ? "0px 5px" : "0px"}`,
            }}
          >
            Cadastre abaixo sua senha, seguindo as recomendações de segurança:
          </Typography>
        </div>
        <div>
          <form onSubmit={handleSubmitPassword}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="#939598"
                    sx={{
                      marginTop: "20px",
                      fontSize: "10px",
                      fontWeight: 700,
                    }}
                  >
                    Sua senha
                  </Typography>
                </div>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    width: `${isMobile ? "300px" : "330px"}`,
                  }}
                  name="senha"
                  type={formData.showPassword ? "text" : "password"}
                  value={formData.senha}
                  required
                  onChange={handleInputPassword}
                  placeholder="Informe sua senha"
                  error={errorSenha}
                  inputProps={{
                    maxLength: 30,
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderColor: "#BDBDBD",
                      borderRadius: 0,
                      fontSize: "14px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {formData.showPassword ? (
                            <RemoveRedEyeOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <ContentValidate>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "22px",
                  padding: "0px 5px",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#636363",
                }}
              >
                Veja o que sua senha precisa conter:
              </Typography>
              <StyledListValidate>
                <li
                  style={{
                    color: /[A-Z]/.test(formData.senha) ? "#93b749" : "#636363",
                  }}
                >
                  conter letras maiúsculas (pelo menos uma)
                </li>
                <li
                  style={{
                    color: /\d/.test(formData.senha) ? "#93b749" : "#636363",
                  }}
                >
                  conter número (pelo menos um)
                </li>
                <li
                  style={{
                    color: /[!@#$%^&*(),.?":{}|<>]/.test(formData.senha)
                      ? "#93b749"
                      : "#636363",
                  }}
                >
                  conter caracteres especiais (pelo menos um)
                </li>
                <li
                  style={{
                    color: formData.senha.length >= 8 ? "#93b749" : "#636363",
                  }}
                >
                  conter mínimo 8 caracteres
                </li>
              </StyledListValidate>
              <div style={{ justifyContent: "space-around", display: "flex" }}>
                <StepValidate className={errorSenha ? "step-validate" : ""} />
                <StepValidate className={errorSenha ? "step-validate" : ""} />
                <StepValidate className={errorSenha ? "step-validate" : ""} />
                <StepValidate className={errorSenha ? "step-validate" : ""} />
              </div>
            </ContentValidate>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="#939598"
                    sx={{
                      marginTop: "20px",
                      fontSize: "10px",
                      fontWeight: 700,
                    }}
                  >
                    Confirme sua senha
                  </Typography>
                </div>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    width: `${isMobile ? "300px" : "330px"}`,
                  }}
                  name="confirmSenha"
                  type={formData.showPasswordConfirm ? "text" : "password"}
                  value={formData.confirmSenha}
                  required
                  onChange={handleInputPassword}
                  placeholder="Confirme sua senha"
                  error={errorSenhaConfirm}
                  inputProps={{
                    maxLength: 30,
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderColor: "#BDBDBD",
                      borderRadius: 0,
                      fontSize: "14px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {formData.showPassword ? (
                            <RemoveRedEyeOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color="#d32f2f"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {formData.senha !== formData.confirmSenha
                    ? "Senhas divergentes"
                    : ""}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleOpenModal}
                disabled={isDisabled}
                type="submit"
                sx={{
                  width: `${isMobile ? "300px" : "330px"}`,
                  height: "48px",
                  margin: "30px 0",
                  fontSize: "14px",
                  color: "#FFFFFF",
                  background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                  "&: hover": {
                    background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                  },
                }}
              >
                Confirmar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CadastrarSenha;
