import React, { ChangeEvent } from "react";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface ICustomPaginationProps {
  numberOfPages: number;
  page: number;
  setPage: (value: number) => void;
}

export const CustomPagination: React.FC<ICustomPaginationProps> = ({
  numberOfPages,
  page,
  setPage,
}) => {
  const handleChange = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination
        count={numberOfPages}
        page={page}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIosIcon, next: ArrowForwardIosIcon }}
            {...item}
            components={{
              previous: () => <button>Anterior</button>,
              next: () => <button>Próximo</button>,
            }}
          />
        )}
      />
    </Stack>
  );
};
