import { useContext } from "react";
import { CartContext  } from ".";

export const useCart = () => {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error('CartContext not found');
  }

  return context;
};