import { create, StateCreator } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface IUserProps {
  token: string | undefined;
  clearUser: () => void;
  setTokenHubJequiti: (token: string) => void;
}

const userInformation: StateCreator<
  IUserProps,
  [["zustand/persist", unknown]]
> = (set) => ({
  token: "",
  setTokenHubJequiti: (token) => set(() => ({ token })),
  clearUser: () => {
    set({ token: undefined });
    sessionStorage.removeItem("bau-storage");
    localStorage.removeItem("bau-storage");
  },
});

export const useHubJequiti = create<IUserProps>()(
  persist(userInformation, {
    name: "bau-storage",
    storage: createJSONStorage(() => localStorage),
  })
);
