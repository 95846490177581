import React, { FC, useEffect, useState } from "react";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box, Tab, Grid, Button } from "@mui/material";
import ContainerBox from "../../components/ContainerBox";
import { useIsMobile } from "../../utils/utils";

type Props = {
  arrays: any[] | null;
  identify: string;
};

const TabContainer: FC<Props> = ({ arrays, identify }) => {
  const [value, setValue] = useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(identify);
  }, [identify]);

  const handleDoc = (value: string) => {
    window.open(`${process.env.REACT_APP_IMAGE_SERVER_URL}${value}`, "_blank");
  };

  const theme = createTheme({
    palette: {
      text: {
        primary: "#001C47",
      },
      primary: {
        main: "#001C47",
      },
      secondary: {
        main: "#F08C10",
      },
    },
  });

  const isMobile = useIsMobile();

  return (
    <ContainerBox>
      <ThemeProvider theme={theme}>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList
                indicatorColor="secondary"
                textColor="primary"
                onChange={handleChange}
                aria-label="container tabs"
                variant="scrollable"
              >
                {arrays?.map((items: any, index: any) => (
                  <Tab
                    key={index}
                    sx={{
                      fontFamily: "Red Hat Display, sans-serif",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "20px",
                      lineHeight: "26px",
                      color: "#000000",
                      "&.Mui-selected": {
                        fontWeight: "700",
                      },
                      textTransform: "none",
                      "@media (max-width: 1199px)": {
                        fontSize: "16px",
                        lineHeight: "16px",
                      },
                    }}
                    label={items.label}
                    value={items.id}
                  />
                ))}
              </TabList>
            </Box>
            {arrays?.map((items: any, index: any) => (
              <TabPanel key={index} value={items.id}>
                {items.img && (
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 5px 50px 5px",
                      }}
                    >
                      {/* <img
                        src={`${process.env.REACT_APP_IMAGE_SERVER_URL}cabecalho_roleta_1.svg`}
                        alt={"Brasão Brasil"}
                        style={{ width: `${isMobile ? "310px" : "auto"}` }}
                      /> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        <div>
                          <img
                            src={`${process.env.REACT_APP_IMAGE_SERVER_URL}logo-regulamento.svg`}
                            alt={"Brasão Brasil"}
                            style={{
                              width: `${isMobile ? "310px" : "auto"}`,
                              margin: "10px auto",
                            }}
                          />
                          <p>
                            REGULAMENTO/PLANO DE OPERAÇÃO DA PROMOÇÃO
                            <br /> {items.nameRegulamento}
                            <br /> CERTIFICADO DE AUTORIZAÇÃO{" "}
                            {items.numeroCertificado}
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  dangerouslySetInnerHTML={{ __html: items.description }}
                  style={{ marginTop: 20 }}
                />
                <Grid
                  container
                  spacing={3}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 16px",
                    }}
                  >
                    {/* <Typography
                      variant="subtitle2"
                      color="#000000"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      PARA SABER MAIS FAÇA DOWNLOAD DO DOCUMENTO COMPLETO ABAIXO
                    </Typography> */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      onClick={() => handleDoc(items.doc)}
                      sx={{
                        width: { xs: "360px", sm: "360px", lg: "454px" },
                        height: "48px",
                        margin: "60px 0 0 0",
                        fontSize: "14px",
                        color: "#FFFF",
                        background: "#F08C10",
                        "&:hover": {
                          background: "#F08C10",
                        },
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="#FFFFFF"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        fazer download do documento completo
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </ThemeProvider>
    </ContainerBox>
  );
};

export default TabContainer;
