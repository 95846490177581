import React from "react";
import { Text } from "./styles";

interface TitleProps {
  isSubtitle?: boolean;
  text: string;
  isDisabled?: boolean;
}

export const Title: React.FC<TitleProps> = ({
  isSubtitle = false,
  text,
  isDisabled = false,
}) => {
  return (
    <Text isSubtitle={isSubtitle} isDisabled={isDisabled}>
      {text}
    </Text>
  );
};
