import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField,
  Typography,
  Dialog,
} from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cpf } from "cpf-cnpj-validator";
import {
  formatCpf,
  isValidCreditCardDate,
  validateCVV,
  validateCreditCard,
  validateName,
} from "../../../../../utils/utils";
import { Controller, useForm } from "react-hook-form";
import { ButtonMaterial, useStyles } from "./styles";
import { baseURLPayment } from "../../../../../api/axios";
import { userStore } from "../../../../../store/user/user";

interface ModalRecurringPaymentProps {
  openModalMobile: boolean;
  setOpenModalMobile(value: boolean): void;
  ticketNumber: string;
  token: string;
  setOpenToast: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  setMessage: (value: string) => void;
  getCarnes: () => Promise<void>;
}

export const ModalRecurringPayment: React.FC<ModalRecurringPaymentProps> = ({
  openModalMobile,
  setOpenModalMobile,
  ticketNumber,
  token,
  setOpenToast,
  setIsError,
  setMessage,
  getCarnes,
}) => {
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  const { user } = userStore();

  const paymentSchema = z.object({
    cardNumber: z
      .string()
      .min(19, "Número do cartão inválido")
      .max(19, "Número do cartão inválido")
      .refine((value) => validateCreditCard(value), "Cartão inválido"),
    expirationDate: z.string().refine((value) => isValidCreditCardDate(value), {
      message: "Informe uma data válida",
    }),
    cvv: z.string().refine((value) => validateCVV(value), {
      message: "Por favor, insira um CVV válido",
    }),
    fullName: z.string().refine((value) => validateName(value), {
      message: "Nome e sobrenome são obrigatórios",
    }),
    cpf: z.string().refine((value) => cpf.isValid(value), {
      message: "CPF inválido",
    }),
  });

  type PaymentFormData = z.infer<typeof paymentSchema>;
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PaymentFormData>({
    resolver: zodResolver(paymentSchema),
    defaultValues: {
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      fullName: "",
      cpf: "",
    },
  });

  const onSubmit = async (data: PaymentFormData) => {
    try {
      const response = await baseURLPayment.post(
        "api/carnes/programar-pagamento",
        {
          assinatura: true,
          cartaoCredito: {
            anoVencimento: data.expirationDate.substring(3, 7),
            codigoSeguranca: data.cvv,
            mesVencimento: data.expirationDate.substring(0, 2),
            numeroCartao: data.cardNumber.replace(/\s+/g, ""),
            nomeTitularCartao: data.fullName,
          },
          numeroCarne: ticketNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
            "Content-Type": "application/json",
            "token-user": token,
          },
        }
      );
      setMessage(response.data.message);
      setOpenToast(true);
      getCarnes();
    } catch (error: any) {
      console.log("Erro ao adicionar pagamento recorrente:", error);
      setOpenToast(true);
      setIsError(true);
      setMessage("Erro ao adicionar pagamento recorrente");
    }

    setOpenModalMobile(false);
    reset();
  };

  const cardNumber = watch("cardNumber");
  const expirationDate = watch("expirationDate");
  const cvv = watch("cvv");
  const fullName = watch("fullName");
  const cpfNumber = watch("cpf");

  const fullForm = useMemo(() => {
    if (cardNumber && expirationDate && cvv && fullName && cpfNumber) {
      return false;
    }
    return true;
  }, [cardNumber, expirationDate, cvv, fullName, cpfNumber]);

  const handleClose = () => {
    setOpenModalMobile(false);
    reset();
    setCheck(false);
  };

  function handleComplete() {
    if (check) {
      setValue("cpf", cpf.format(user?.userFiscalPerson || ""));
      setValue("fullName", `${user?.name || ""} ${user?.lastName || ""}`);
    } else {
      setValue("cpf", "");
      setValue("fullName", "");
    }
  }

  const handleCheck = () => {
    setCheck(!check);
  };

  useEffect(() => {
    handleComplete();
  }, [check]);

  console.log("expirationDate", expirationDate);

  return (
    <Dialog disableEnforceFocus disableAutoFocus open={openModalMobile}>
      <Box className={classes.root}>
        <h2 className={classes.title}>Pagamento com Cartão</h2>
        <p className={classes.subtitle}>
          Você optou por pagamento recorrente no cartão de crédito.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              gap: {
                xs: 5,
                sm: 2,
              },
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Controller
              name="cardNumber"
              control={control}
              render={({ field }) => {
                const handleChange = (
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  let value = e.target.value.replace(/\D/g, "");
                  value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
                  field.onChange(value);
                };

                return (
                  <TextField
                    {...field}
                    value={field.value || ""}
                    onChange={handleChange}
                    placeholder="Número do cartão"
                    error={!!errors.cardNumber}
                    helperText={errors.cardNumber?.message}
                    fullWidth
                    inputProps={{
                      maxLength: 19,
                    }}
                  />
                );
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: {
                  xs: 5,
                  sm: 2,
                },
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Controller
                name="expirationDate"
                control={control}
                render={({ field }) => {
                  const handleChange = (
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    let value = e.target.value.replace(/\D/g, "");
                    if (value.length > 2) {
                      value = value.slice(0, 2) + "/" + value.slice(2);
                    }
                    value = value.slice(0, 7);
                    field.onChange(value);
                  };

                  return (
                    <TextField
                      {...field}
                      value={field.value || ""}
                      onChange={handleChange}
                      placeholder="MM/AA"
                      error={!!errors.expirationDate}
                      helperText={errors.expirationDate?.message}
                      fullWidth
                      inputProps={{
                        maxLength: 5,
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="cvv"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="CVV"
                    error={!!errors.cvv}
                    helperText={errors.cvv?.message}
                    fullWidth
                    inputProps={{
                      maxLength: 3,
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box className={classes.checkbox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check}
                    onChange={handleCheck}
                    sx={{
                      color: "#F08C10",
                      "&.Mui-checked": {
                        color: "#F08C10",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    color="#000000"
                    sx={{
                      lineHeigth: 20,
                    }}
                  >
                    Usar os dados do cadastro
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: {
                xs: 5,
                sm: 2,
              },
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Nome impresso no cartão"
                  type="text"
                  name="name"
                  fullWidth
                  error={!!errors.fullName}
                  helperText={errors.fullName?.message}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                />
              )}
            />
            <Controller
              name="cpf"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  name="number"
                  placeholder="CPF do titular do cartão"
                  fullWidth
                  error={!!errors.cpf}
                  helperText={errors.cpf?.message}
                  value={field.value ? cpf.format(field.value) : ""}
                  onChange={(e) => {
                    const formattedValue = cpf.format(e.target.value);
                    field.onChange(formattedValue);
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box className={classes.footer}>
            <Typography
              variant="subtitle1"
              color="#F08C10"
              sx={{
                fontWeight: 700,
                lineHeight: "20px",
                fontSize: "16px",
              }}
            >
              PAGAMENTO RECORRENTE
            </Typography>
          </Box>
          <Box className={classes.textFooter}>
            <Typography
              color="#000000"
              variant="subtitle2"
              sx={{
                lineHeight: "20px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              Ao finalizar o pagamento, você autoriza o débito mensal dos
              próximos pagamentos diretamente <br /> no cartão de crédito
              informado.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 5,
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Button
              fullWidth
              sx={{
                fontSize: "16px",
                lineHeight: "10px",
                background: "#FFFFFF",
                color: "#F08C10",
                border: "1px solid #F08C10",
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <ButtonMaterial type="submit" disabled={fullForm}>
              Confirmar
            </ButtonMaterial>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};
