import { FC, useState, useEffect } from "react";

import Parcelas from "./Parcelas";
import MeusCarnes from "./MeusCarnes";

type Props = {
  menu: string;
};

const DataTableCarnes: FC<Props> = ({ menu }) => {
  const [view, setView] = useState(false);
  const [id, setId] = useState("");
  const [code, setCode] = useState("");

  const handleClickDetalhes = (item: any) => {
    setView(true);
    setCode(item);
    setId(item);
  };

  useEffect(() => {
    setView(false);
  }, [menu]);

  return (
    <>
      {view ? (
        <Parcelas
          id={id}
          code={code}
          setView={setView}
          onClickDetalhes={() => handleClickDetalhes(code)}
        />
      ) : (
        <MeusCarnes
          menu={menu}
          setView={setView}
          setId={setId}
          setCode={setCode}
          onClickDetalhes={() => handleClickDetalhes(code)}
          code={code}
        />
      )}
    </>
  );
};

export default DataTableCarnes;
