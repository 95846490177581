import { produto } from "../../utils/productsMagento";
import { useIsMobile } from "../../utils/utils";
import { useTheme } from "@mui/material/styles";
import Carousel from "react-bootstrap/Carousel";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import { CarouselArrows } from "../../components/CarouselArrows/CarouselArrows";
import { useAuth } from "../../context/AuthProvider/useAuth";
import React, { useState, useContext, useEffect } from "react";

const CardProduto = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const auth = useAuth();
  const [tokenRedirect, setTokenRedirect] = useState("");

  const handleBuyNowClick = (productName: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click",
      click_category: "carrossel",
      click_text: "Comprar agora",
      click_kit: productName,
    });

    const produtoSelecionado = produto.find((p) => p.name === productName);

    if (produtoSelecionado) {
      const url =
        localStorage.getItem("dataClient") !== null &&
        localStorage.getItem("dataClient") !== "" &&
        produtoSelecionado.name.includes("Carlinhos Maia")
          ? `${produtoSelecionado.url}?token=${localStorage.getItem(
              "dataClient"
            )}`
          : produtoSelecionado.url;

      window.location.href = url;
    }
  };

  const cards = [];
  for (let i = 0; i < produto.length; i += 4) {
    const row = [];
    for (let j = i; j < i + 4 && j < produto.length; j++) {
      row.push(
        <Card
          sx={{
            border: "1px solid #D9D9D9",
            width: 280,
            height: "448px",
            margin: "0 10px",
            "&:first-child": {
              marginLeft: 0,
            },
            "&:last-child": {
              marginRight: 0,
            },
          }}
        >
          <CardMedia
            component="img"
            height="260"
            sx={{ width: "260px" }}
            image={produto[j].image}
            alt=""
          />
          <CardContent sx={{ height: "40px", margin: "0 0 40px 0" }}>
            <Typography variant="subtitle1" color="#000000">
              {produto[j].name}
            </Typography>
          </CardContent>
          <CardContent sx={{ height: "24px", margin: "0 0 15px 0" }}>
            <Typography
              variant="subtitle1"
              color="#1C1C7B"
              fontWeight={theme.typography.fontWeightBold}
            >
              {produto[j].price.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </Typography>
          </CardContent>
          <Typography
            variant="button"
            component="a"
            onClick={() => handleBuyNowClick(produto[j].name)}
            target="_blank"
            color="#FFFFFF"
            fontWeight={theme.typography.fontWeightBold}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "244px",
              height: "48px",
              margin: "auto",
              cursor: "pointer",
              "&: hover": {
                color: "#FFFFFF",
              },
            }}
          >
            COMPRE AGORA
          </Typography>
        </Card>
      );
    }
    cards.push(
      <Carousel.Item key={i}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {row}
        </div>
      </Carousel.Item>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "27px", sm: "38px" },
          }}
        >
          Conheça nossas opções de Kits com carnês e adquira já o seu!
        </Typography>
      </div>
      {isMobile ? (
        <CarouselArrows indicators={false} interval={null} variant="dark">
          {produto.map((item: any, key: any) => (
            <Carousel.Item key={key}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    border: "1px solid #D9D9D9",
                    width: 280,
                    height: "448px",
                    margin: "0 10px",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="260"
                    sx={{ width: "260px" }}
                    image={item.image}
                    alt=""
                  />
                  <CardContent sx={{ height: "40px", margin: "0 0 40px 0" }}>
                    <Typography variant="subtitle1" color="#000000">
                      {item.name}
                    </Typography>
                  </CardContent>
                  <CardContent sx={{ height: "35px", margin: "0 0 15px 0" }}>
                    <Typography
                      variant="subtitle1"
                      color="#1C1C7B"
                      fontWeight={theme.typography.fontWeightBold}
                    >
                      {item.price.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Typography>
                  </CardContent>
                  <Typography
                    variant="button"
                    component="a"
                    onClick={() => handleBuyNowClick(item.name)}
                    target="_blank"
                    color="#FFFFFF"
                    fontWeight={theme.typography.fontWeightBold}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "260px",
                      height: "48px",
                      margin: "auto",
                      "&: hover": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    COMPRE AGORA
                  </Typography>
                </Card>
              </div>
            </Carousel.Item>
          ))}
        </CarouselArrows>
      ) : (
        <CarouselArrows indicators={false} interval={null} variant="dark">
          {cards}
        </CarouselArrows>
      )}
    </>
  );
};

export default CardProduto;
