import { Grid, Divider } from "@mui/material";
import { GridPayment, DivPayment, BoxDivider, Copyright } from "./FooterStyles";
import LinkFooter from "../LinkFooter";
import LinkFooterMobile from "../LinkFooterMobile";
import ContainerBox from "../ContainerBox";
import { useIsMobile } from "../../utils/utils";
import { payment, securitySelo } from "../../utils/data";

const Footer = () => {
  const isMobile = useIsMobile();

  return (
    <footer style={{ marginBottom: 0, padding: 0 }} id="footer">
      <ContainerBox>
        <Grid xs={12}>{isMobile ? <LinkFooterMobile /> : <LinkFooter />}</Grid>
        <GridPayment container>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            sm={6}
            sx={{ paddingBottom: "40px !important" }}
          >
            <DivPayment>
              <div>
                <BoxDivider>
                  <p>FORMAS DE PAGAMENTO</p>
                </BoxDivider>
                <BoxDivider>
                  <DivPayment>
                    {payment.map((url: any, index: any) => (
                      <img src={url.imagem} alt={url.name} key={index} />
                    ))}
                  </DivPayment>
                </BoxDivider>
              </div>
            </DivPayment>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            sm={6}
            sx={{ paddingBottom: "40px !important" }}
          >
            <DivPayment>
              <div>
                <BoxDivider>
                  <p>Selos e Segurança</p>
                </BoxDivider>
                <BoxDivider>
                  <DivPayment>
                    {securitySelo.map((url: any, index: any) => (
                      <img
                        src={url.imagem}
                        alt={url.name}
                        key={index}
                        style={{ width: 140 }}
                      />
                    ))}
                  </DivPayment>
                </BoxDivider>
              </div>
            </DivPayment>
          </Grid>
        </GridPayment>
        <Grid xs={12}>
          <Divider
            sx={{
              borderColor: "#D1D1D1",
              opacity: "0.5",
              transform: "matrix(1, 0, 0, -1, 0, 0)",
            }}
          />
        </Grid>
        <Copyright>
          <span>
            SS COMÉRCIO DE COSMÉTICOS E PRODUTOS DE HIGIENE PESSOAL LTDA - CNPJ:
            07.278.350/0001-63 AV. DAS COMUNICAÇÕES, 927 - VILA JARAGUA, OSASCO
            - SP, 06276-906
          </span>
        </Copyright>
      </ContainerBox>
    </footer>
  );
};

export default Footer;
