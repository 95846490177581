import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined'
import { Typography } from '@mui/material'

const TextoBoleto = () => {
  return (
    <div
      style={{
        margin: '10% 5%',
      }}
    >
      <Typography 
        variant='subtitle1'
        sx={{
          fontWeight: 700,
          margin: '0 0 20px 0'
        }}
      >
        <ReceiptOutlinedIcon /> Boleto
      </Typography>
      <Typography
        variant='subtitle2'
        component='p'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <PrintOutlinedIcon />
        <span style={{marginLeft: '10px'}}>
          Você pode imprimir o boleto e pagar no banco, 
          ou ainda pagar pela internet utilizando o código de barras.
        </span>
      </Typography>
      <Typography
        variant='subtitle2'
        component='p'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <QueryBuilderOutlinedIcon />
        <span style={{marginLeft: '10px'}}>
          O boleto tem validade de 1 dia e, 
          se não for pago até a data de vencimento, 
          o pedido será cancelado.
        </span>
      </Typography>
      <Typography
        variant='subtitle2'
        component='p'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <span style={{marginLeft: '35px'}}>
          Seu pedido pode levar até 3 dias para ser processado após a confirmação de pagamento. 
          Não é possível fazer depósito ou transferência entre contas por meio do boleto, e o 
          boleto não pode ser enviado pelo correio.
        </span>
      </Typography>
      <Typography
        variant='subtitle2'
        component='p'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <span style={{marginLeft: '35px'}}>
          Após a finalização não será mais possível adicionar ou retirar itens no seu pedido.
        </span>
      </Typography>
      
    </div>
  )
}

export default TextoBoleto
