import { styled } from "@mui/material/styles";

export const Content = styled("div")({
  width: "35rem",
  height: "15rem",
  backgroundColor: "red",
});

export const Subtitle = styled("p")({
  color: "#1C1C7B",
  fontWeight: 700,
});
