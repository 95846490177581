import ContainerBox from "../ContainerBox";
import { Grid, Typography } from "@mui/material";
import { ContainerButton } from "./ButtonCarne.styled";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider/useAuth";

const ButtonCarne = () => {
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();
  const navigate = useNavigate();

  const handleClick = (nameMenuItem: string, url: string | (() => void)) => {
    if (nameMenuItem === "Comprar Carnê") {
      window.location.href = url as string;
    } else {
      navigate("/minha-conta");
      toggleSubMenu(true);
      handleSideBar(nameMenuItem);
    }
  };
  const auth = useAuth();
  const user = auth?.user;

  const tokenRedirectMagento = localStorage.getItem("dataClient");
  const isLogin =
    tokenRedirectMagento !== null && tokenRedirectMagento !== ""
      ? "?token=" + tokenRedirectMagento
      : "";
  const buttonItens = [
    {
      name: "Comprar Carnê",
      url: `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html${isLogin}`,
    },
    {
      name: "Pagar Parcelas",
      url: () => handleSideBar("Pagar Parcelas"),
    },
    {
      name: "Cadastrar Carnê",
      url: () => {
        handleSideBar("Cadastrar Carnê");
      },
    },
    {
      name: "Resgatar Produtos",
      url: () => {
        handleSideBar("Resgatar Produtos");
      },
    },
    ...(user
      ? [
          {
            name: "Consultar Carnês",
            url: () => {
              handleSideBar("Consultar Carnês");
            },
          },
        ]
      : []),
    {
      name: "Meus Cupons",
      url: () => {
        handleSideBar("Meus Cupons");
      },
    },
  ];
  return (
    <ContainerBox
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        flexWrap: "wrap",
        justifyContent: {
          sm: "center",
          md: "space-between",
          lg: "space-between",
        },
        alignItems: "center",
      }}
    >
      {buttonItens.map((item, key) => (
        <ContainerButton>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              variant="button"
              component="a"
              target="_blank"
              rel="noreferrer"
              color="#FFFFFF"
              sx={{
                minWidth: `${user ? "175px" : "205px"}`,
                height: "48px",
                fontSize: "14px",
                margin: "5px 10px",
                padding: "10px",
                flex: 1,
                textAlign: "center",
                cursor: "pointer",
                "&:first-child": {
                  marginLeft: 0,
                },
                "&:last-child": {
                  marginRight: 0,
                },
                "&:hover": {
                  color: "#FFFFFF",
                },
              }}
              onClick={() => handleClick(item.name, item.url)}
            >
              {item.name}
            </Typography>
          </Grid>
        </ContainerButton>
      ))}
    </ContainerBox>
  );
};

export default ButtonCarne;
