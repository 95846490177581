import { apiToken } from '../../services/api';
import axios from 'axios';

export const PaymentCredit = async (data: any, tokenUser: any) => {

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Content-Type': 'application/json',
      'token-user': `Bearer ${tokenUser}`,
    },
  });

  try {
    const response = await request.post(`api/v01/pagamento/cartao/pagar`, JSON.stringify(data));
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const PaymentPix = async (data: any, tokenUser: any) => {

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Content-Type': 'application/json',
      'token-user': `Bearer ${tokenUser}`,
    },
  });

  try {
    const response = await request.post(`api/v01/pagamento/pix/pagar`, JSON.stringify(data));
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const PaymentInfo = async (data: any, tokenUser: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
      'token-user': `Bearer ${tokenUser}`,
    },
  });

  try {
    const response = await request.get(`api/v01/pagamento/pedido/parcela/status/${data}`);
    return response.data
  } catch (error: any) {
    throw error.response.data;
  }
}

export const SegundaVia = async (data: any, tokenUser: any) => {

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Content-Type': 'application/json',
      'token-user': `${tokenUser}`,
      'segundaVia': `${data}`
    },
  });

  try {
    const response = await request.get(`segundaVia`);
    return response.data
  } catch (error: any) {
    throw error.response.data;
  }
}