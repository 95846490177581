import { api, apiToken } from '../../services/api';
import axios from 'axios';

export const SearchCep = async (cep: string) => {
  // const response = await apiToken();

  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const request = await authorization.get(`api/v01/cliente/cep/buscar/${cep}`);
    return request.data;

  } catch (error: any) {
    throw error;
  }
}

export const LoginRequest = async (cpf: string, senha: string) => {
  // const response = await apiToken();

  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const request = await authorization.post('api/v01/login/autenticar', { usuario: cpf, senha: senha });
    return request.data.token;

  } catch (error: any) {
    throw error.response.data;
  }
}

export const RegisterRequest = async (data: any) => {
  // const response = await apiToken();

  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },

  });

  try {
    const response = await authorization.post('api/v01/cliente/salvar/precadastro', JSON.stringify(data));
    return response.data;
  } catch (error: any) {
    throw error;
  }
}


export const SendSMS = async (data: any) => {
  // const response = await apiToken();
  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },

  });
  try {
    const response = await authorization.post('api/v01/cliente/envia-sms', JSON.stringify(data))
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

export const RegisterCarne = async (data: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.put('api/v01/carne/vinculo/solicitar', JSON.stringify(data));
    return response.data;

  } catch (error: any) {
    throw error.response.data;
  }
}

export const ListCarne = async (data: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });
  try {
    const response = await request.get(`api/v01/carne/listar/${data}`);
    return response.data;
  } catch (error: any) {

    throw error.response.data;
  }
}

export const ListResgateCarne = async (data: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.get(`api/v01/carne/resgate/listar/${data}`);
    return response.data;
  } catch (error: any) {

    throw error.response.data;
  }

}

export const ListParcela = async (data: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.get(`api/v01/carne/buscar/${data}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}


export const getDetailParcela = async (data: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.get(`api/v01/pagamento/pedido/parcela/status/${data}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}


export const UrlResgate = async (data: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.post(`api/v01/carne/gerarUrlResgate`, JSON.stringify(data));
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const PaymentInfo = async (data: any, tokenUser: any) => {
  const carneNumber = data.codigoBarras;
  const serie = carneNumber.substring(0, 3);
  const numeroCarne = carneNumber.substring(3, 10);

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API_SAC_BAU_HML}`,
    headers: {
      'Authorization': `Bearer ${tokenUser}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.get(`cliente/${data.cpfClie}/${serie}/${numeroCarne}/${data.numero}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const SetRecorrencia = async (data: any, tokenUser: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
      'token-user': tokenUser,
    },
  });

  try {
    const response = await request.post(`api/v01/carne/recorrencia/programar`, JSON.stringify(data));
    return response.data;
  } catch (error: any) {
    throw error.response.data.error;
  }
}

export const DeleteRecorrencia = async (data: any, tokenUser: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
      'token-user': `Bearer ${tokenUser}`,
    },
  });

  try {
    const response = await request.delete(`api/v01/carne/cancelar-pagamento-recorrente?numeroCarne=${data}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const StatusPagamento = async (data: any, tokenUser: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
      'token-user': `Bearer ${tokenUser}`,
    },
  });

  try {
    const response = await request.get(`api/v01/pagamento/pedido/parcela/status/${data}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const Descript = async (data: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}decoded-token?token=${data}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const Encrypt = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API}encoder-token`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

export const BuscaCliente = async (data: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const req = await request.post(`api/v01/cliente/buscar?cpf=${data}`);
    return req;
  } catch (error: any) {
    throw error.message;
  }
}

export const ValidaCliente = async (cpf: string, dataNasc: string) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });
  try {
    const getCliente = await request.get(`api/v01/cliente/valida-cliente/${cpf}/${dataNasc}`)
    return getCliente.data;
  } catch (error: any) {
    const erro = error.response.data.message
    throw erro;
  }
}

export const AtualizaCliente = async (data: any, tokenUser: any) => {
  // const response = await apiToken();
  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'content-type': 'application/json',
      'token-user': `Bearer ${tokenUser}`,
    },
  });

  try {
    const response = await request.put(`api/v01/cliente/atualiza-cliente?cpfCliente=${data.documentos[0].valor}`, JSON.stringify(data));
    return response;
  } catch (error: any) {
    throw error;
  }
}


export const ValidaPin = async (login: string, pin: string): Promise<boolean> => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bear ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'aplication/json',
    },
  });
  try {
    const getPin = await request.get(`api/v01/cliente/valida-pin/${login}/${pin}`)
    return getPin.data;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export const ValidaEmail = async (email: string) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bear ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'aplication/json',
    },
  });
  try {
    const getEmail = await request.get(`email?email=${email}`)
    return getEmail.data;
  } catch (error: any) {
    const erro = error.response.data.message
    throw erro;

  }
}

export const ListPedidos = async (data: any) => {
  // const response = await apiToken();

  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'content-type': 'application/json',
    },
  });

  try {
    const response = await authorization.get(`api/v01/cliente/${data}/meus-pedidos`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

export const SendEmail = async (cpf: any, dataNasc: any, email: any) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.get(`api/v01/cliente/envia-email/${cpf}/${dataNasc}/${email}`);
    return response.data;
  } catch (error: any) {

    throw error.response.data;
  }

}

export const ListCarnePagamento = async (cpf: any) => {
  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const response = await request.get(`api/v01/carne/listar/apagar/${cpf}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}

