import styled from "styled-components";
import { Badge } from "@mui/material";

export const MenuUrl = styled.a`
  @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");
  font-family: "Red Hat Display", sans-serif !important;
  text-decoration: none;
  text-transformation: uppercase;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  &:hover {
    color: #ffffff;
  }
`;

export const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    background: "#F08C10",
  },
});

export const StyledLink = styled.a`
  color: "#F08C10";
`;

export const StyledMenu = styled.div`
  .menu-item {
    position: relative;
    transition: color 0.1s ease;
    margin: 0px 5px;
  }
  .menu-item:hover {
    color: #f08c10;
  }
  .menu-item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3.6px;
    background-color: #f08c10;
    transition: width 0.1s ease;
  }
  .menu-item:hover::after {
    width: 100%;
    top: 38px;
  }
  .c-carne:hover::after {
    width: 100%;
    top: 43px;
  }
`;
export const UserName = styled.p`
  align-items: center;
  font-family: "Red Hat Display", sans-serif !important;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
`;
