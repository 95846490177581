import { FC } from "react";
import { Typography, Grid, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ContainerBox from "../../components/ContainerBox";

type Props = {
  arrays: any[];
};

const CardListProgramas: FC<Props> = ({ arrays }) => {
  const navigate = useNavigate();

  const handleClick = (value: string) => {
    const id = value;
    navigate("/regulamento", { state: { id } });
  };

  const renderCard = (items: any, index: number) => {
    const isEvenIndex = index % 2 === 1;

    return (
      <div
        style={{
          minHeight: 508,
          paddingBottom: "92px",
          backgroundColor: isEvenIndex ? "#E6E9ED" : "#FFFFFF",
        }}
        key={index}
      >
        <ContainerBox>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {isEvenIndex ? (
              <>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{ display: "flex", justifyContent: "left" }}
                >
                  <Card
                    sx={{
                      width: 360,
                      margin: "92px 0 0 0",
                      boxShadow: "none",
                      background: "none !important",
                    }}
                  >
                    <img
                      src={items.image}
                      alt={items.label}
                      style={{
                        width: "400px",
                      }}
                    />
                  </Card>
                </Grid>
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    <Typography
                      variant="h2"
                      color="#000000"
                      sx={{
                        lineHeight: "24px",
                        fontSize: "18px",
                        marginTop: "92px",
                      }}
                    >
                      {items.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#000000"
                      sx={{
                        textAlign: "justify",
                        lineHeight: "26px",
                        margin: "32px 0 92px 0",
                        width: 552,
                        height: 320,
                        a: {
                          textDecorationLine: "underline",
                          color: "#000000",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: items.description }}
                    />
                  </Grid>
                  {items.link && (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "right" }}
                    >
                      <Typography
                        variant="subtitle1"
                        component="button"
                        color="#F08C10"
                        onClick={() => handleClick(items.id)}
                        sx={{
                          fontWeight: 700,
                          border: "none",
                          textDecoration: "underline",
                          marginRight: "auto",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#F08C10",
                          },
                        }}
                      >
                        {items.link}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    <Typography
                      variant="h2"
                      color="#000000"
                      sx={{
                        lineHeight: "24px",
                        fontSize: "18px",
                        marginTop: "92px",
                      }}
                    >
                      {items.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#000000"
                      sx={{
                        textAlign: "justify",
                        margin: "32px 0 92px 0",
                        a: {
                          textDecorationLine: "underline",
                          color: "#000000",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: items.description }}
                    />
                  </Grid>
                  {items.link && (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "right" }}
                    >
                      <Typography
                        variant="subtitle1"
                        component="button"
                        color="#F08C10"
                        onClick={() => handleClick(items.id)}
                        sx={{
                          fontWeight: 700,
                          textDecoration: "underline",
                          border: "none",
                          marginRight: "auto",
                          cursor: "pointer",
                          background: "#fff",
                          "&:hover": {
                            color: "#F08C10",
                          },
                        }}
                      >
                        {items.link}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Card
                    sx={{
                      width: 360,
                      margin: "92px 0",
                      boxShadow: "none",
                      background: "none !important",
                    }}
                  >
                    <img
                      src={items.image}
                      alt={items.label}
                      style={{
                        height: "326",
                      }}
                    />
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </ContainerBox>
      </div>
    );
  };

  return <>{arrays.map(renderCard)}</>;
};

export default CardListProgramas;
