import React from "react";
import { useIsMobile } from "../../utils/utils";
import { delivery, timeCarousel } from "../../utils/data";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import ContainerBox from "../ContainerBox";
import { CarouselArrows } from "../CarouselArrows/CarouselArrows";
import Carousel from "react-bootstrap/esm/Carousel";

const RodapeEntrega = () => {
  const isMobile = useIsMobile();

  return (
    <div style={{ margin: "62px 0 0 0" }}>
      {isMobile ? (
        <CarouselArrows indicators={false} variant="dark">
          {delivery.map((item: any, key: any) => (
            <Carousel.Item interval={timeCarousel} key={key}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "none",
                    boxShadow: "none",
                    margin: "0 24px",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: "auto",
                      height: 50,
                    }}
                    image={item.image}
                    alt="Live from space album cover"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography variant="h2">{item.title}</Typography>
                      <Typography variant="subtitle2" color="#000000">
                        {item.text}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </div>
            </Carousel.Item>
          ))}
        </CarouselArrows>
      ) : (
        <>
          <Divider
            sx={{
              borderColor: "#D1D1D1",
              opacity: "0.5",
              transform: "matrix(1, 0, 0, -1, 0, 0)",
            }}
          />
          <Box
            sx={{
              height: "184px",
              display: "flex",
            }}
          >
            <ContainerBox
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              {delivery.map((item: any, key: any) => (
                <Card
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "320px",
                    borderRadius: "none",
                    boxShadow: "none",
                  }}
                >
                  <img
                    src={item.image}
                    alt=""
                    style={{ width: "auto", height: 50 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography variant="h2">{item.title}</Typography>
                      <Typography variant="subtitle2" color="#000000">
                        {item.text}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              ))}
            </ContainerBox>
          </Box>
          <Divider
            sx={{
              borderColor: "#D1D1D1",
              opacity: "0.5",
              transform: "matrix(1, 0, 0, -1, 0, 0)",
            }}
          />
        </>
      )}
    </div>
  );
};

export default RodapeEntrega;
