import { Typography, Grid } from "@mui/material";
import ContainerBox from "../../components/ContainerBox";
import TextoBau from "./TextoBau";
import { useIsMobile } from "../../utils/utils";
import CardStep from "../../components/CardStep";
import { useNavigate } from "react-router-dom";

const SobreBau = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const handleClick = () => {
    const id = "0";
    navigate("/regulamento", { state: { id } });
  };

  return (
    <div style={{ margin: `${isMobile ? "50px 0" : "172px 0"}` }}>
      <ContainerBox>
        <Typography variant="h1">Sobre o Carnê do Baú</Typography>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: `${isMobile && "0px 16px"}`,
            }}
          >
            <Typography
              variant="subtitle1"
              color="#000000"
              sx={{
                textAlign: "justify",
              }}
              component={TextoBau}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "right", margin: "60px 0" }}
          >
            <Typography
              variant="h2"
              component="a"
              color="#F08C10"
              onClick={handleClick}
              sx={{
                cursor: "pointer",
                margin: "0 15px",
                "&:hover": {
                  color: "#F08C10",
                },
              }}
            >
              Veja o regulamento
            </Typography>
          </Grid>
          <CardStep />
        </Grid>
      </ContainerBox>
    </div>
  );
};

export default SobreBau;
