import ContainerBox from "../../components/ContainerBox";
import TextoPremios from "./TextoPremios";
import { Typography } from "@mui/material";
import VideoPlayer from "../../components/VideoPlayer";
import { useIsMobile } from "../../utils/utils";

const Premios = () => {
  const isMobile = useIsMobile();

  return (
    <div style={{ margin: `${isMobile ? "50px 0" : "172px 0"}` }}>
      <ContainerBox>
        <Typography variant="h1">Prêmios</Typography>
        <Typography
          variant="subtitle1"
          color="#000000"
          sx={{
            textAlign: "justify",
            margin: `${isMobile && "0 15px"}`,
            a: {
              color: "#000000",
            },
          }}
        >
          <TextoPremios />
        </Typography>
        <VideoPlayer videoLink={"TmaWNz4gRIw"} />
      </ContainerBox>
    </div>
  );
};

export default Premios;
