import axios from "axios";

export const baseURL = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
    "Content-Type": "application/json",
  },
});
export const baseURLWithoutToken = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

export const baseUrlHubJequiti = axios.create({
  baseURL: `${process.env.REACT_APP_API_HUB_JEQUITI}`,
});

export const baseURLPayment = axios.create({
  baseURL: `${process.env.REACT_APP_API_PAGAMENTOS}`,
});
