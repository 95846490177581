import React, { createContext, useContext, useState } from "react";
import { getDetailParcela } from "../AuthProvider/util"

type HandleParcelaContextType = {
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  menuSidebar: boolean;
  setMenuSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  getDetail: (data: any) => Promise<void>;
};

const HandleParcelaContext = createContext<
HandleParcelaContextType | undefined
>(undefined);

export const HandleParcelaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<boolean>(false);
  const [menuSidebar, setMenuSidebar] = useState<boolean>(false);


  const getDetail =  async(data: any) => {
    const response = await getDetailParcela(data);
    return response
  }


  const contextValue: HandleParcelaContextType = {
    state, 
    setState,
    setMenuSidebar,
    menuSidebar,
    getDetail
  };

  return (
    <HandleParcelaContext.Provider value={contextValue}>
      {children}
    </HandleParcelaContext.Provider>
  );

};

export const useHandleParcela = () => {
  const context = useContext(HandleParcelaContext);
  if (!context) {
    throw new Error(
      "useHandleParcela deve ser usado dentro de um HandleParcelaProvider"
    );
  }
  return context;
};