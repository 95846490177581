import React, { FC, useEffect, useState } from "react";
import { Drawer, IconButton, Typography, Paper, Badge } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { TableProductMobile } from "../TableProduct";
import { theme } from "../../styles";

type Props = {
  open: boolean;
  arrCart: any[];
  remove: any;
  quantity: string;
  change: any;
  changeClose: any;
};

const MiniCart: FC<Props> = ({
  open,
  arrCart,
  remove,
  quantity,
  change,
  changeClose,
}) => {
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("tokenBackOffice");

    storedToken ? setValidate(true) : setValidate(false);
  }, [validate]);

  const calcularValorTotal = (): number => {
    let total = 0;
    for (const item of arrCart) {
      const valorTotalItem =
        item.type === "carne" ? item.valor * parseInt(quantity) : item.valor;
      total += valorTotalItem;
    }
    return total;
  };

  const calcularValorDesconto = (): number => {
    let desconto = 0;
    for (const item of arrCart) {
      const valorDescontoItem = item.desc;
      desconto += valorDescontoItem;
    }
    return desconto;
  };

  console.log("arrCart", arrCart);

  return (
    <Paper elevation={3}>
      <Drawer
        sx={{
          width: "360px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "360px",
            height: "478px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            margin: "10px 0",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div
          style={{
            width: "100%",
            height: "48px",
            background: "#1C1C7B",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            color="#FFFFFF"
            sx={{
              margin: "0 15px",
            }}
          >
            Meu Carrinho
          </Typography>

          <IconButton
            onClick={changeClose}
            sx={{
              marginLeft: "auto",
            }}
            style={{ color: "#FFFFFF" }}
          >
            <CloseTwoToneIcon />
          </IconButton>
        </div>
        {arrCart.length === 0 ? (
          <div
            style={{
              background: "rgba(219, 221, 225, 0.9)",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ShoppingCartOutlinedIcon
              sx={{
                fontSize: 200,
                color: "#F08C10",
              }}
            />
            <Typography
              variant="h2"
              component="p"
              color="#F08C10"
              sx={{
                color: "#F08C10",
              }}
            >
              Seu carrinho está vazio :(
            </Typography>
          </div>
        ) : (
          <>
            <TableProductMobile
              arr={arrCart}
              removeLine={remove}
              qtd={quantity}
              handle={change}
            />
            <div
              style={{
                background: "#EAEAEC",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 15px",
                }}
              >
                <Typography variant="subtitle1" color="#000000">
                  Valor do Pedido:
                </Typography>
                <Typography variant="subtitle1" color="#000000">
                  {calcularValorTotal().toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 15px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="#001C47"
                  fontWeight={theme.typography.fontWeightBold}
                >
                  Total:
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#001C47"
                  fontWeight={theme.typography.fontWeightBold}
                >
                  {calcularValorTotal().toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </div>
            </div>
            <Typography
              id="btn-ver-carrinho"
              variant="button"
              component="a"
              href={`${validate ? "/autenticar/checkout" : "/checkout"}`}
              color="#FFFFFF"
              sx={{
                margin: 0,
                width: "100%",
                height: "48px",
                fontSize: "16px",
                cursor: "pointer",
                borderRadius: 0,
                "&: hover": {
                  color: "#FFFFFF",
                },
              }}
            >
              ver carrinho
            </Typography>
          </>
        )}
      </Drawer>
    </Paper>
  );
};

export default MiniCart;
