import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ContainerBox from "../ContainerBox";
import { formatNumberCarne } from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useIsMobile } from "../../utils/utils";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";

const RegistrarCarne = () => {
  const { setMenuSidebar } = useHandleParcela();
  const isMobile = useIsMobile();
  const auth = useAuth();
  const navigate = useNavigate();
  const [carneNumber, setCarneNumber] = useState("");
  const [serieNumber, setSerieNumber] = useState("");
  const [idUser, setIdUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState<null | any>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();
  const [alertOpen, setAlertOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const tokenBko = localStorage.getItem("backOfficeId");

  useEffect(() => {
    if (tokenBko) setValidate(true);
  }, [tokenBko]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      handleDescript(token);
    }
  }, []);

  const handleDescript = async (data: string) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(data);
        setDataUser(request);
        setIdUser(data);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    setCarneNumber(value);
  };

  const handleSerie = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.slice(0, 3);
    setSerieNumber(value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();

    const carnePart = carneNumber.slice(0, 6);
    const dvPart = carneNumber.slice(-1);

    const data = {
      cpf: dataUser.login,
      serie: serieNumber,
      numeroCarne: carnePart,
      digito: dvPart,
    };

    try {
      const response = await auth?.carneRegister(data);
      setLoading(false);
      setAlertMessage(JSON.stringify(response));
      setAlertStatus("success");
      setAlertOpen(true);
      setCarneNumber("");
      setSerieNumber("");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "button_click",
        click_category: "meus_carnes",
        click_text: "cadastrar",
      });
    } catch (error: any) {
      setLoading(false);
      setAlertMessage(JSON.stringify(error));
      setAlertStatus("error");
      setAlertOpen(true);
    }
  };

  const handleClick = () => {
    validate ? navigate("/autenticar/minha-conta") : navigate("/minha-conta");
    toggleSubMenu(true);
    handleSideBar("Consultar Carnês");
  };

  return (
    <>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={10}
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "100px",
          }}
        >
          <ContainerBox
            sx={{ width: { sm: "364px", md: "775px", lg: "775px" } }}
          >
            <div
              style={{
                flexDirection: "column",
              }}
            >
              <Typography variant="h1" sx={{ margin: "30px 0" }}>
                {isMobile && (
                  <ArrowBackIosIcon
                    onClick={() => setMenuSidebar(false)}
                    sx={{ fontSize: "32px" }}
                  />
                )}
                Cadastrar Carnês
              </Typography>
              <Typography
                variant="subtitle1"
                color="#000000"
                sx={{ margin: "30px 0" }}
              >
                <strong>Adicione</strong> seus carnês, informando abaixo os
                códigos:
                <PopupState variant="popover" popupId="popup-info">
                  {(popupInfo) => (
                    <React.Fragment>
                      <IconButton color="inherit" sx={{ padding: 0 }}>
                        <HelpOutlineIcon
                          {...bindTrigger(popupInfo)}
                          sx={{
                            fontSize: 16,
                            color: "#BDBDBD",
                            margin: "0 15px",
                          }}
                        />
                      </IconButton>
                      <Menu
                        {...bindMenu(popupInfo)}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            backgroundColor: "#FFFFFF",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem onClick={popupInfo.close}>
                          <img
                            src={`${process.env.REACT_APP_IMAGE_SERVER_URL}dados-carne.svg`}
                            alt={"carne"}
                            style={{
                              width: `${isMobile ? "330px" : "330px"}`,
                              height: `${isMobile ? "293px" : "150px"}`,
                            }}
                          />
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box
                  border="1px solid #D9D9D9"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 30px",
                    }}
                  >
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="#000000"
                          sx={{
                            fontSize: "10px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Número de série
                        </Typography>
                        <TextField
                          name="serie"
                          value={serieNumber.replace(/\D/g, "")}
                          onChange={handleSerie}
                          placeholder="000"
                          required
                          sx={{
                            boxSizing: "border-box",
                            background: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "4px",
                            width: { xs: "250px", sm: "250px", md: "200px" },
                          }}
                          InputProps={{
                            style: {
                              height: "48px",
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            color="#000000"
                            sx={{
                              fontSize: "10px",
                              fontWeight: 700,
                              textTransform: "uppercase",
                            }}
                          >
                            Número do carnê DV
                          </Typography>
                        </div>
                        <TextField
                          name="Carne"
                          value={formatNumberCarne(carneNumber)}
                          onChange={handleChange}
                          placeholder="000000-0"
                          required
                          sx={{
                            boxSizing: "border-box",
                            background: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "4px",
                            width: { xs: "250px", sm: "250px", md: "200px" },
                          }}
                          inputProps={{ maxLength: 8 }}
                          InputProps={{
                            style: {
                              height: "48px",
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <Button
                          type="submit"
                          sx={{
                            width: { xs: "250px", sm: "250px", md: "200px" },
                            height: "48px",
                            fontSize: "14px",
                            margin: "25px 0 0 0",
                            color: "#FFFFFF",
                            "&: hover": {
                              background: "#F08C10",
                            },
                          }}
                        >
                          {loading ? (
                            <CircularProgress sx={{ color: "#FFFFFF" }} />
                          ) : (
                            "cadastrar"
                          )}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "25px 0",
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="a"
                  onClick={() => handleClick()}
                  color="#1C1C7B"
                  sx={{
                    fontWeight: 500,
                    lineHeight: "15px",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {`< Consulte aqui os seus carnês cadastrados`}
                </Typography>
              </div>
            </div>
          </ContainerBox>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertStatus || "info"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegistrarCarne;
function setValidate(arg0: boolean) {
  throw new Error("Function not implemented.");
}
