import { styled } from "@mui/material/styles";

interface TitleProps {
  isSubtitle: boolean;
  isDisabled: boolean;
}

export const Text = styled("div")<TitleProps>(({ isSubtitle, isDisabled }) => ({
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "15px",
  color: isDisabled ? "#8D8D8D" : isSubtitle ? "#605F5F" : "#1C1C7B",
  marginTop: isSubtitle ? "10px" : "0px",
}));
