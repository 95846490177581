import {FC} from 'react'
import { Pagination } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';

const themes = createTheme({
  palette: {
    primary: {
      main: '#F08C10',
    },
  },
});

type Props = {
  countPage: number;
  page: number;
  handle: any;
}

const SetPagination:FC<Props> = ({countPage, page, handle}) => {
  return (
    <ThemeProvider theme={themes}>
      <Pagination
        color="primary"
        sx={{
          '& .MuiPaginationItem-root': {
            fontWeight: 700,
          },
          '& .Mui-selected': {
            '&:hover': {
              background: '#F08C10',
            },
          },
        }}
        shape="rounded"
        size='small'
        count={countPage}
        page={page}
        onChange={handle}
      />
    </ThemeProvider>
  )
}

export default SetPagination