import { styled } from "@mui/material/styles";

interface TitleProps {
  isDisabled: boolean;
}

export const TitleCoupon = styled("h6")<TitleProps>(({ isDisabled }) => ({
  fontWeight: 700,
  color: isDisabled ? "#8D8D8D" : "#F08C10",
}));

export const Button = styled("button")<TitleProps>(({ isDisabled }) => ({
  backgroundColor: isDisabled ? "#fff" : "#F08C10",
  textTransform: "uppercase",
  color: isDisabled ? "#8D8D8D" : "#fff",
  border: "none",
  borderRadius: "3px",
  padding: "10px 15px",
  fontWeight: 700,
}));

export const Text = styled("p")({
  color: "#8D8D8D",
  fontSize: "12px",
  textAlign: "center",
  textTransform: "uppercase",
  fontWeight: 700,
});
