import styled from 'styled-components'

export const ContentValidate = styled.div`
    display: grid;
    margin: auto;
    width: fit-content;
`

export const StyledListValidate = styled.div`
    padding: 3px 10px;
    color: #636363;
    font-family: Red Hat Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

`

export const StepValidate = styled.div`
    width: 60px;
    height: 9px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #D9D9D9;
    margin: 25px 10px 7px 10px;
    &.step-validate {
      /* Estilos adicionais, se necessário */
    }
`