import React, { useEffect, useState } from "react";
import { Box, Card, Skeleton, Typography } from "@mui/material";
import { isEmpty, toString } from "lodash";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import star from "../../../assets/img/star.png";
import star2x from "../../../assets/img/star2x.png";
import star3x from "../../../assets/img/star3x.png";
import disabledStar from "../../../assets/img/disabled-star.png";
import disabledStar2x from "../../../assets/img/disabled-star2x.png";
import disabledStar3x from "../../../assets/img/disabled-star3x.png";

import { Title } from "./components/Title";
import { Button, TitleCoupon, Text } from "./styles";
import { map } from "lodash";
import { CustomTooltip } from "../Parcelas.styled";
import { ModalSeeCoupon } from "./components/ModalSeeCoupon";
import { ModalAlert } from "./components/ModalAlert";
import { userStore } from "../../../store/user/user";
import { useHubJequiti } from "../../../store/hubjequiti";

import { useCoupons } from "./useCoupons";

import ErroSemResultados from "../../ErroSemResultados";

import { useHandleParcela } from "../../../context/ContextParcelas/userContextParcelas";
import { useIsMobile } from "../../../utils/utils";
import { CustomPagination } from "../../pagination";

export const MyTickets: React.FC = () => {
  const [openModalSeeCoupon, setOpenSeeModalCoupon] = useState(false);
  const { user } = userStore();
  const { token } = useHubJequiti();

  const userToken = toString(token);

  const isMobile = useIsMobile();
  const { setMenuSidebar } = useHandleParcela();

  const [page, setPage] = useState(1);
  const [openModalAlert, setOpenModalAlert] = useState(true);
  const { data, isLoading } = useCoupons(
    user.userFiscalPerson,
    page,
    userToken ? userToken : user.token
  );

  const [couponId, setCouponId] = useState("");
  const mockLoading = Array(5);

  console.log("token", user);

  return (
    <div>
      <div className="flex items-center gap-2">
        {isMobile && (
          <ArrowBackIosIcon
            onClick={() => setMenuSidebar(false)}
            sx={{ fontSize: "32px" }}
          />
        )}
        <Typography variant="h1">Meus cupons</Typography>
      </div>
      <Typography variant="subtitle1" color="#000000">
        Concorra a prêmios TODOS OS DIAS no programa Cupom Premiado no SBT
      </Typography>
      <div className="mt-3 h-[49rem] md:h-[32rem]">
        {!isLoading &&
          map(data?.dados, (item, index) => (
            <Card
              className="p-3 mt-4 d-flex flex-column flex-md-row align-items-center justify-content-between"
              key={index}
            >
              <div className="d-flex w-100 justify-content-between align-items-center">
                {Number(item?.status) === 1 ? (
                  <img
                    src={star}
                    srcSet={`${star3x} 3x, ${star2x} 2x, ${star} 1x`}
                    alt="Capa Carnê Digital"
                    style={{ maxWidth: "100%", height: "100%" }}
                  />
                ) : (
                  <img
                    src={disabledStar}
                    srcSet={`${disabledStar3x} 3x, ${disabledStar2x} 2x, ${disabledStar} 1x`}
                    alt="Capa Carnê Digital"
                    style={{ width: "4rem", marginLeft: "0.4rem" }}
                  />
                )}
                <TitleCoupon isDisabled={false}>
                  CUPOM <br /> BAÚ
                </TitleCoupon>
                <div className="d-flex flex-column">
                  <Title
                    text="Tipo de Carnê"
                    isDisabled={Number(item?.status) === 1}
                  />
                  <Title
                    text={item?.carneDigital ? "Digital" : "Físico"}
                    isSubtitle
                    isDisabled={Number(item?.status) === 1}
                  />
                </div>
              </div>

              <div className="mt-3 d-flex w-100 justify-content-between mt-md-0 ms-0 ms-md-4 align-items-center">
                <div className="d-flex flex-column">
                  <Title text="Carnê" isDisabled={Number(item?.status) === 1} />
                  <Title
                    text={item?.numero}
                    isSubtitle
                    isDisabled={Number(item?.status) === 1}
                  />
                </div>
                <div className="d-flex flex-column">
                  <Title
                    text="Parcela"
                    isDisabled={Number(item?.status) === 1}
                  />
                  <Title
                    text={item?.parcela}
                    isSubtitle
                    isDisabled={Number(item?.status) === 1}
                  />
                </div>
                <CustomTooltip
                  title={
                    <>
                      <Typography
                        style={{
                          display: "flex",
                          textAlign: "center",
                          margin: "auto",
                          justifyContent: "center",
                          paddingBottom: 10,
                          color: "#000",
                          fontWeight: 700,
                        }}
                        fontSize={16}
                      >
                        {Number(item?.status) === 1
                          ? "Cupom indisponível"
                          : "Cupom nas urnas"}
                      </Typography>
                      <p
                        style={{
                          fontSize: 14,
                          alignItems: "center",
                        }}
                      >
                        {Number(item?.status) === 1
                          ? "A imagem do cupom é desativada 20 dias após sua emissão, mas não se preocupe, seus cupons já estão, nas urnas do SBT."
                          : "Seu cupom já foi gerado e você poderá visualiza-lo em breve"}
                      </p>
                    </>
                  }
                  disableHoverListener={Number(item?.status) === 0}
                >
                  {Number(item?.status) === 1 ? (
                    <a
                      href={`${process.env.REACT_APP_API_HUB_JEQUITI}?token=${item?.token}`}
                      target="_blank"
                      className="cursor-pointer text-decoration-none"
                      rel="noreferrer"
                    >
                      <Text>
                        cupom <br />
                        nas urnas
                      </Text>
                    </a>
                  ) : (
                    <Button
                      isDisabled={Number(item?.status) === 1}
                      disabled={Number(item?.status) === 1}
                      onClick={() => {
                        setOpenSeeModalCoupon(true);
                        setCouponId(toString(item?.id));
                      }}
                    >
                      {Number(item?.status) === 1 ? (
                        <p>
                          cupom <br />
                          Impresso
                        </p>
                      ) : (
                        <p className="m-0">ver cupom</p>
                      )}
                    </Button>
                  )}
                </CustomTooltip>
              </div>
            </Card>
          ))}
        {!isLoading && !data?.dados.length && (
          <div className="flex items-center justify-center">
            <ErroSemResultados />
          </div>
        )}

        {isLoading &&
          map(mockLoading, (_, index) => (
            <Skeleton variant="rounded" key={index} className="mt-4">
              <Box
                width={{ xs: "20rem", md: "50rem" }}
                height={{ xs: "14rem", md: "6.3rem" }}
              ></Box>
            </Skeleton>
          ))}
      </div>
      <Box className="flex justify-center mt-4">
        <CustomPagination
          numberOfPages={data?.totalPaginas || 0}
          page={page}
          setPage={setPage}
        />
      </Box>

      {openModalSeeCoupon && (
        <ModalSeeCoupon
          open={openModalSeeCoupon}
          setOpen={setOpenSeeModalCoupon}
          couponId={couponId}
        />
      )}
      {openModalAlert && (
        <ModalAlert open={openModalAlert} setOpen={setOpenModalAlert} />
      )}
    </div>
  );
};
