import { FC } from 'react';
import { Typography, Grid, Card } from '@mui/material';
import { styles } from '../../styles';
import { useNavigate } from 'react-router-dom';

import ContainerBox from '../../components/ContainerBox';

type Props = {
  arrays: any[];
};

const CardList: FC<Props> = ({ arrays }) => {
  const navigate = useNavigate();

  const handleClick = (value: string) => {
    const id = value;
    navigate('/regulamento', { state: { id } });
  };

  const renderCard = (items: any, index: number) => {
    const isEvenIndex = index % 2 === 0;

    return (
      <div
        style={{
          minHeight: 508,
          paddingBottom: '92px',
          backgroundColor: isEvenIndex ? '#E6E9ED' : '#FFFFFF',
        }}
        key={index}
      >
        <ContainerBox>
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            {isEvenIndex ? (
              <>
                <Grid container sx={{ display: 'flex', justifyContent: 'center' }} lg={6} md={6} sm={6} xs={6}>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
                    <Typography sx={styles.titleCard}>{items.title}</Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      color="#000000"
                      sx={{
                        textAlign: 'justify',
                        margin: '32px 0 92px 0',
                        a: {
                          textDecorationLine: 'underline',
                          color: '#000000',
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: items.description }}
                    />
                  </Grid>
                  {items.link && (
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Typography
                        variant="subtitle1"
                        component="button"
                        color="#F08C10"
                        onClick={() => handleClick(items.id)}
                        sx={{
                          fontWeight: 700,
                          textDecoration: 'underline',
                          border: 'none',
                          background: 'rgb(230, 233, 237)',
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#F08C10',
                          },
                        }}
                      >
                        {items.link}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Card
                    sx={{
                      width: 552,
                      margin: '92px 0',
                      boxShadow: 'none',
                      background: 'none !important',
                    }}
                  >
                    <img 
                      src={items.image}
                      alt={items.label}
                      style={{
                        height: "326"
                      }}
                    />
                  </Card>
                </Grid>
              </>
            ) : (
              <>                
                <Grid item lg={6} md={6} sm={6} xs={6} sx={{ display: 'flex', justifyContent: 'left' }}>
                  <Card
                    sx={{
                      width: 552,
                      margin: '92px 0',
                      boxShadow: 'none',
                      background: 'none !important',
                    }}
                  >
                    <img 
                      src={items.image}
                      alt={items.label}
                      style={{
                        height: "326"
                      }}
                    />
                  </Card>
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'center' }} lg={6} md={6} sm={6} xs={6}>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
                    <Typography sx={styles.titleCard}>{items.title}</Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      color="#000000"
                      sx={{
                        textAlign: 'justify',
                        margin: '32px 0 92px 0',
                        a: {
                          textDecorationLine: 'underline',
                          color: '#000000',
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: items.description }}
                    />
                  </Grid>
                  {items.link && (
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Typography
                        variant="subtitle1"
                        component="button"
                        color="#F08C10"
                        onClick={() => handleClick(items.id)}
                        sx={{
                          fontWeight: 700,
                          border: 'none',
                          textDecoration: 'underline',
                          background: 'rgb(230, 233, 237)',
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#F08C10',
                          },
                        }}
                      >
                        {items.link}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </ContainerBox>
      </div>
    );
  };

  return <>{arrays.map(renderCard)}</>;
};

export default CardList;
