import { Typography, Grid } from "@mui/material";
import ContainerBox from "../ContainerBox";
import { useIsMobile } from "../../utils/utils";

const Error404 = () => {
  const isMobile = useIsMobile();

  return (
    <ContainerBox>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: `${isMobile ? "81px 0" : "140px 0"}`,
        }}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "50px 15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              component="p"
              color="#1C1C7B"
              sx={{
                margin: { xs: "0", sm: "0" },
                fontWeight: 400,
                textAlign: "justify",
              }}
            >
              <strong>ERRO 404!</strong> A página que você procura não foi
              encontrada
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              textAlign: "justify",
              margin: 0,
            }}
          >
            <Typography variant="subtitle1" component="p" color="#000000">
              <span>
                não se preocupe! separamos aqui alguns assuntos que podem te
                interessar&nbsp;
                <span>
                  :)&nbsp;
                  <br />
                  <a
                    href="/"
                    style={{
                      color: "#F08C10",
                      fontWeight: 700,
                    }}
                  >
                    clique aqui
                  </a>
                </span>
              </span>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default Error404;
