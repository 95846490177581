import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  validateName,
  phoneMask,
  validateEmail,
  formatLocaleDate,
} from "../../utils/utils";
import ContainerBox from "../../components/ContainerBox";
import { useIsMobile } from "../../utils/utils";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { Alert, Snackbar } from "@mui/material";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";
import { CalendarContainer } from "../../pages/RecuperarSenha/RecuperarSenha.styled";
import DatePicker, { registerLocale } from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";

registerLocale("ptBR", ptBR);

const MeusDados = () => {
  const isMobile = useIsMobile();
  const auth = useAuth();
  const [fullName, setFullName] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [prevFullName, setPrevFullName] = useState("");
  const [prevPhone, setPrevPhone] = useState("");
  const [prevEmail, setPrevEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorDate, setErroDate] = useState(false);
  const [errorPhone, setErroPhone] = useState(false);
  const [errorEmail, setErroEmail] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [getItems, setGetItems] = React.useState<null | any>(null);
  const [putData, setPutData] = React.useState<null | any>(null);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [token, setToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { setMenuSidebar } = useHandleParcela();

  const formatLocaleDate = (date: Date): string => {
    return format(date, "dd/MM/yyyy", { locale: ptBR });
  };

  const formatApiDate = (dateStr: string): string => {
    const [year, month, day] = dateStr.split("T")[0].split("-");
    return `${day}/${month}/${year}`;
  };

  const validateBirthDate = (dateStr: string): boolean => {
    if (!dateStr) {
      return false; // Retorna falso se a data for nula ou vazia
    }

    const [day, month, year] = dateStr.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const minDate = new Date(1920, 0, 1);
    const adultDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    return birthDate >= minDate && birthDate <= adultDate;
  };

  const isBirthDateValid = validateBirthDate(birthDate);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      handleDescript(token);
      setToken(token);
    }
  }, [putData]);

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(item);
        handleData(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleData = async (token: any) => {
    const login = token.login;

    try {
      if (auth) {
        const request = await auth?.searchClient(login);
        handleArray(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleArray = (request: any) => {
    setGetItems(request);
    setFullName(request.nome + " " + request.sobrenome);
    setPrevFullName(request.nome + " " + request.sobrenome);
    setBirthDate(formatApiDate(request.nascimento));

    if (request?.documentos && Array.isArray(request.documentos)) {
      const documentoTipo1 = request.documentos.find(
        (documento: any) => documento.tipo === 1
      );
      if (documentoTipo1) {
        const valorDocumento = documentoTipo1.valor;
        setCpf(valorDocumento);
      }
    }

    if (request?.contatos && Array.isArray(request.contatos)) {
      const contatoTipo1 = request.contatos.find(
        (contato: any) => contato.tipo === 1
      );
      const contatoTipo2 = request.contatos.find(
        (contato: any) => contato.tipo === 2
      );
      if (contatoTipo1) {
        const valorContato = contatoTipo1.valor;
        setEmail(valorContato);
        setPrevEmail(valorContato);
      }

      if (contatoTipo2) {
        const valorContato2 = contatoTipo2.valor;
        setPhone(valorContato2);
        setPrevPhone(valorContato2);
      }
    }
  };

  const handleInputName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[0-9]/g, "");
    setFullName(value.slice(0, 50));
    setIsError(!validateName(event.target.value));

    if (value === prevFullName) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  };

  const isValidDate = (
    dateStr: string
  ): { isValid: boolean; message: string } => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Atualizado para DD/MM/YYYY
    if (!regex.test(dateStr)) {
      return {
        isValid: false,
        message: "Informe uma data de nascimento válida.",
      };
    }

    const [day, month, year] = dateStr.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const minDate = new Date(1920, 0, 1);
    const maxDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (birthDate < minDate || birthDate > maxDate) {
      return {
        isValid: false,
        message: "Informe uma data de nascimento válida.",
      };
    }
    return { isValid: true, message: "" };
  };

  const minDate = new Date(1920, 0, 1);

  const toggleCalendarVisibility = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleInputDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 2) value = value.slice(0, 2) + "/" + value.slice(2);
    if (value.length > 5) value = value.slice(0, 5) + "/" + value.slice(5, 9);
    setBirthDate(value);

    if (value.length === 10) {
      const validationResult = isValidDate(value);
      setErroDate(!validationResult.isValid);
      setErrorMessage(validationResult.message);
      setIsModified(true);
    }
  };

  const handleDateSelect = (date: Date | null) => {
    if (date) {
      const formattedDate = formatLocaleDate(date);
      setBirthDate(formattedDate);

      const validationResult = isValidDate(formattedDate);
      setErroDate(!validationResult.isValid);
      setErrorMessage(validationResult.message);

      setIsModified(formattedDate !== birthDate);
    }
  };

  const isBirthDateDisabled =
    getItems && getItems.nascimento
      ? validateBirthDate(formatApiDate(getItems.nascimento))
      : true;

  const handleInputPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\d()-]/g, "");
    const unformattedValue = value.replace(/[^0-9]/g, "");
    setPhone(phoneMask(value));
    setErroPhone(value.length !== 14 || value === "");

    if (unformattedValue === prevPhone) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  };

  const handleInputEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    setErroEmail(!validateEmail(value));

    if (value === prevEmail) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();

    const [firstName, ...rest] = fullName.split(" ");
    const secondName = rest.join(" ");
    setFirstName(firstName);
    setSecondName(secondName);

    const data = {
      id: getItems.id,
      idPreCupom: getItems.idPreCupom,
      nome: firstName,
      sobrenome: secondName,
      nascimento: birthDate,
      sexo: getItems.sexo,
      idUsuarioInclusao: getItems.idUsuarioInclusao,
      idUsuarioConectado: getItems.idUsuarioConectado,
      dataInclusao: getItems.dataInclusao,
      idUsuarioAtualizacao: getItems.idUsuarioAtualizacao,
      dataAtualizacao: getItems.dataAtualizacao,
      sistemaOrigem: getItems.sistemaOrigem,
      contatos: [
        {
          tipo: 1,
          valor: email,
        },
        {
          tipo: 2,
          valor: phone.replace(/\D/g, ""),
        },
      ],
      documentos: [
        {
          tipo: 1,
          valor: cpf.replace(/\D/g, ""),
        },
      ],
      enderecos: getItems.enderecos,
    };

    try {
      if (auth) {
        const putData = await auth?.putClient(data, token);
        setLoading(false);
        setPutData(putData);
        setIsModified(false);
        setAlertMessage("Dados atualizado com sucesso!");
        setAlertStatus("success");
        setAlertOpen(true);
      }
    } catch (error: any) {
      setLoading(false);
      setAlertMessage(`${error}`);
      setAlertStatus("error");
      setAlertOpen(true);
      throw error;
    }
  };

  const isDisabled = isError || errorDate || errorPhone || errorEmail;

  return (
    <>
      <ContainerBox>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: `${isMobile ? "322px" : "454px"}`,
              }}
            >
              <Typography variant="h1">
                {isMobile && (
                  <ArrowBackIosIcon
                    onClick={() => setMenuSidebar(false)}
                    sx={{ fontSize: "32px" }}
                  />
                )}
                Meus Dados
              </Typography>
              <Typography
                variant="subtitle1"
                color="#000000"
                sx={{
                  width: "100%",
                  margin: `${isMobile ? "0 15px" : null}`,
                }}
              >
                Edite os campos abaixo e clique em "Atualizar Dados" para salvar
                as alterações.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </ContainerBox>
      <ContainerBox>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: `${isMobile ? "center" : null}`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: `${isMobile ? "322px" : "454px"}`,
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  Nome Completo*
                </Typography>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  type="text"
                  name="Nome Completo"
                  value={fullName}
                  onChange={handleInputName}
                  placeholder="Nome Sobrenome"
                  required
                  error={isError}
                  helperText={
                    isError ? "Nome e sobrenome são obrigatórios" : ""
                  }
                  InputProps={{
                    endAdornment: isError ? null : (
                      <CheckOutlinedIcon
                        sx={{ color: "orange", marginRight: "8px" }}
                      />
                    ),
                    style: {
                      height: "48px",
                    },
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  CPF
                </Typography>
                <TextField
                  disabled
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="Cpf"
                  value={cpf
                    .replace(/[^\d.-]/g, "")
                    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
                    .slice(0, 14)}
                  placeholder="000.000.000-00"
                  required
                  InputProps={{
                    endAdornment: (
                      <CheckOutlinedIcon
                        sx={{ color: "orange", marginRight: "8px" }}
                      />
                    ),
                    style: {
                      height: "48px",
                    },
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  data de nascimento*
                </Typography>

                <TextField
                  disabled={isBirthDateValid}
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="birthDate"
                  value={birthDate}
                  placeholder="DD/MM/AAAA"
                  required
                  onChange={handleInputDate}
                  error={errorDate}
                  helperText={errorMessage}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <IconButton
                            onClick={toggleCalendarVisibility}
                            aria-label="toggle date picker"
                            disabled={isBirthDateValid}
                          >
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                        {errorDate ? null : (
                          <CheckOutlinedIcon
                            sx={{ color: "orange", marginRight: "8px" }}
                          />
                        )}
                      </>
                    ),
                    style: {
                      height: "48px",
                    },
                  }}
                  inputProps={{
                    pattern: "\\d{2}/\\d{2}/\\d{4}",
                    readOnly: false,
                    max: new Date().toISOString().split("T")[0],
                    maxLength: 10,
                  }}
                  fullWidth
                />
                {isOpen && isBirthDateValid && (
                  <CalendarContainer>
                    <DatePicker
                      locale="ptBR"
                      selected={
                        birthDate
                          ? new Date(birthDate.split("/").reverse().join("-"))
                          : null
                      }
                      onChange={handleDateSelect}
                      inline
                      calendarClassName="custom-calendar"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={new Date(1920, 0, 1)}
                    />
                  </CalendarContainer>
                )}

                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  Celular*
                </Typography>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  type="tel"
                  name="Celular"
                  value={phoneMask(phone)}
                  onChange={handleInputPhone}
                  placeholder="(00) 00000-0000"
                  required
                  error={errorPhone}
                  helperText={errorPhone ? "Telefone inválido" : ""}
                  InputProps={{
                    endAdornment: errorPhone ? null : (
                      <CheckOutlinedIcon
                        sx={{ color: "orange", marginRight: "8px" }}
                      />
                    ),
                    style: {
                      height: "48px",
                    },
                  }}
                  inputProps={{
                    maxlength: 15,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textTransform: "uppercase",
                    marginTop: "20px",
                    fontSize: "10px",
                    fontWeight: 700,
                  }}
                >
                  E-MAIL*
                </Typography>
                <TextField
                  sx={{
                    boxSizing: "border-box",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleInputEmail}
                  placeholder="exemplo@dominio.com"
                  required
                  error={errorEmail}
                  helperText={errorEmail ? "E-mail inválido" : ""}
                  InputProps={{
                    endAdornment: errorEmail ? null : (
                      <CheckOutlinedIcon
                        sx={{ color: "orange", marginRight: "8px" }}
                      />
                    ),
                    style: {
                      height: "48px",
                    },
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <Button
                  type="submit"
                  disabled={!isModified || isDisabled || loading}
                  sx={{
                    width: "100%",
                    height: "48px",
                    margin: "30px 0",
                    fontSize: "14px",
                    color: "#FFFF",
                    background: `${
                      !isModified || isDisabled ? "#D1D1D1" : "#F08C10"
                    }`,
                    "&: hover": {
                      background: `${
                        !isModified || isDisabled ? "#D1D1D1" : "#F08C10"
                      }`,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                  ) : (
                    "Atualizar"
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </ContainerBox>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertStatus || "info"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MeusDados;
