import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px 0px;
    height: 139px;
    color: #231F20;
    font-family: Red Hat Display;
    font-style: normal;
    line-height: normal;
    h6 {
        font-size: 16px;
        font-weight: 700;
        color: #575756;
    }
    .body1 {

        font-weight: 400;
        font-size: 14px;
    }
    .menu-item {
        width: 80px;
        &:hover {
            background-color: "#F08C10" !important;
        }
    }
`
