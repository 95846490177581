import React, {FC, useEffect, useState, useContext} from 'react'
import {
Typography,
Button,
CircularProgress
} from '@mui/material'
import { CartContext } from '../../context/CartProvider';
import { useNavigate } from "react-router-dom";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";

type Props = {
  handle: any;
  context: number;
  loading: boolean;
  value: string;
}

const CardButton: FC<Props> = ({ handle, context, loading, value }) => {
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();
  const [tokenId, setTokenId] = React.useState('');
  const navigate = useNavigate();
  const backOffice = useBackOffice();
  const { disableButton } = useContext(CartContext)!;

  useEffect(() => {
    const data = localStorage.getItem('token');
    if (data) {
      setTokenId(data)
    }
  }, []);

  const handleNavigate = () => {
    backOffice?.isBackOfficeLogged()
      ? navigate("/autenticar/minha-conta")
      : navigate("/minha-conta")      
      handleSideBar("Pagar Parcelas");
      toggleSubMenu(true);
  }
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Button
        disabled={(context === 1 && value === '0') || (value === '3' && disableButton) ? true : false}
        onClick={() => handle(context)}
        sx={{
          width: '300px',
          heigth: '40px',
          color: '#FFFFFF',
          fontSize: '14px',
          background: `${(context === 1 && value === '0') || (value === '3' && disableButton) ? '#C4C4C4' : '#F08C10'}`,
          fontWeight: 700,                  
            '&:hover': {
              background: `${(context === 1 && value === '0') || (value === '3' && disableButton) ? '#C4C4C4' : '#F08C10'}`,
            },
        }}
      >
        {loading ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : 'REALIZAR PAGAMENTO'} 
      </Button>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '19px 0'
        }}
      >            
        <Typography
          color='#1C1C7B'
          component='a'
          onClick={() => handleNavigate()}
          sx={{                
            cursor:'pointer',
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '16px',
            textDecoration: 'none',
            '&:hover': {
              color: '#1C1C7B',
            },
          }}
        >
          {'< Voltar para Meus Carnês'}
        </Typography>
      </div>
    </div>
  )
}

export default CardButton
