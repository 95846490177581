import React, { useEffect, useState, useRef } from "react";
import ContainerBox from "../../components/ContainerBox";
import TabContainer from "../../components/TabContainer";
import { politicas } from "../../utils/politicas";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../utils/utils";

const PoliticasLegais = () => {
  const [identify, setIdentify] = useState("0");
  const location = useLocation();

  useEffect(() => {
    const id = location.state?.id || identify;
    setIdentify(id);
  }, [location.state]);

  const isMobile = useIsMobile();
  const topRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  return (
    <div style={{ margin: `${isMobile ? "50px 0" : "172px 0"}` }} ref={topRef}>
      <ContainerBox>
        <Typography variant="h1">Políticas Legais</Typography>
        <Typography
          variant="subtitle1"
          color="#000000"
          sx={{
            margin: "28px 0 30px 0",
            "@media (max-width: 1199px)": {
              margin: "28px 0 3px 16px",
            },
          }}
        >
          Confira os regulamentos dos programas participantes e do Baú da
          Felicidade.
        </Typography>
        <TabContainer arrays={politicas} identify={identify} />
      </ContainerBox>
    </div>
  );
};

export default PoliticasLegais;
