import { ArrowForwardIosOutlined, AssignmentOutlined, FileCopyOutlined, LocationOnOutlined } from '@mui/icons-material';
import { ViewInArOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components'

interface ContainerProps {
    myItemName?: { id: number; name: string }[];
    activeMenuItem?: string;
}

interface StyledIconProps {
    active: boolean;
}

export const StyledIcon = styled.img<StyledIconProps>`
    padding: 0px 15px 10px 0px;
    width: auto;
    path {
    fill:  '#F08C10' ;
  }
`


export const Container = styled.div<ContainerProps>`
    .collapse-carnes {
        margin-left: 35px;
        color: #1C1C7B;
        font-family: Red Hat Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
    .menu-title,
    .menu-img,
    .menu-arrow {
        color: #1C1C7B;
    }

    .menu-title[data-active='true'],
    .menu-img[data-active='true'],
    .menu-arrow[data-active='true'] {
        color: #F08C10;
    }
`;

export const StyledArrow = styled(ArrowForwardIosOutlined) <{ active: boolean }>`
  margin: 0 10px;
  color: ${(props) => (props.active ? "#F08C10" : "#1C1C7B")};
`;

export const StyledViewInArOutlined = styled(ViewInArOutlined) <{ active: boolean }>`
    margin-right: 10px;
    color: ${(props) => (props.active ? "#F08C10" : "#1C1C7B")};
`;

export const StyledLocationOnOutlined = styled(LocationOnOutlined) <{ active: boolean }>`
    margin-right: 10px;
    color: ${(props) => (props.active ? "#F08C10" : "#1C1C7B")};
`;

export const StyledAssignmentOutlined = styled(AssignmentOutlined) <{ active: boolean }>`
    margin-right: 10px;
    color: ${(props) => (props.active ? "#F08C10" : "#1C1C7B")};
`;

export const StyledFileCopyOutlined = styled(FileCopyOutlined) <{ active: boolean }>`
    margin-right: 10px;
    color: ${(props) => (props.active ? "#F08C10" : "#1C1C7B")};
`;

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon) <{ active: boolean }>`
    margin-right: 10px;
    color: ${(props) => (props.active ? "#F08C10" : "#1C1C7B")};
`;


