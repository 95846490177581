import { Carousel } from "react-bootstrap";
import { useIsMobile } from "../../utils/utils";
import { CarouselArrows } from "../../components/CarouselArrows/CarouselArrows";
import ContainerBox from "../ContainerBox";
import { timeCarousel } from "../../utils/data";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useNavigate } from "react-router-dom";
import { MenuList } from "@mui/material";
import Img01 from "../../assets/imgCarounselHome/img01.png";
import Img02 from "../../assets/imgCarounselHome/img02.png";
import Img03 from "../../assets/imgCarounselHome/img03.png";
import Img04 from "../../assets/imgCarounselHome/img04.png";
import Img05 from "../../assets/imgCarounselHome/img05.png";
import ImgMobile01 from "../../assets/imgCarounselHome/imgMobile01.png";
import ImgMobile02 from "../../assets/imgCarounselHome/imgMobile02.png";
import ImgMobile03 from "../../assets/imgCarounselHome/imgMobile03.png";
import ImgMobile04 from "../../assets/imgCarounselHome/imgMobile04.png";
import ImgMobile05 from "../../assets/imgCarounselHome/imgMobile05.png";

const CarouselItem = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();

  const handleClick = (nameMenuItem: string, url: string | (() => void)) => {
    if (nameMenuItem === "Comprar Carnê") {
      window.location.href = url as string;
    } else {
      navigate("/minha-conta");
      toggleSubMenu(true);
      handleSideBar(nameMenuItem);
    }
  };

  const baseImageName = isMobile ? "mobile-banner" : "banner";

  const carousel = [
    {
      imgMobile: ImgMobile01,
      img: Img01,
      label: "Comprar Carnê",
      url: "https://www.youtube.com/c/Ba%C3%BAdaFelicidadeJequiti",
    },
    {
      imgMobile: ImgMobile02,
      img: Img02,
      label: "Comprar Carnê",
      url: "https://lojabau.jequiti.com.br/carne-do-bau-da-felicidade-jequiti-2024-digital.html",
    },
    {
      imgMobile: ImgMobile03,
      img: Img03,
      label: "Resgatar Produtos",
      url: () => {
        handleSideBar("Resgatar Produtos");
      },
    },
    {
      imgMobile: ImgMobile04,
      img: Img04,
      label: "Pagar Parcelas",
      url: () => {
        handleSideBar("Pagar Parcelas");
      },
    },
    {
      imgMobile: ImgMobile05,
      img: Img05,
      label: "Pagar Parcelas",
      url: () => {
        handleSideBar("Pagar Parcelas");
      },
    },
  ];

  return (
    <ContainerBox
      sx={{
        marginTop: "10px",
        // width: "1129px",
      }}
    >
      <CarouselArrows variant="dark" indicators>
        {carousel.map((rotate: any, index: any) => (
          <Carousel.Item
            interval={timeCarousel}
            key={index}
            style={{ cursor: "pointer" }}
          >
            {rotate.url ? (
              <>
                <MenuList onClick={() => handleClick(rotate.label, rotate.url)}>
                  <img
                    className="hidden md:block"
                    src={rotate.img}
                    alt={rotate.label}
                  />
                  <img
                    className="block md:hidden"
                    src={rotate.imgMobile}
                    alt={rotate.label}
                  />
                </MenuList>
              </>
            ) : (
              <img
                className="d-block w-100"
                style={{
                  width: "100%",
                  height: "334px",
                  objectFit: "cover",
                  padding: "0px 20px 0px 20px",
                }}
                src={rotate.img}
                alt={rotate.label}
              />
            )}
          </Carousel.Item>
        ))}
      </CarouselArrows>
    </ContainerBox>
  );
};

export default CarouselItem;
