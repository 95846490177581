import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IAuthProvider, IContext, IUser } from "./types";
import {
  LoginRequest,
  RegisterRequest,
  SearchCep,
  RegisterCarne,
  ListCarne,
  Descript,
  ListResgateCarne,
  ListParcela,
  UrlResgate,
  PaymentInfo,
  SetRecorrencia,
  DeleteRecorrencia,
  BuscaCliente,
  SendSMS,
  ValidaPin,
  ValidaEmail,
  ValidaCliente,
  AtualizaCliente,
  ListPedidos,
  SendEmail,
  Encrypt,
  ListCarnePagamento,
} from "./util";
import { api } from "../../services/api";

export const AuthContext = createContext<IContext | undefined>(undefined);

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  const [teste, setTeste] = useState<IUser | null>(null);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const recoveredUser = localStorage.getItem("userId");
    const idParam = id ?? "";
    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
    }

    setLoading(false);
  }, []);

  const login = async (cpf: string, senha: string) => {
    const loginResponse = await LoginRequest(cpf.replace(/\D/g, ""), senha);

    try {
      const user = await descriptToken(loginResponse);
      const userLogin = user.login;
      const cleanBearerToken = loginResponse.replace(/^Bearer\s+/i, "");
      const token = cleanBearerToken;

      localStorage.setItem("token", token);

      const userKey = {
        id: user.id,
        nome: user.nome,
        carneQuitado: false,
      };

      localStorage.setItem("userId", JSON.stringify(userKey));
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setUser(user);
      navigate("/");
      checkCarneQuitado(userLogin)
        .then((isCarneQuitado) => {
          const updatedUserKey = { ...userKey, carneQuitado: isCarneQuitado };
          localStorage.setItem("userId", JSON.stringify(updatedUserKey));
        })
        .catch((error) => {
          console.error(
            "Erro ao verificar se o carne está quitado:",
            error.message
          );
        });
    } catch (error: any) {
      console.error("Erro durante o login:", error.message);
      throw error.message;
    }
  };

  const checkCarneQuitado = async (userLogin: string): Promise<boolean> => {
    try {
      const carneListResponse = await carneList(userLogin);
      return carneListResponse.some(
        (carne: any) => carne.carneQuitado === true
      );
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    const tokenRedirectMagento = localStorage.getItem("dataClient");

    var url = `${process.env.REACT_APP_API_BAU}/customer/account/logoff/token=${tokenRedirectMagento}`;

    // handleLogoffMagento(url);

    localStorage.removeItem("token");
    localStorage.removeItem("tokenId");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("token_system");
    localStorage.removeItem("dataClient");
    localStorage.removeItem("bannerUserClose");
    localStorage.removeItem("tokenBackOffice");
    localStorage.removeItem("backOfficeId");
    localStorage.removeItem("selectedItems");
    api.defaults.headers.Authorization = null;
    setUser(null);
    navigate("/");
  };

  const handleLogoffMagento = (item: string) => {
    window.open(`${item}`, "_blank");
  };

  const register = async (data: any) => {
    const response = await RegisterRequest(data);
    return response.sucesso;
  };

  const isAuthenticated = () => {
    const storedUser = localStorage.getItem("userId");
    return !!storedUser;
  };

  const isAuthLogged = () => {
    const storeBackoffice = localStorage.getItem("backofficeId");
    return !!storeBackoffice;
  };

  const searchAddress = async (cep: string) => {
    const response = await SearchCep(cep);
    return response;
  };

  const verifyPhone = async (data: any) => {
    const response = await SendSMS(data);
    return response;
  };

  const verifyEmail = async (email: string) => {
    const response = await ValidaEmail(email);
    return response;
  };

  const verifyPin = async (login: string, pin: string) => {
    try {
      const response = await ValidaPin(login, pin);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const verifyClient = async (cpf: string, dataNasc: string) => {
    try {
      const response = await ValidaCliente(cpf, dataNasc);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const carneRegister = async (data: any) => {
    try {
      const response = await RegisterCarne(data);
      return response.sucesso;
    } catch (error: any) {
      throw error.error;
    }
  };

  const carneList = async (data: any) => {
    try {
      const response = await ListCarne(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const carneListPagamento = async (data: any) => {
    try {
      const response = await ListCarnePagamento(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const carneResgate = async (data: any) => {
    try {
      const response = await ListResgateCarne(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const carneParcela = async (data: any) => {
    try {
      const response = await ListParcela(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const parcela2Via = async (data: any, token: any) => {
    try {
      const response = await PaymentInfo(data, token);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const resgateUrl = async (data: any) => {
    try {
      const response = await UrlResgate(data);
      return response.url;
    } catch (error: any) {
      throw error;
    }
  };

  const recorrencia = async (data: any, token: any) => {
    try {
      const response = await SetRecorrencia(data, token);
      return response.message;
    } catch (error: any) {
      throw error;
    }
  };

  const recorrenciaDelete = async (data: any, token: any) => {
    try {
      const response = await DeleteRecorrencia(data, token);
      return response.message;
    } catch (error: any) {
      throw error;
    }
  };

  const descriptToken = async (data: any) => {
    try {
      const response = await Descript(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const encryptToken = async (data: any) => {
    try {
      const response = await Encrypt(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const searchClient = async (data: any) => {
    try {
      const response = await BuscaCliente(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const putClient = async (data: any, token: string) => {
    try {
      const response = await AtualizaCliente(data, token);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const pedidos = async (data: any) => {
    try {
      const response = await ListPedidos(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const enviaEmail = async (cpf: any, dataNasc: any, email: any) => {
    try {
      const response = await SendEmail(cpf, dataNasc, email);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const contextValue: IContext = {
    user,
    loading,
    login,
    logout,
    register,
    searchAddress,
    isAuthenticated,
    carneRegister,
    carneParcela,
    carneList,
    carneResgate,
    resgateUrl,
    parcela2Via,
    recorrencia,
    recorrenciaDelete,
    descriptToken,
    searchClient,
    verifyPhone,
    verifyPin,
    verifyEmail,
    verifyClient,
    putClient,
    pedidos,
    enviaEmail,
    encryptToken,
    carneListPagamento,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
