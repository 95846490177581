import { Button, Grid, InputBase, InputLabel, Typography } from '@mui/material';
import React, {FC} from 'react'
import { useIsMobile } from '../../utils/utils'
import { alpha, styled } from '@mui/material/styles';


const Label = styled(InputLabel)(({ theme }) => ({
  fontFamily: 'Red Hat Display, sans-serif',
}));

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),    
  },
  '& .MuiTypography-root .MuiFormLabel-root .MuiInputLabel-root': {    
    fontFamily: 'Red Hat Display, sans-serif',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '2px solid #E5E5E5',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: 14,
    padding: '10px 12px',
    marginBottom: theme.spacing(2),
    heigth: '50px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: [
      'Red Hat Display, sans-serif',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

type Props = {
  boleto: string;
  handleClick: any;
  handleValue: any;
  copy: boolean;
}

const BoletoConfirmation: FC<Props> = ({ boleto, handleClick, handleValue, copy }) => {
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={3} sx={{ display: "flex", justifyContent: "center",}}>
      <Grid 
        item 
        lg={8} md={8} sm={12} xs={12} 
        sx={{ 
          display: "flex", 
          flexDirection: { xs: 'column', sm: 'row' }, 
          justifyContent: "center", 
          alignItems: "center"
        }}
      >
        <div style={{ flex: 1,}}>
          <Label sx={{fontWeight: 700, color: '#1C1C7B'}}>Dados do Boleto Bancário a ser pago</Label>
          <Input
            name='boleto'
            value={boleto}
            multiline
            onChange={handleValue}
            sx={{
              width: { xs: '340px', sm: '100%' },
              color: '#575756',
              fontSize: '14px'
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            onClick={handleClick}
            sx={{
              height: '40px',
              color: '#FFFFFF',
              width: { xs: '340px', sm: '147px' },
              fontSize: isMobile ? '12px' : '16px',
              '&:hover': {
                background: '#F08C10',
              }
            }}
          >
            {copy ? 'Copiado' : 'Copiar' }
          </Button>
        </div>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} 
        sx={{
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center"
          }}
        >
          <Button
            sx={{
              width: { xs: '340px', sm: '100%' },
              height: '40px',
              color: '#FFFFFF',
              fontSize: isMobile ? '12px' : '16px',
              '&: hover': {
                background: '#F08C10',
              }
            }}
          >
            ABRIR Boleto Bancário
          </Button>
      </Grid>
    </Grid>
  )
}

export default BoletoConfirmation

