import { Grid } from "@mui/material";
import SideBar from "../../components/SideBar";
import { MainContent } from "../../components/MainContent";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";


const MinhaContaMobile = () => {
const { menuSidebar } = useHandleParcela();

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      {menuSidebar ? (
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 360
            }}
          >
            <MainContent />
          </div>
        </Grid>
      ) : (
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "block",
            }}
          >
            <SideBar />
          </div>
        </Grid>
      )}
    </Grid>
  )
}

export default MinhaContaMobile