import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { regulamento } from "../../utils/regulamento";
import { useLocation } from "react-router-dom";
import ContainerBox from "../../components/ContainerBox";
import TabContainer from "../../components/TabContainer";
import { useIsMobile } from "../../utils/utils";

const Regulamento = () => {
  const [identify, setIdentify] = useState("0");
  const location = useLocation();

  useEffect(() => {
    const id = location.state?.id || identify;
    setIdentify(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const isMobile = useIsMobile();

  return (
    <div style={{ margin: `${isMobile ? "50px 0" : "155px 0"}` }}>
      <ContainerBox>
        <Typography variant="h1">Regulamento</Typography>
        <Typography
          variant="subtitle1"
          color="#000000"
          sx={{
            margin: "28px 0 50px 0px",
            "@media (max-width: 1199px)": {
              margin: "28px 0 50px 16px",
            },
          }}
        >
          Confira os regulamentos dos programas participantes e do Baú da
          Felicidade.
        </Typography>
        <TabContainer arrays={regulamento} identify={identify} />
      </ContainerBox>
    </div>
  );
};

export default Regulamento;
