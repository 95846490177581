import styled from 'styled-components';
import { Badge } from "@mui/material";

export const MenuUrl = styled.a`
  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
  font-family: 'Red Hat Display', sans-serif !important;
  text-decoration: none;
  text-transformation: uppercase;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  &:hover {
    color: #FFFFFF;
  }
`;

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    background: '#F08C10'
  },
});

export const StyledLink = styled.a`
    color: '#F08C10'
`