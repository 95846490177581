import { useEffect } from "react";

const Logoff = () => {
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenId");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("token_system");
    localStorage.removeItem("bannerUserClose");
    localStorage.removeItem("selectedItems");
    localStorage.removeItem("dataClient");
    window.open("/login", "_self");
  }, []);

  return null;
};

export default Logoff;
