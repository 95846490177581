import React, { useEffect } from "react";
import MeusDados from "./MeusDados";
import MeusEnderecos from "./MeusEnderecos";
import MeusPedidos from "./MeusPedidos";
import RegistrarCarne from "./RegistrarCarne";
import MsgBoasVindasPerfil from "../MsgBoasVindasPerfil";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import DataTableCarnes from "./DataTableCarnes";
import { MyTickets } from "./MyTickets";

const MainContent = () => {
  const { activeSection } = useHandleSideBar();

  const renderContent = () => {
    switch (activeSection) {
      case "Minha Conta":
        return <MsgBoasVindasPerfil />;
      case "Meus Pedidos":
        return <MeusPedidos />;
      case "Consultar Carnês":
        return <DataTableCarnes menu={"consulta"} />;
      case "Meus Endereços":
        return <MeusEnderecos />;
      case "Pagar Parcelas":
        return <DataTableCarnes menu={"pagar"} />;
      case "Cadastrar Carnê":
        return <RegistrarCarne />;
      case "Resgatar Produtos":
        return <DataTableCarnes menu={"resgate"} />;
      case "Meus Dados":
        return <MeusDados />;
      case "Meus Carnês":
        return <MsgBoasVindasPerfil />;
      case "Meus Cupons":
        return <MyTickets />;
      default:
        return <MsgBoasVindasPerfil />;
    }
  };

  return <div>{renderContent()}</div>;
};

export default MainContent;
