import styled from 'styled-components'
import { Skeleton } from "@mui/material";

export const StyledSkeletonTableHeader = styled(Skeleton)`
    width: 220px;
    height: 30px;
    padding: 25px;
    margin-left: 10px;
`
export const StyledSkeletonTableBody = styled(Skeleton)`
    width: 220px;
    height: 80px;
    padding: 10px;
    margin-left: 10px;
`