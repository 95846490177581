import { useMediaQuery } from "react-responsive";

export const useIsMobile = () => {
  return useMediaQuery({
    query: "(max-width: 1199px)",
  });
};

export const downloadBase64File = (
  contentType: any,
  base64Data: any,
  fileName: any
) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const validateCpf = (strCPF: string) => {
  let cpf = strCPF.replace(/\D/g, "");
  if (cpf === null) {
    return false;
  }
  if (cpf.length !== 11) {
    return false;
  }
  if (
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }
  let numero = 0;
  let caracter = "";
  let numeros = "0123456789";
  let j = 10;
  let somatorio = 0;
  let resto = 0;
  let digito1 = 0;
  let digito2 = 0;
  let cpfAux = "";
  cpfAux = cpf.substring(0, 9);
  for (let i = 0; i < 9; i++) {
    caracter = cpfAux.charAt(i);
    if (numeros.search(caracter) === -1) {
      return false;
    }
    numero = Number(caracter);
    somatorio = somatorio + numero * j;
    j--;
  }
  resto = somatorio % 11;
  digito1 = 11 - resto;
  if (digito1 > 9) {
    digito1 = 0;
  }
  j = 11;
  somatorio = 0;
  cpfAux = cpfAux + digito1;
  for (let i = 0; i < 10; i++) {
    caracter = cpfAux.charAt(i);
    numero = Number(caracter);
    somatorio = somatorio + numero * j;
    j--;
  }
  resto = somatorio % 11;
  digito2 = 11 - resto;
  if (digito2 > 9) {
    digito2 = 0;
  }
  cpfAux = cpfAux + digito2;
  if (cpf !== cpfAux) {
    return false;
  } else {
    return true;
  }
};

export const validateDate = (date: string) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(date)) {
    return false;
  }

  const currentDate = new Date();
  const selectedDate = new Date(date);

  const minDate = new Date("1900-01-01");

  return (
    selectedDate <= currentDate &&
    selectedDate >= minDate &&
    selectedDate.getFullYear() >= 1900 &&
    selectedDate.getFullYear() <= currentDate.getFullYear()
  );
};

export const validateName = (name: string) => {
  const nameParts = name.trim().split(" ");
  return nameParts.length >= 2;
};

export const validateCVV = (cvv: string): boolean => {
  const cvvDigits = cvv.replace(/\D/g, "");
  return /^[0-9]{3,4}$/.test(cvvDigits);
};

export const validateCreditCard = (cardNumber: any) => {
  cardNumber = cardNumber.replace(/[\s-]/g, "");
  if (!/^\d{13,16}$/.test(cardNumber)) {
    return false;
  }

  if (/^(\d)\1+$/.test(cardNumber)) {
    return false;
  }

  let sum = 0;
  let doubleUp = false;
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);
    if (doubleUp) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    doubleUp = !doubleUp;
  }

  return sum % 10 === 0;
};

export const isValidCreditCardDate = (inputDate: string): boolean => {
  const dateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;

  if (!dateRegex.test(inputDate)) {
    return false;
  }

  const [mes, ano] = inputDate.split("/").map(Number);
  const dataAtual = new Date();

  const anoAtual = dataAtual.getFullYear() % 100;

  return ano > anoAtual;
};

export const validateDateCreditCard = (inputDate: any) => {
  const dateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;

  if (!dateRegex.test(inputDate)) {
    return false;
  }

  const partes = inputDate.split("/");
  const mes = parseInt(partes[0], 10);
  const ano = parseInt(partes[1], 10);

  const dataAtual = new Date();
  const mesAtual = dataAtual.getMonth() + 1;
  const anoAtual = dataAtual.getFullYear() % 100;

  if (ano < 0 || ano > 99) {
    return false;
  }

  if (ano < anoAtual) {
    return false;
  }

  if (ano === anoAtual && mes < mesAtual) {
    return false;
  }

  return true;
};

export const phoneMask = (value: string) => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "($1) $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
};

export const cepMask = (cep: string) => {
  if (!cep) return "";
  cep = cep.replace(/\D/g, "");
  cep = cep.slice(0, 8);
  cep = cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
  return cep;
};

export const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}(?:\.[a-zA-Z0-9-]{2,})?$/;
  return regex.test(email);
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = getMonthName(date.getMonth());
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const getMonthName = (month: number) => {
  const monthNames = [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ];
  return monthNames[month];
};

export const formatCarneResgatado = (numeroPedido: string) => {
  const formattedNumeroPedido = numeroPedido.replace(
    /^(\d{3})(\d{6})(\d{1})$/,
    "$1 $2-$3"
  );
  return formattedNumeroPedido;
};

export const formatarValorEmReais = (value: number | undefined | null) => {
  if (typeof value !== "number") {
    console.warn("Valor fornecido não é um número válido:", value);
    return "R$ 0,00"; // valor padrão ou alguma string representando um valor padrão
  }

  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const formatCpf = (value: string) => {
  return value
    .replace(/[^\d.-]/g, "")
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    .slice(0, 14);
};

export const formatNumberCarne = (value: string) => {
  return value
    .replace(/[^\d.-]/g, "")
    .replace(/(\d{6})(\d{1})/, "$1-$2")
    .slice(0, 8);
};

export const price = 18;

export const time = 60;

export function formatUserData(data: string) {
  const partesData = data.split("-");
  if (partesData.length === 3) {
    const ano = partesData[0];
    const mes = partesData[1];
    const dia = partesData[2];
    return `${ano}-${mes}-${dia} 00:00:00`;
  } else {
    return null;
  }
}

export function formatDateToYYYYMMDD(inputDate: string): string {
  let dateObj: Date;

  // Verifica se a string está no formato DD/MM/AAAA
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputDate)) {
    const [day, month, year] = inputDate.split("/").map(Number);
    dateObj = new Date(year, month - 1, day); // Cria o objeto Date a partir de DD/MM/AAAA
  }
  // Verifica se a string está no formato ISO (AAAA-MM-DDTHH:mm:ss.sssZ ou AAAA-MM-DD)
  else if (/^\d{4}-\d{2}-\d{2}/.test(inputDate)) {
    dateObj = new Date(inputDate); // Cria o objeto Date a partir do formato ISO
  }
  // Se o formato não for reconhecido, retorna um erro
  else {
    console.error("Data no formato inválido:", inputDate);
    return "Invalid date format";
  }

  // Verifica se a data gerada é válida
  if (isNaN(dateObj.getTime())) {
    console.error("Data inválida criada:", dateObj);
    return "Invalid date";
  }

  // Formata a data corretamente para AAAA-MM-DD
  const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`;

  return formattedDate;
}

export function maskEmail(email: string) {
  if (!email || typeof email !== "string" || !email.includes("@")) {
    console.error("E-mail inválido");
    return null;
  }

  const [localPart, domain] = email.split("@");
  if (!localPart || !domain) {
    console.error("E-mail inválido");
    return null;
  }
  const maskedLocalPart =
    localPart.length > 2
      ? `${localPart[0]}${"*".repeat(localPart.length - 2)}${localPart.slice(
          -1
        )}`
      : localPart;
  const maskedDomain =
    domain.length > 2
      ? `${domain[0]}${"*".repeat(domain.length - 2)}${domain.slice(-1)}`
      : domain;

  return `${maskedLocalPart}@${maskedDomain}`;
}

export function validarSenha(senha: string) {
  if (senha.length < 8) {
    return false;
  }

  if (!/[A-Z]/.test(senha)) {
    return false;
  }

  if (!/\d/.test(senha)) {
    return false;
  }

  if (!/[!@#$%^&*(),.?":{}|<>]/.test(senha)) {
    return false;
  }

  return true;
}

export const formatPastedDate = (pastedValue: any, cursorPosition: any) => {
  const matchWithHyphen = pastedValue.match(/^(\d{2})-(\d{2})-(\d{4})$/);

  if (matchWithHyphen) {
    const [, day, month, year] = matchWithHyphen;
    return `${year}-${month}-${day}`;
  }

  const matchWithSlash = pastedValue.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);

  if (matchWithSlash) {
    const [, day, month, year] = matchWithSlash;
    return `${year}-${month}-${day}`;
  }

  const matchWithoutSeparator = pastedValue.match(/^(\d{2})(\d{2})(\d{4})$/);

  if (matchWithoutSeparator) {
    const [, day, month, year] = matchWithoutSeparator;
    return `${year}-${month}-${day}`;
  }

  return pastedValue;
};

export const formatLocaleDate = (date: Date) => {
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
};
