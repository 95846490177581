import styled from 'styled-components'
import Carousel from 'react-bootstrap/Carousel';

export const CarouselArrows = styled(Carousel)`

  .carousel-control-prev,
  .carousel-control-next {
    background-color: #EBEDF1;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    top: 100px;
    transform: translateY(-50%);
    opacity: 2;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 45%;
}

.carousel-indicators {
  top: 100%;
  margin: 12px;
  background-color: #EBEDF1;
  button {
    height: 6px;
    width: 49px;
    border-radius: 22px;
    border: 8px;
  }
  button[aria-current="true"] {
    background-color: #F08C10;
  }

  button[aria-current="false"] {
    background-color: #D9D9D9;
  }
}
`;