import React, { FC, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { filter } from "lodash";
import { useIsMobile } from "../../../utils/utils";
import { useHandleParcela } from "../../../context/ContextParcelas/userContextParcelas";

import { useHandleSideBar } from "../../../context/MenuSideBar/useHandleSideBar";
import { useStyles } from "./styles";

import { ModalRecurringPayment } from "./components/ModalRecurringPayment";
import { baseURL } from "../../../api/axios";
import { userStore } from "../../../store/user/user";
import { ICarne } from "../../../types/carne";
import { Toast } from "../../Toast";
import { ModalCancelRecurringPayment } from "./components/ModalCancelRecurringPayment";

import { Carnets } from "./components/Carnets";

type Props = {
  menu: string;
  setView: any;
  setId: any;
  setCode: any;
  onClickDetalhes: () => void;
  code: string;
};

const MeusCarnes: FC<Props> = ({
  menu,
  setView,
  setId,
  setCode,
  onClickDetalhes,
  code,
}) => {
  const navigate = useNavigate();

  const [dataId, setDataId] = useState("");

  const { handleSideBar, toggleSubMenu, activeSection } = useHandleSideBar();
  const { setMenuSidebar } = useHandleParcela();

  const [validate, setValidate] = useState(false);

  const tokenBko = localStorage.getItem("backOfficeId");

  const isMobile = useIsMobile();
  const classes = useStyles();
  const { user: userInformation } = userStore();
  const [isView, setIsView] = useState<number | null>(null);
  const [recurringPayment, setRecurringPayment] = useState(false);
  const [myTickets, setMyTickets] = useState<ICarne[]>([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [openModalCancelRecurring, setOpenModalCancelRecurring] =
    useState(false);

  useEffect(() => {
    if (tokenBko) setValidate(true);
  }, [tokenBko]);

  function handleParcelDetails(item: any) {
    setView(true);
    setCode(item?.codigoBarras);
  }

  const handleClick = () => {
    validate ? navigate("/autenticar/minha-conta") : navigate("/minha-conta");
    toggleSubMenu(true);
    handleSideBar("Cadastrar Carnê");
  };

  const getCarnes = async () => {
    try {
      setLoadingTickets(true);
      const response = await baseURL.get(
        `api/v01/carne/listar/${userInformation.userFiscalPerson}`
      );
      setMyTickets(response.data);
    } catch (error: any) {
      console.log("erro ao listar carnes", error);
    } finally {
      setLoadingTickets(false);
    }
  };

  const paidCarnet = useMemo(() => {
    return filter(myTickets, (item) => item.status === "Quitado");
  }, [myTickets]);

  const payInstallments = useMemo(() => {
    return filter(myTickets, (item) => item.pagarParcela === true);
  }, [myTickets]);

  function handleCarnets() {
    switch (activeSection) {
      case "Consultar Carnês":
        return (
          <Carnets
            paginatedItems={myTickets}
            loading={loadingTickets}
            setRecurringPayment={setRecurringPayment}
            recurringPayment={recurringPayment}
            setView={setView}
            setCode={setCode}
            setTicketNumber={setTicketNumber}
            setOpenModalCancelRecurring={setOpenModalCancelRecurring}
            handleParcelDetails={handleParcelDetails}
          />
        );
      case "Resgatar Produtos":
        return (
          <Carnets
            paginatedItems={paidCarnet}
            loading={loadingTickets}
            setRecurringPayment={setRecurringPayment}
            recurringPayment={recurringPayment}
            setView={setView}
            setCode={setCode}
            setTicketNumber={setTicketNumber}
            setOpenModalCancelRecurring={setOpenModalCancelRecurring}
            handleParcelDetails={handleParcelDetails}
          />
        );
      case "Pagar Parcelas":
        return (
          <Carnets
            paginatedItems={payInstallments}
            loading={loadingTickets}
            setRecurringPayment={setRecurringPayment}
            recurringPayment={recurringPayment}
            setView={setView}
            setCode={setCode}
            setTicketNumber={setTicketNumber}
            setOpenModalCancelRecurring={setOpenModalCancelRecurring}
            handleParcelDetails={handleParcelDetails}
          />
        );

      default:
        return (
          <Carnets
            paginatedItems={myTickets}
            loading={loadingTickets}
            setRecurringPayment={setRecurringPayment}
            recurringPayment={recurringPayment}
            setView={setView}
            setCode={setCode}
            setTicketNumber={setTicketNumber}
            setOpenModalCancelRecurring={setOpenModalCancelRecurring}
            handleParcelDetails={handleParcelDetails}
          />
        );
    }
  }

  function handleTitle() {
    switch (activeSection) {
      case "Consultar Carnês":
        return <h1 className={classes.titleH1}>Consultar Carnês</h1>;
      case "Resgatar Produtos":
        return <h1 className={classes.titleH1}>Resgatar Produtos</h1>;
      case "Pagar Parcelas":
        return <h1 className={classes.titleH1}>Pagar Parcelas</h1>;

      default:
        return <h1 className={classes.titleH1}>Consultar Carnês</h1>;
    }
  }

  useEffect(() => {
    getCarnes();
  }, []);

  return (
    <>
      <div className="flex items-center gap-2 mt-4">
        {isMobile && (
          <ArrowBackIosIcon
            onClick={() => setMenuSidebar(false)}
            sx={{ fontSize: "32px" }}
          />
        )}
        <h1 className={classes.titleH1}>{handleTitle()}</h1>
      </div>
      <p className={classes.paragraph}>
        <strong className={classes.strong}>Acompanhe</strong> seus carnês,
        acessando os detalhes. Caso queira{" "}
        <strong className={classes.strong}>Cadastrar um novo carnê,</strong>{" "}
        <strong
          onClick={handleClick}
          className="text-[#F08C10] underline-offset-auto cursor-pointer"
        >
          clique aqui.{" "}
        </strong>{" "}
        Pague seu carnê em recorrência no cartão de crédito, ou realize o
        pagamento individual de suas parcelas.
      </p>

      {handleCarnets()}

      {recurringPayment && (
        <ModalRecurringPayment
          openModalMobile={recurringPayment}
          setOpenModalMobile={setRecurringPayment}
          ticketNumber={ticketNumber}
          token={dataId}
          setOpenToast={setOpenToast}
          setIsError={setIsError}
          setMessage={setMessage}
          getCarnes={getCarnes}
        />
      )}
      {openModalCancelRecurring && (
        <ModalCancelRecurringPayment
          modalRemove={openModalCancelRecurring}
          setModalRemove={setOpenModalCancelRecurring}
          ticketNumber={ticketNumber}
          token={dataId}
          getCarnes={getCarnes}
        />
      )}

      {openToast && (
        <Toast
          open={openToast}
          setOpen={setOpenToast}
          type={isError ? "error" : "success"}
          title={message}
        />
      )}
    </>
  );
};

export default MeusCarnes;
