import React, { FC, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import ContainerBox from "../ContainerBox";
import { useIsMobile } from "../../utils/utils";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";

interface EnderecoCardProps {
  setInsertCard?: any;
}

const ErroSemResultados: FC<EnderecoCardProps> = ({ setInsertCard }) => {
  const isMobile = useIsMobile();
  const { activeSection, setActiveMenu } = useHandleSideBar();

  useEffect(() => {
    setActiveMenu(activeSection);
  }, [activeSection, setActiveMenu]);

  const menuDetails = {
    "Meus Pedidos": {
      text: "Oh, não, você não possui pedidos...",
      image: `${process.env.REACT_APP_IMAGE_SERVER_URL}noorder.svg`,
      description:
        "Vamos mudar isso agora! Adquira já seu carnê, pague as parcelas e participe dos sorteios.",
      textButton: "COMPRAR CARNÊ",
      LinkButton: `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`,
    },
    "Pagar Parcelas": {
      text: "Oh, você não possui carnê para pagamento...",
      image: `${process.env.REACT_APP_IMAGE_SERVER_URL}triangulo.svg`,
      description:
        "Vamos mudar isso agora! Adquira já seu carnê, pague as parcelas e participe dos sorteios.",
      textButton: "COMPRAR CARNÊ",
      LinkButton: `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`,
    },
    "Consultar Carnês": {
      text: "Oh, você não possui carnê para consulta...",
      image: `${process.env.REACT_APP_IMAGE_SERVER_URL}triangulo.svg`,
      description:
        "Vamos mudar isso agora! Adquira já seu carnê, pague as parcelas e participe dos sorteios.",
      textButton: "COMPRAR CARNÊ",
      LinkButton: `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`,
    },
    "Resgatar Produtos": {
      text: "Oh, você não possui nenhum carnê liberado para resgate...",
      image: `${process.env.REACT_APP_IMAGE_SERVER_URL}noorder.svg`,
      description:
        "Vamos mudar isso agora! Adquira já seu carnê, pague as parcelas e participe dos sorteios.",
      textButton: "COMPRAR CARNÊ",
      LinkButton: `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`,
    },
    "Meus Endereços": {
      text: "Você não possui endereço cadastrado",
      image: `${process.env.REACT_APP_IMAGE_SERVER_URL}info-erro.svg`,
      description: "Clique no botão abaixo e cadastre o seu endereço.",
      textButton: "Adicionar endereço",
      LinkButton: () => setInsertCard(true),
    },
  };

  const { text, image, description, textButton, LinkButton } =
    menuDetails[activeSection as keyof typeof menuDetails] || {};

  const handleClick = () => {
    if (typeof LinkButton === "string") {
      window.location.href = LinkButton;
    } else {
      if (typeof LinkButton === "function") {
        LinkButton();
      }
    }
  };

  return (
    <ContainerBox>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: `${isMobile ? "300px" : "auto"}`,
            }}
          >
            <img
              src={image}
              style={{ width: `${isMobile ? "80px" : "90px"}`, margin: "auto" }}
              alt="Não encontrado"
            />
            <Typography
              component="p"
              color="#000000"
              sx={{
                width: `${isMobile ? "290px" : "330px"}`,
                fontSize: "20px",
                fontWeight: 700,
                textAlign: "center",
                margin: "20px 0 0 0",
              }}
            >
              {text}
            </Typography>
            <Typography
              component="p"
              color="#000000"
              sx={{
                width: `${isMobile ? "290px" : "330px"}`,
                margin: "20px 0 0 0",
                fontSize: `${isMobile ? "14px" : "16px"}`,
                lineHeight: "21px",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              {description}
            </Typography>
            <Button
              onClick={() => handleClick()}
              sx={{
                width: `${isMobile ? "290px" : "330px"}`,
                height: 48,
                fontSize: "14px",
                margin: "15px 0",
                color: "#F08C10",
                background: "#FFFFFF",
                border: "1px solid #F08C10",
                "&: hover": {
                  background: "#FFFFFF",
                },
              }}
            >
              {textButton}
            </Button>
          </div>
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default ErroSemResultados;
