import { useQuery } from "@tanstack/react-query";
import { baseUrlHubJequiti } from "../../../api/axios";
import { ICoupon } from "../../../types/coupons";

const getCoupons = async (
  fiscalPerson: string,
  page: number,
  token: string
) => {
  const response = await baseUrlHubJequiti.get<ICoupon>(
    `/cupomvirtual/cpf/${fiscalPerson}/pagina/${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const useCoupons = (
  fiscalPerson: string,
  page: number,
  token: string
) => {
  return useQuery({
    queryKey: ["coupons", page],
    queryFn: () => getCoupons(fiscalPerson, page, token),
    refetchInterval: 180000,
  });
};
