import styled from 'styled-components'
import { Box } from "@mui/material";

export const ContainerOrange = styled.div`
    background-color: #F08C10;
    top: 0;
    z-index: 1;
    width: 100%;
    p {
        color: #001C47;
        font-family: Red Hat Display;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: auto;
    }
`

export const ContentBox = styled(Box)`
    display: flex;
    align-items: center;
    text-align: center;
    height: inherit;
    margin: auto;
`