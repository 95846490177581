import styled from "styled-components"
import { Modal } from "react-bootstrap";

interface StyledModalProps {
  customWidth?: string;
  customHeight?: string;
}


export const Container = styled(Modal) <StyledModalProps>`
  .modal-dialog {
    max-width: ${(props) => props.customWidth || "500px"} !important;
    height: ${(props) => props.customHeight || "auto"} !important;
    margin: auto; /* Centraliza o modal */

  }
  .modal-content {
    width: ${(props) => props.customWidth || "500px"} !important;
    height: ${(props) => props.customHeight || "auto"} !important;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;


// export const Container = styled.div`


// `