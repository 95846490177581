import React, {FC, useContext} from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { CartContext } from '../../context/CartProvider';


const ListContainer = styled('div')({
  maxHeight: '250px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#D9D9D9',
    borderRadius: '4px',
  },
});

type Props = {
  arr: any[];
  qtd: string;
  setContext: any;
  setStepper: any;
}

const CardResumo: FC<Props> = ({arr, qtd, setContext, setStepper}) => {  
  const theme = useTheme();
  const { setDisableButton } = useContext(CartContext)!;


  const handleReturn = (value: number) => {
    setContext(value)
    setStepper(value)
    setDisableButton(false)
  }

  return (
    <>
      <div  style={{display: 'flex', justifyContent: 'space-between', margin: '0 20px'}}>
        <Typography 
          variant='h2'
          color='#000000'
          sx={{
            marginTop: '15px'
          }}
        >
          Resumo do pedido
        </Typography>                
        <Typography
          component='button'
          onClick={() => handleReturn(0)}
          variant='subtitle2'
          color='#F08C10'
          fontWeight={theme.typography.fontWeightBold}
          sx={{
            marginTop: '15px',
            background: '#FFFFFF',
            border: 'none'
          }}
        >
          Editar carrinho
        </Typography>
      </div>
      <ListContainer>
        {arr.map((item, index) => (
          <List key={index}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <img 
                  src={`${process.env.REACT_APP_IMAGE_SERVER_URL}img_carne_bau.svg`}
                  style={{ width: `40px`, height: `40px`}}
                  alt="Carnê do Baú"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography 
                      variant='subtitle2' 
                      fontWeight={theme.typography.fontWeightBold}
                      color='rgba(0, 0, 0, 0.5)'
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                        fontSize: '12px'
                      }}
                    >
                      {/* {item.name} */}
                      {`${item.numero}ª Parcela carnê: série ${item.serie} | número ${item.numeroCarne}-${item.digito}`}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography 
                      variant='subtitle1' 
                      fontWeight={theme.typography.fontWeightBold}
                      color='#000000'
                    >
                    {item.valor.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        ))}              
      </ListContainer>
    </>
  )
}

export default CardResumo
