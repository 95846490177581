import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IBackOfficeProvider, IContext, IUser } from "./types";
import { api } from "../../services/api";
import {
  LoginRequest,
  LoginClient,
  PaymentPix,
} from './util'
import {
  Descript,
} from "../AuthProvider/util"

export const BackOfficeContext = createContext<any | undefined>(undefined);

export const BackOfficeProvider: React.FC<IBackOfficeProvider> = ({ children }) => {
  

  const loginBackOffice = async (login: string, senha: string) => {
    const request = await LoginRequest(login, senha);

    try {
      const userBackOffice = await descriptToken(request);

      const userKey = {
        login: userBackOffice.login,
        email: userBackOffice.email,
      };

      localStorage.setItem("backOfficeId", JSON.stringify(userKey));

      const cleanBearerToken = request.replace(/^Bearer\s+/i, "");
      const token = cleanBearerToken;
      localStorage.setItem("tokenBackOffice", token);

    } catch (error: any) {
      throw error;
    }
  };
  
  const loginClient = async (login: string, date: string) => {
    const request = await LoginClient(login.replace(/\D/g, ""), date);

    try {
      const userClient = await descriptToken(request['token-user']);
      localStorage.setItem("token", request['token-user']);

      const userKey = {
        id: userClient.id,
        nome: userClient.nome,
      };

      localStorage.setItem("userId", JSON.stringify(userKey));

    } catch (error: any) {
      throw error.message;
    }
  };

  const isBackOfficeLogged = () => {
    const storeBackoffice = localStorage.getItem("backOfficeId");
    return !!storeBackoffice;
  }

  const descriptToken = async (data: any) => {
    try {
      const response = await Descript(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const pixPayment = async(data: any, token: any, login: string) => {  
    const response = await PaymentPix(data, token, login);
    return response
  }
  
  const contextValue: IContext = {
    loginBackOffice,
    loginClient,
    isBackOfficeLogged,
    pixPayment,
  };

  return (
    <BackOfficeContext.Provider value={contextValue}>{children}</BackOfficeContext.Provider>
  );
};