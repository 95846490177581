import PixOutlinedIcon from '@mui/icons-material/PixOutlined'
import { Typography } from '@mui/material'

const TextoPix = () => {
  return (
    <div
      style={{
        margin: '2% 5%',
      }}
    >
      <Typography 
        variant='subtitle1'
        sx={{
          fontWeight: 700,
          margin: '0 0 20px 0'
        }}
      >
        <PixOutlinedIcon /> PIX
      </Typography>
      <Typography 
        variant='subtitle1'
        sx={{
          fontWeight: 500,
          lineHeight: '18px',
          textAlign: 'justify',
          fontSize: '14px'
        }}
      >
        <p>
          Após clicar no botão "Realizar pagamento", o código Pix ficará disponível para 
          pagamento por no máximo 20 minutos.
        </p>
        <p>
          Após a finalização não será mais possível adicionar ou retirar itens do seu carrinho
        </p>
        <p>
          O pagamento das parcelas só será creditada depois que identificamos o pagamento. 
          Caso não seja pago, seu pedido será cancelado.
        </p>
      </Typography>
    </div>
  )
}

export default TextoPix
