import React, {FC} from 'react'
import { useTheme } from '@mui/material/styles'
import { 
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIsMobile } from '../../utils/utils'  

type Props = {
  arr: any[];
  qtd: string;
  vlrTotal: any;
  vlrDesc: any;
}

const ListContainer = styled('div')({
  maxWidth: '95%',
  maxHeight: '130px',
  marginBottom: '35px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#D9D9D9',
    borderRadius: '4px',
  },
});

const CardConfirmPayment: FC<Props> = ({arr, qtd, vlrTotal, vlrDesc}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <div style={{width:'100%', borderBottom: isMobile ? '1px solid rgba(0, 0, 0, 0.2)' : 'none'}}>
      <div  style={{display: 'flex', justifyContent: 'space-between', margin: '0 20px'}}>
        <Typography 
          variant='h2'
          color='#1C1C7B'
          sx={{
            marginTop: '15px'
          }}
        >
          Resumo do pedido
        </Typography>
      </div>
      <ListContainer>
        {arr.map((item, index) => (
          <List key={index} sx={{maxHeight: '90px'}}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <img
                  src={`${process.env.REACT_APP_IMAGE_SERVER_URL}img_carne_bau.svg`}
                  style={{ width: `40px`, height: `40px`}}
                  alt="Carnê do Baú"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography 
                      variant='subtitle2' 
                      fontWeight={theme.typography.fontWeightBold}
                      color='rgba(0, 0, 0, 0.5)'
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                        fontSize: '12px'
                      }}
                    >
                      {`${item.numero}ª Parcela carnê: série ${item.serie} | número ${item.numeroCarne}-${item.digito}`}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography 
                      variant='subtitle2' 
                      fontWeight={theme.typography.fontWeightBold}
                      color='#202020'
                      sx={{
                        textAlign: 'right'
                      }}
                    >
                    {item.valor?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        ))}
      </ListContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15px' }}>
        <Typography
          variant='subtitle1'
          color='#000000'
        >
          Valor do Pedido:
        </Typography>
        <Typography
          variant='subtitle1'
          color='#000000'
        >
          {vlrTotal?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 15px' }}>
        <Typography
          variant='subtitle1'
          color='#001C47'  
          fontWeight={theme.typography.fontWeightBold}
        >
          Total:
        </Typography>
        <Typography
          variant='subtitle1'
          color='#001C47'  
          fontWeight={theme.typography.fontWeightBold}
        >
          {vlrTotal?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} 
        </Typography>
      </div>
    </div>
  )
}

export default CardConfirmPayment
