import React, { useEffect, FC } from "react";
import { Grid, Typography, Box } from "@mui/material";
import ContainerBox from "../ContainerBox";
import { useIsMobile } from "../../utils/utils";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../context/AuthProvider/useAuth";
import Logo from "../../assets/img/logo-bau.png";
import { userStore } from "../../store/user/user";

type Props = {
  vlrTotal: any;
  vlr: string;
};

const BannerConfirmation: FC<Props> = ({ vlrTotal, vlr }) => {
  const [id, setId] = React.useState<null | any>(null);
  const auth = useAuth();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { user } = userStore();

  useEffect(() => {
    const data = localStorage.getItem("token");

    if (data) {
      handleDescript(data);
    }
  }, []);

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(item);
        setId(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  return (
    <ContainerBox sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              width: "100%",
              marginBottom: "30px",
              minHeight: "202px",
              background: "#F08C10",
              border: "1px solid #F08C10",
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {!isMobile && (
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={Logo}
                    style={{ width: `202px`, height: `202px` }}
                    alt="Premios"
                  />
                </Grid>
              )}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  fontWeight={theme.typography.fontWeightBold}
                  sx={{
                    color: "#FFFFFF",
                    margin: "20px 15px",
                  }}
                >
                  Seu pedido foi processado com sucesso
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{
                    color: "#FFFFFF",
                    margin: "20px 15px",
                  }}
                >
                  Um e-mail de confirmação foi enviado a você em &nbsp;
                  {`${user?.email}`} todos os detalhes do pedido.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{
                    color: "#FFFFFF",
                    margin: "20px 15px",
                  }}
                >
                  {vlr === "1"
                    ? `Agora, efetue o pagamento no valor de ${vlrTotal?.toLocaleString(
                        "pt-BR",
                        { style: "currency", currency: "BRL" }
                      )} até o dia do vencimento, 
                  utilizando os dados abaixo.`
                    : vlr === "3"
                    ? `Seu pagamento está sendo processado, você receberá um e-mail de confirmação.`
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default BannerConfirmation;
