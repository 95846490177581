import { create } from "zustand";

interface ITicket {
  exibirDetalhe: boolean;
  setExibirDetalhe: (value: boolean) => void;

}

export const useTicket = create<ITicket>((set) => ({
  exibirDetalhe: false,
  setExibirDetalhe: (value: boolean) => set({ exibirDetalhe: value }),
}));