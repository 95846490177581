export const pedidos = [  
  {
    "situacao": "Iniciar manuseio",
    "valorTotalPedido": 200.0,
    "enderecoEntrega": "Rua Afonso Popenga - casa - Palhoça - SC - 88135280",
    "pedidoLinhas": [
        {
            "descProduto": "Kit Baú Extra - R$ 200,00  - 312",
            "valorTotal": 200.0,
            "quantidade": 1,
            "codProduto": "312"
        },
        {
            "descProduto": "CUPOM RODA A RODA CLIENTE",
            "valorTotal": 0.0,
            "quantidade": 10,
            "codProduto": "99715146"
        }
    ],
    "origem": "MAGENTO",
    "numeroPedido": "997",
    "dataPedido": "2023-05-18T03:00:00.000+0000",
    "codSituacao": 10,
    "codigoBarras": "1409614958",
  },
  {
    "situacao": "Aguardando Pagamento",
    "valorTotalPedido": 200.0,
    "enderecoEntrega": "Rua Afonso Popenga - casa - Palhoça - SC - 88135280",
    "pedidoLinhas": [
        {
            "descProduto": "Kit Baú Extra - R$ 200,00  - 312",
            "valorTotal": 200.0,
            "quantidade": 1,
            "codProduto": "312"
        },
        {
            "descProduto": "CUPOM RODA A RODA CLIENTE",
            "valorTotal": 0.0,
            "quantidade": 10,
            "codProduto": "99715146"
        }
    ],
    "origem": "MAGENTO",
    "numeroPedido": "930",
    "dataPedido": "2023-05-18T03:00:00.000+0000",
    "codSituacao": 10,
    "codigoBarras": "0965343820"
  },
  {
    "situacao": "Iniciar manuseio",
    "valorTotalPedido": 200.0,
    "enderecoEntrega": "Rua Afonso Popenga - casa - Palhoça - SC - 88135280",
    "pedidoLinhas": [
        {
            "descProduto": "Kit Baú Extra - R$ 200,00  - 312",
            "valorTotal": 200.0,
            "quantidade": 1,
            "codProduto": "312"
        },
        {
            "descProduto": "CUPOM RODA A RODA CLIENTE",
            "valorTotal": 0.0,
            "quantidade": 10,
            "codProduto": "99715146"
        }
    ],
    "origem": "MAGENTO",
    "numeroPedido": "931",
    "dataPedido": "2023-05-18T03:00:00.000+0000",
    "codSituacao": 10,
    "codigoBarras": "1409614958"
  },  
  {
    "situacao": "Iniciar manuseio",
    "valorTotalPedido": 300.0,
    "enderecoEntrega": "Rua Afonso Popenga - casa - Palhoça - SC - 88135280",
    "pedidoLinhas": [
        {
            "descProduto": "Kit Baú Extra - R$ 200,00  - 312",
            "valorTotal": 200.0,
            "quantidade": 1,
            "codProduto": "312"
        },
        {
            "descProduto": "CUPOM RODA A RODA CLIENTE",
            "valorTotal": 0.0,
            "quantidade": 10,
            "codProduto": "99715146"
        }
    ],
    "origem": "MAGENTO",
    "numeroPedido": "998",
    "dataPedido": "2023-05-18T03:00:00.000+0000",
    "codSituacao": 10,
    "codigoBarras": "1409614958"
  },
  {
    "situacao": "Iniciar manuseio",
    "valorTotalPedido": 200.0,
    "enderecoEntrega": "Rua Afonso Popenga - casa - Palhoça - SC - 88135280",
    "pedidoLinhas": [
        {
            "descProduto": "Kit Baú Extra - R$ 200,00  - 312",
            "valorTotal": 200.0,
            "quantidade": 1,
            "codProduto": "312"
        },
        {
            "descProduto": "CUPOM RODA A RODA CLIENTE",
            "valorTotal": 0.0,
            "quantidade": 10,
            "codProduto": "99715146"
        }
    ],
    "origem": "MAGENTO",
    "numeroPedido": "935",
    "dataPedido": "2023-05-18T03:00:00.000+0000",
    "codSituacao": 10,
    "codigoBarras": "0965343820"
  },
  {
    "situacao": "Iniciar manuseio",
    "valorTotalPedido": 200.0,
    "enderecoEntrega": "Rua Afonso Popenga - casa - Palhoça - SC - 88135280",
    "pedidoLinhas": [
        {
            "descProduto": "Kit Baú Extra - R$ 200,00  - 312",
            "valorTotal": 200.0,
            "quantidade": 1,
            "codProduto": "312"
        },
        {
            "descProduto": "CUPOM RODA A RODA CLIENTE",
            "valorTotal": 0.0,
            "quantidade": 10,
            "codProduto": "99715146"
        }
    ],
    "origem": "MAGENTO",
    "numeroPedido": "938",
    "dataPedido": "2023-05-18T03:00:00.000+0000",
    "codSituacao": 10,
    "codigoBarras": "1409614958"
  },
]

export const resgate = [
    {        
      "codigoBarras": "1409614958",
      "tipo": "Digital",    
      "valorResgate": 200.0,
      "valorPago": 180.0,
      "status": "Disponivel para Resgate"
    },
    {        
      "codigoBarras": "1409614960",
      "tipo": "Fisico",    
      "valorResgate": 200.0,
      "valorPago": 180.0,
      "status": "Disponivel para Resgate"
    },
    {        
      "codigoBarras": "1409614962",
      "tipo": "Digital",    
      "valorResgate": 200.0,
      "valorPago": 180.0,
      "status": "Disponivel para Resgate"
    },
    {        
      "codigoBarras": "1409614964",
      "tipo": "Fisico",    
      "valorResgate": 200.0,
      "valorPago": 180.0,
      "status": "Disponivel para Resgate"
    },
    {        
      "codigoBarras": "1409614966",
      "tipo": "Digital",    
      "valorResgate": 200.0,
      "valorPago": 180.0,
      "status": "Disponivel para Resgate"
    },
    {        
      "codigoBarras": "1409614968",
      "tipo": "Fisico",    
      "valorResgate": 200.0,
      "valorPago": 180.0,
      "status": "Disponivel para Resgate"
    },
]

export const pagarCarne = [
  {        
    "codigoBarras": "1409614958",
    "tipo": "Digital",
    "status": "Vinculado ao cliente",
    "value": "80.0",
    "recorrencia": true,
  },
  {        
    "codigoBarras": "1409614960",
    "tipo": "Fisico",
    "status": "Vinculado ao cliente",
    "value": "70.0",
    "recorrencia": true,
  },
  {        
    "codigoBarras": "1409614962",
    "tipo": "Digital",
    "status": "Resgatado",
    "value": "180.0",
    "recorrencia": false,
  },
  {        
    "codigoBarras": "1409614964",
    "tipo": "Fisico",
    "status": "Vinculado ao cliente",
    "value": "180.0",
    "recorrencia": true,
  },
  {        
    "codigoBarras": "1409614966",
    "tipo": "Digital",
    "status": "Pré Tesouro",
    "value": "180.0",
    "recorrencia": false,
  },
  {        
    "codigoBarras": "1409614968",
    "tipo": "Digital",
    "status": "Quitado",
    "value": "180.0",
    "recorrencia": false,
  },
  {        
    "codigoBarras": "1409614970",
    "tipo": "Fisico",
    "status": "Resgatado",
    "value": "350.0",
    "recorrencia": false,
  },
]