import { FC } from "react";
import { Button, Typography, Grid } from "@mui/material";
import { formatCarneResgatado } from "../../utils/utils";
import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../DataTable";
import { pagarCarne } from "../../utils/meuscarnes";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#F08C10",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const PagarCarne: FC = () => {
  const renderTypography = (headerName: any) => (
    <Typography
      component="p"
      variant="subtitle1"
      fontWeight={700}
      color="#000000"
      sx={{
        fontSize: "10px",
        textTransform: "uppercase",
      }}
    >
      {headerName}
    </Typography>
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Série | Número-DV",
      width: 150,
      sortable: false,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "tipo",
      headerName: "tipo",
      width: 100,
      sortable: false,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "status",
      headerName: "status",
      width: 160,
      sortable: false,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "recorencia",
      headerName: "Pagar em Recorrência",
      width: 150,
      sortable: false,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
      disableColumnMenu: true,
      renderCell: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AntSwitch inputProps={{ "aria-label": "ant design" }} />
        </div>
      ),
    },
    {
      field: "button",
      headerName: " ",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              width: "80px",
              height: "30px",
              color: "#F08C10",
              fontSize: "8px",
              border: "1px solid #F08C10",
              borderRadius: 0,
              background: "#FFFFFF",
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
          >
            Pagar Parcela
          </Button>
        </div>
      ),
    },
  ];

  const rows: any[] = pagarCarne.map((row) => ({
    id: formatCarneResgatado(row.codigoBarras),
    tipo: row.tipo,
    status: row.status,
    button: "",
  }));

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "center", md: "left" },
        }}
      >
        <div
          style={{
            flexDirection: "column",
          }}
        >
          <div>
            <Typography variant="h1">Pagar Parcelass</Typography>
            <Typography
              variant="subtitle1"
              color="#000000"
              sx={{
                margin: "0 0 30px 0",
                width: { xs: "360px", sm: "360px", md: "454px" },
              }}
            >
              <b>Pague</b> seu carnê em recorrência no cartão de crédito, ou
              realize o pagamento individual de suas parcelas.
            </Typography>
          </div>
          <DataTable rows={rows} columns={columns} />
        </div>
      </Grid>
    </Grid>
  );
};

export default PagarCarne;
