import React, {FC} from 'react'
import {
  Table,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

type Props = {
  arr: any[];
  vlrTotal: any;
  vlrDesc: any;
}


const TableResume: FC<Props> = ({arr, vlrTotal, vlrDesc}) => {
  const theme = useTheme();

  return (
    <Table size="small" aria-label="a dense table" >
      <TableHead
        sx={{
          display: {xs: 'none', sm: 'contents'}
        }}
      >
        <TableRow
          sx={{
            backgroundColor: '#1C1C7B',
            height: '20px'
          }}
        >
          <Typography 
            variant='subtitle1' 
            color='#FFFFFF'  
            fontWeight={theme.typography.fontWeightBold}
            sx={{
              fontSize: '12px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            Resumo do Pedido
          </Typography>
        </TableRow>
      </TableHead>                 
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 10px 15px' }}>
        <Typography
          variant='subtitle1'
          color='#000000'
        >
          Total de parcela{`${arr.length > 1 ? 's' : ''}`} ({arr.length} ite{`${arr.length > 1 ? 'ns' : 'm'}`})
        </Typography>
        <Typography
          variant='subtitle1'
          color='#000000'
        >
          {vlrTotal().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </div>                  
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15px 30px 15px' }}>
        <Typography
          variant='subtitle1'
          color='#000000'
        >
          Descontos:
        </Typography>
        <Typography
          variant='subtitle1'
          color='#000000'
        >                        
          {vlrDesc().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </div>                   */}
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15px' }}>
        <Typography
          variant='subtitle1'
          color='#000000'  
          fontWeight={theme.typography.fontWeightBold}
        >
          Total:
        </Typography>
        <Typography
          variant='subtitle1'
          color='#000000'  
          fontWeight={theme.typography.fontWeightBold}
        >
          {vlrTotal().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} 
        </Typography>
      </div>
    </Table>
  )
}

export default TableResume
