import React, { useEffect, useRef, useState } from "react";
import { Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";

import { saveAs } from "file-saver";

import { Subtitle } from "./styles";
import { useHubJequiti } from "../../../../../store/hubjequiti";
import { baseUrlHubJequiti } from "../../../../../api/axios";
import { userStore } from "../../../../../store/user/user";
import { toString } from "lodash";
interface ModalSeeCouponProps {
  open: boolean;
  setOpen(value: boolean): void;
  couponId: string;
}

export const ModalSeeCoupon: React.FC<ModalSeeCouponProps> = ({
  open,
  setOpen,
  couponId,
}) => {
  const { token } = useHubJequiti();
  const { user } = userStore();

  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const userToken = toString(token);

  const contentRef = useRef(null);

  const handleDownload = () => {
    try {
      setLoading(true);
      if (!image) {
        throw new Error("Imagem inválida ou ausente.");
      }
      const blob = new Blob(
        [Uint8Array.from(atob(image), (c) => c.charCodeAt(0))],
        {
          type: "image/png",
        }
      );
      saveAs(blob, "imagem.png");
    } catch (error: any) {
      console.error("Erro ao processar a imagem:", error.message);
    } finally {
      setLoading(false);
    }
  };

  async function getImageCoupon() {
    try {
      const response = await baseUrlHubJequiti.get(
        `/cupomvirtual/${couponId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken ? userToken : user.token}`,
          },
        }
      );
      setImage(response.data.imagem);
    } catch (error: any) {
      console.log("erro ao listar cupons", error);
    }
  }

  useEffect(() => {
    getImageCoupon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} className="p-3">
      <div className="p-3 ">
        <div className="d-flex justify-content-end">
          <Tooltip title="fechar">
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon className="text-warning" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="cursor-pointer d-flex" onClick={handleDownload}>
          <Subtitle>Baixar cupom </Subtitle>
          <GetAppIcon style={{ color: "#1C1C7B" }} />
        </div>

        <div ref={contentRef} className="mb-4">
          <img
            src={`data:image/png;base64,${image}`}
            alt="Capa Carnê Digital"
            className="w-full h-24 md:h-full"
          />
        </div>

        <Typography variant="subtitle1" color="#000000">
          *ATENÇÃO: Você não precisa mais enviar o seu cupom impresso, porque o
          Baú irá imprimir e colocar nas urnas do SBT.
        </Typography>
      </div>
    </Dialog>
  );
};
