import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: initial;
  width: auto;
  padding: 0px 13px;
  h4 {
    color: #1c1c7b;
    font-family: Red Hat Display;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .body1 {
    margin-top: 31px;
    color: #000;
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 90%;
  }
`;

export const LinkButton = styled.button`
  text-decoration: underline;
  border: none;
  background-color: transparent;
  color: #000;
  font-weight: 700;
`;
