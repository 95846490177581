import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Box } from "@mui/material";
import ContainerBox from "../ContainerBox";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";

const CardStep: FC = () => {
  const { handleSideBar, setActiveMenuItem, toggleSubMenu } =
    useHandleSideBar();
  const navigate = useNavigate();

  const handleClick = (nameMenuItem: string) => {
    if (nameMenuItem === "Comprar Carnê") {
      window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`;
    } else {
      navigate("/minha-conta");
      setActiveMenuItem(nameMenuItem);
      toggleSubMenu(true);
      handleSideBar(nameMenuItem);
    }
  };

  const arrays = [
    {
      label: "Peça aqui",
      text: ` o seu carnê. O Baú vai enviá-lo para sua casa.`,
      imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}pecaAqui.svg`,
      name: "Comprar Carnê",
    },
    {
      label: "Cadastre Aqui",
      text: ` o seu carnê para localizarmos você caso for sorteado.`,
      imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}cadastre.svg`,
      name: "Cadastrar Carnê",
    },
    {
      label: "Escolha Aqui",
      text: ` as suas mercadorias e receba em casa.`,
      imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}escolha.svg`,
      name: "Resgatar Produtos",
    },
  ];

  return (
    <ContainerBox>
      <Grid
        container
        spacing={0}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {arrays.map((items: any, index: any) => (
          <Grid
            item
            lg={4}
            md={12}
            sm={12}
            xs={12}
            key={index}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: 360,
                height: 289,
                background: `${
                  index % 2 === 0 ? "#E6E9ED" : "rgba(240, 140, 16, 0.15)"
                }`,
                border: "none",
                margin: "0 15px 15px 15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    width: "260px",
                    height: "162px",
                  }}
                  src={items.imagem}
                  alt={items.label}
                />
                <Typography
                  variant="subtitle1"
                  color="#001C47"
                  sx={{
                    width: 260,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    component="a"
                    color="#001C47"
                    onClick={() => handleClick(items.name)}
                    sx={{
                      fontWeight: 700,
                      textDecoration: "underline",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#001C47",
                      },
                    }}
                  >
                    {items.label}
                  </Typography>
                  {/* {items.label === 'Peça aqui' ? (
                    <Typography
                      variant="subtitle1"
                      color="#001C47"
                      component="a"
                      href={`${process.env.REACT_APP_LINK_KITS}carne-do-bau-da-felicidade-jequiti-2022/p`}
                      target='_blank'
                      sx={{
                        fontWeight: 700,
                        textDecoration: "underline",
                        cursor: 'pointer'
                      }}
                    >
                      {items.label}
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      color="#001C47"
                      sx={{
                        fontWeight: 700,
                        textDecoration: "underline",
                        cursor: 'pointer'
                      }}
                    >
                      {items.label}
                    </Typography>
                  )}                 */}
                  {items.text}
                </Typography>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: 260 }}>
                  <Typography
                    variant="subtitle1"
                    color="#001C47"
                    sx={{
                      textAlign: "center",
                      fontWeight: 700,
                      textDecorationLine: "underline",
                    }}
                  >
                    {items.label} {items.subText}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="#001C47"
                    sx={{
                      textAlign: "center",
                      fontWeight: 700,
                      textDecorationLine: "underline",
                    }}
                  >
                    {items.subText}
                  </Typography>
                </div> */}
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </ContainerBox>
  );
};

export default CardStep;
