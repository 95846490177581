import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerOrange, ContentBox } from "./UserAutenticated.styled";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Typography, Box } from "@mui/material";
import { useIsMobile } from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { CartContext } from "../../context/CartProvider";

const BackOfficeAutenticated = () => {
  const [tokenBko, setTokenBko] = useState<null | any>(null);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const auth = useAuth();
  const { setCartItems } = useContext(CartContext)!;
  const token = localStorage.getItem("backOfficeId");

  useEffect(() => {
    if (token) setTokenBko(JSON.parse(token));
  }, [token]);

  const handleLogOff = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedItems");
    setCartItems([]);
    navigate("/autenticar/login");
  };

  const handleLogOffBko = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("backOfficeId");
    localStorage.removeItem("tokenBackOffice");
    localStorage.removeItem("selectedItems");
    navigate("/autenticar");
  };

  return (
    <ContainerOrange
      style={{
        height: isMobile ? 60 : 50,
        top: isMobile ? 125 : 103,
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        zIndex: 1,
      }}
    >
      <ContentBox
        sx={{
          width: { xs: "290px", sm: "718px", md: "869px", lg: "1129px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            height: 50,
          }}
        >
          <PersonOutlineOutlinedIcon
            sx={{ fontSize: 20, margin: "auto 3px auto auto" }}
          />
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: isMobile ? 12 : 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            color="#001C47"
          >
            {"Logado como"}{" "}
            <strong style={{ marginLeft: 5 }}>{tokenBko?.login}</strong>
          </Typography>
          {auth?.isAuthenticated() && (
            <Typography
              variant="subtitle1"
              onClick={() => handleLogOff()}
              sx={{
                cursor: "pointer",
                fontSize: isMobile ? 12 : 16,
                lineHeight: 15,
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: isMobile ? "0 5px" : "0 10px",
                textDecorationLine: "underline",
              }}
              color="#001C47"
            >
              Atualizar Cliente
            </Typography>
          )}
          <Typography
            variant="subtitle1"
            onClick={handleLogOffBko}
            sx={{
              cursor: "pointer",
              fontSize: isMobile ? 12 : 16,
              lineHeight: 15,
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: isMobile ? "0 5px" : "0 10px",
              textDecorationLine: "underline",
            }}
            color="#001C47"
          >
            Sair
          </Typography>
        </Box>
      </ContentBox>
    </ContainerOrange>
  );
};

export default BackOfficeAutenticated;
