import React, { FC, useState, useEffect, useContext } from "react";
import { Grid, InputBase, InputLabel, TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import {
  validateName,
  formatCpf,
  validateCpf,
  validateCreditCard,
  validateCVV,
  validateDateCreditCard,
} from "../../utils/utils";
import { CartContext } from "../../context/CartProvider";

const Label = styled(InputLabel)(({ theme }) => ({
  fontFamily: "Red Hat Display, sans-serif",
}));

type Props = {
  setSendPayment: any;
};

const CreditCard: FC<Props> = ({ setSendPayment }) => {
  const [carNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [fullName, setFullName] = useState("");
  const [isError, setIsError] = useState(false);
  const [cpf, setCpf] = useState("");
  const [errorCpf, setErrorCpf] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [check, setCheck] = useState(false);
  const [date, setDate] = useState("");

  const [errorCard, setErrorCard] = useState(false);
  const [errorCvv, setErrorCvv] = useState(false);
  const [errorDateCard, setErrorDateCard] = useState(false);
  const { setDisableButton } = useContext(CartContext)!;

  useEffect(() => {
    const dadosCartao = {
      nomeTitularCartao: fullName,
      numeroCartao: carNumber.replace(/\s/g, ""),
      mesVencimento: date.slice(0, 2),
      anoVencimento: date.slice(3),
      codigoSeguranca: cvv,
    };
    setSendPayment(dadosCartao);
  }, [carNumber, cvv, fullName, date]);

  useEffect(() => {
    const allFieldsAreValid =
      !errorCard &&
      !errorCvv &&
      !errorDateCard &&
      !isError &&
      !errorCpf &&
      carNumber &&
      cvv &&
      fullName &&
      date &&
      cpf;

    setDisableButton(!allFieldsAreValid);
  }, [
    carNumber,
    cvv,
    fullName,
    date,
    errorCard,
    errorCvv,
    errorDateCard,
    isError,
    errorCpf,
    cpf,
    setDisableButton,
  ]);

  const handleCard = (event: any) => {
    const value = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4")
      .slice(0, 19);
    setCardNumber(value);
    setErrorCard(!validateCreditCard(event.target.value));
  };

  const handleInputName = (event: any) => {
    const value = event.target.value.replace(/[0-9]/g, "");
    const values = event.target.value.trim();
    const [firstName, ...rest] = values.split(" ");
    const secondName = rest.join(" ");
    setFirstName(firstName);
    setSecondName(secondName);
    setFullName(value.slice(0, 50));
    setCheck(false);
    setIsError(!validateName(event.target.value));
  };

  const handledate = (event: any) => {
    const value = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{2})/, "$1/$2")
      .slice(0, 5);
    setDate(value);
    setErrorDateCard(!validateDateCreditCard(value));
  };

  const handleCvv = (event: any) => {
    const value = event.target.value.replace(/\D/g, "").slice(0, 3);
    setCvv(value);
    setErrorCvv(value.length === 3 ? false : !validateCVV(value));
  };

  const handleChangeCpf = (event: any) => {
    const value = event.target.value;
    setCpf(value);
    setCheck(false);
    setErrorCpf(!validateCpf(event.target.value) || !event.target.value);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Label>Número Cartão</Label>
        <TextField
          name="Credit Card"
          value={carNumber}
          onChange={handleCard}
          placeholder="0000 0000 0000 0000"
          sx={{
            width: { xs: 270, sm: 270, md: 300, lg: 300 },
            height: 15,
            margin: "0 0 80px 0",
          }}
          required
          error={errorCard}
          helperText={
            errorCard ? "Por favor, insira um número de cartão válido" : ""
          }
        />
        <Label>Nome do titular</Label>
        <TextField
          placeholder="Nome impresso no cartão"
          type="text"
          name="name"
          value={fullName}
          onChange={handleInputName}
          required
          error={isError}
          helperText={isError ? "Nome e sobrenome são obrigatórios" : ""}
          sx={{
            width: { xs: 270, sm: 270, md: 300, lg: 300 },
            margin: "0 55px 0 0",
          }}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: 300,
              margin: "0 55px 48px 0",
            }}
          >
            <div>
              <Label>Validade</Label>
              <TextField
                name="Ano"
                placeholder="MM/AA"
                value={date}
                onChange={handledate}
                sx={{
                  width: { xs: 128, sm: 128, md: 143, lg: 143 },
                  height: 48,
                  margin: "0 15px 0 0",
                }}
                required
                error={errorDateCard}
                helperText={errorDateCard ? "Informe uma data válida" : ""}
              />
            </div>
            <div>
              <Label>Cód. Segurança</Label>
              <TextField
                name="CVV"
                placeholder="000"
                value={cvv}
                onChange={handleCvv}
                sx={{
                  width: { xs: 128, sm: 128, md: 143, lg: 143 },
                  height: 48,
                }}
                required
                error={errorCvv}
                helperText={errorCvv ? "Por favor, insira um CVV válido." : ""}
              />
            </div>
          </div>
          <Label>CPF do titular do cartão</Label>
          <TextField
            name="number"
            placeholder="000.000.000-00"
            value={formatCpf(cpf)}
            onChange={handleChangeCpf}
            error={errorCpf}
            helperText={errorCpf ? "CPF inválido" : null}
            required
            sx={{
              width: { xs: 270, sm: 270, md: 300, lg: 300 },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default CreditCard;
