import React, { FC, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useIsMobile } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";

type Props = {
  pedido: number;
  data: string;
};

const CardTicket: FC<Props> = ({ pedido, data }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [id, setId] = useState("");
  const date = new Date(data);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();
  const navigate = useNavigate();
  const backOffice = useBackOffice();

  const formattedDate = `Realizado em ${day}/${month}/${year} às ${hour}:${minute}`;

  useEffect(() => {
    const data = localStorage.getItem("token");
    if (data) {
      setId(data);
    }
  }, [data]);

  const handleNavigate = () => {
    backOffice?.isBackOfficeLogged()
      ? navigate("/autenticar/minha-conta")
      : navigate("/minha-conta")      
      handleSideBar("Pagar Parcelas");
      toggleSubMenu(true);
  }

  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "flex",
        justifyContent: "center",
        borderBottom: isMobile ? "1px solid #EFEFEF" : "none",
      }}
    >
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 15px",
          }}
        >
          <Typography
            variant="h2"
            color="#1C1C7B"
            fontWeight={theme.typography.fontWeightBold}
          >
            Número do pedido
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 15px",
          }}
        >
          <Typography
            variant="h2"
            color="#F08C10"
            fontWeight={theme.typography.fontWeightBold}
          >
            {`#${pedido}`}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 15px",
          }}
        >
          <Typography variant="subtitle2" color="#000000">
            {formattedDate}
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ width: "100%", margin: "20px 30px 20px 0" }}>
          <Typography
            component="a"
            onClick={() => handleNavigate()}
            variant="button"
            color="#000000"
            sx={{
              backgroundColor: "#FFFFFF",
              border: "2px solid #F08C10",
              fontSize: "12px",
              color: "#F08C10",
              width: "100%",
              height: "26px",
              cursor: 'pointer',
              "&: hover": {
                color: "#F08C10",
              },
            }}
          >
            Acompanhe seus carnês
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default CardTicket;
