import { Grid } from "@mui/material";
import SideBar from "../../components/SideBar";
import { MainContent } from "../../components/MainContent";

const MinhaContaDesktop = () => {
  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        item
        lg={3}
        md={3}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "block",
          }}
        >
          <SideBar />
        </div>
      </Grid>
      <Grid
        item
        lg={9}
        md={9}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            marginLeft: "50px",
            marginBottom: 50,
          }}
        >
          <MainContent />
        </div>
      </Grid>
    </Grid>
  );
};

export default MinhaContaDesktop;
