import React, { useEffect, useRef } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Button,
  Collapse,
} from "@mui/material";
import { useIsMobile } from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import {
  Container,
  StyledArrow,
  StyledAssignmentOutlined,
  StyledFileCopyOutlined,
  StyledKeyboardArrowDownIcon,
  StyledLocationOnOutlined,
  StyledViewInArOutlined,
} from "./SideBar.styled";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  const { setMenuSidebar } = useHandleParcela();
  const isMobile = useIsMobile();
  const backOffice = useBackOffice();
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const name = user?.cliente?.nome || user?.nome;

  const {
    handleSideBar,
    toggleSubMenu,
    isSubMenuOpen,
    setIsDrawerOpen,
    activeMenuItem,
    setActiveMenuItem,
  } = useHandleSideBar();

  const submenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        submenuRef.current &&
        !submenuRef.current.contains(event.target as Node)
      ) {
        toggleSubMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleSubMenu]);

  const handleLogout = () => {
    const tokenRedirectMagento = localStorage.getItem("dataClient");

    var url = `${process.env.REACT_APP_API_BAU}/customer/account/logoff/token=${tokenRedirectMagento}`;

    // handleLogoffMagento(url);

    backOffice?.isBackOfficeLogged()
      ? handleLogoutBackOffice()
      : handleLogoutClient();
  };

  const handleLogoffMagento = (item: string) => {
    window.open(`${item}`, "_blank");
  };

  const handleLogoutClient = () => {
    auth?.logout();
  };

  const handleLogoutBackOffice = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("dataCliente");
    navigate("/autenticar/login");
  };

  const menuItems = [
    { id: 1, name: "Meus Pedidos" },
    { id: 2, name: "Meus Carnês" },
    { id: 3, name: "Pagar Parcelas" },
    { id: 4, name: "Cadastrar Carnê" },
    { id: 5, name: "Resgatar Produtos" },
    { id: 6, name: "Consultar Carnês" },
    { id: 7, name: "Meus Dados" },
    { id: 8, name: "Meus Endereços" },
  ];

  const handleMenuItemClick = (menuItemName: string) => {
    setActiveMenuItem(menuItemName);
    const selectedItem = menuItems.find((item) => item.name === menuItemName);
    if (selectedItem) {
      handleSideBar(selectedItem.name);
    }
    if (isMobile) {
      setIsDrawerOpen(true);
      setMenuSidebar(true);
    }
    if (menuItemName === "Meus Carnês") {
      setIsDrawerOpen(false);
    }
  };

  const handleMeusCarnes = () => {
    if (isMobile) {
      toggleSubMenu(true);
    } else {
      toggleSubMenu(true);
      handleMenuItemClick("Meus Carnês");
    }
  };

  return (
    <Container myItemName={menuItems} activeMenuItem={activeMenuItem}>
      <List sx={{ marginTop: 1 }}>
        {isMobile && (
          <>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    color="#1C1C7B"
                    sx={{ fontWeight: 700, fontSize: 28, margin: "20px 42px" }}
                  >
                    Olá, {name}
                  </Typography>
                </React.Fragment>
              }
            />
            <Divider component="li" sx={{ width: 270 }} />
          </>
        )}

        <ListItemButton
          onClick={() => handleMenuItemClick("Meus Pedidos")}
          sx={{
            fontWeight: 700,
          }}
        >
          <StyledViewInArOutlined active={activeMenuItem === "Meus Pedidos"} />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  data-active={activeMenuItem === "Meus Pedidos"}
                  className="menu-title"
                  variant="subtitle2"
                  color={
                    activeMenuItem === "Meus Pedidos" ? "#F08C10" : "#1C1C7B"
                  }
                  sx={{
                    fontWeight: 700,
                    textTransform: "uppercase",
                  }}
                >
                  Meus Pedidos
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography variant="body1" color="rgba(0, 0, 0, 0.7)">
                  Acompanhe aqui o status
                </Typography>
              </React.Fragment>
            }
          />
          <StyledArrow active={activeMenuItem === "Meus Pedidos"} />
        </ListItemButton>
        <Divider component="li" sx={{ width: 270 }} />
        <ListItemButton
          ref={submenuRef}
          onClick={handleMeusCarnes}
          style={{ height: 80 }}
        >
          <StyledAssignmentOutlined active={activeMenuItem === "Meus Carnês"} />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  data-active={activeMenuItem === "Meus Carnês"}
                  className="menu-title"
                  variant="subtitle2"
                  color={
                    activeMenuItem === "Meus Carnês" ? "#F08C10" : "#1C1C7B"
                  }
                  sx={{ fontWeight: 700, textTransform: "uppercase" }}
                >
                  MEUS CARNÊS
                </Typography>
              </React.Fragment>
            }
          />
          {isSubMenuOpen ? (
            <StyledKeyboardArrowDownIcon
              sx={{ fontSize: "38px" }}
              active={activeMenuItem === "Meus Carnês"}
            />
          ) : (
            <StyledArrow active={activeMenuItem === "Meus Carnês"} />
          )}
        </ListItemButton>
        <Collapse
          in={isSubMenuOpen}
          timeout="auto"
          unmountOnExit
          className="collapse-carnes"
          ref={submenuRef}
        >
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => {
                toggleSubMenu(true);
                handleMenuItemClick("Pagar Parcelas");
              }}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      data-active={activeMenuItem === "Pagar Parcelas"}
                      color={
                        activeMenuItem === "Pagar Parcelas"
                          ? "#F08C10"
                          : "#1C1C7B"
                      }
                      sx={{ fontWeight: 700, textTransform: "uppercase" }}
                    >
                      Pagar Parcelas
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                toggleSubMenu(true);
                handleMenuItemClick("Cadastrar Carnê");
              }}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      data-active={activeMenuItem === "Cadastrar Carnê"}
                      color={
                        activeMenuItem === "Cadastrar Carnê"
                          ? "#F08C10"
                          : "#1C1C7B"
                      }
                      sx={{ fontWeight: 700, textTransform: "uppercase" }}
                    >
                      Cadastrar Carnê
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                toggleSubMenu(true);
                handleMenuItemClick("Resgatar Produtos");
              }}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      className="menu-title"
                      variant="body1"
                      data-active={activeMenuItem === "Resgatar Produtos"}
                      color={
                        activeMenuItem === "Resgatar Produtos"
                          ? "#F08C10"
                          : "#1C1C7B"
                      }
                      sx={{ fontWeight: 700, textTransform: "uppercase" }}
                    >
                      Resgatar Produtos
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                toggleSubMenu(true);
                handleMenuItemClick("Consultar Carnês");
              }}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      className="menu-title"
                      variant="body1"
                      data-active={activeMenuItem === "Consultar Carnês"}
                      color={
                        activeMenuItem === "Consultar Carnês"
                          ? "#F08C10"
                          : "#1C1C7B"
                      }
                      sx={{ fontWeight: 700, textTransform: "uppercase" }}
                    >
                      Consultar Carnês
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider component="li" sx={{ width: 270 }} />
        <ListItemButton
          onClick={() => {
            handleMenuItemClick("Meus Dados");
          }}
        >
          <StyledFileCopyOutlined active={activeMenuItem === "Meus Dados"} />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  className="menu-title"
                  variant="subtitle2"
                  data-active={activeMenuItem === "Meus Dados"}
                  color={
                    activeMenuItem === "Meus Dados" ? "#F08C10" : "#1C1C7B"
                  }
                  sx={{ fontWeight: 700, textTransform: "uppercase" }}
                >
                  MEUS DADOS
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography variant="body1" color="rgba(0, 0, 0, 0.7)">
                  Veja e edite suas informações
                </Typography>
              </React.Fragment>
            }
          />
          <StyledArrow active={activeMenuItem === "Meus Dados"} />
        </ListItemButton>
        <Divider component="li" sx={{ width: 270 }} />
        <ListItemButton
          onClick={() => {
            handleMenuItemClick("Meus Endereços");
          }}
        >
          {/* <StyledIcon
            active={activeMenuItem === "Meus Endereços"}
            src={`${process.env.REACT_APP_IMAGE_SERVER_URL}local-icon.svg`}
            alt="Ícone localização"
          /> */}
          <StyledLocationOnOutlined
            active={activeMenuItem === "Meus Endereços"}
          />
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  className="menu-title"
                  variant="subtitle2"
                  data-active={activeMenuItem === "Meus Endereços"}
                  color={
                    activeMenuItem === "Meus Endereços" ? "#F08C10" : "#1C1C7B"
                  }
                  sx={{ fontWeight: 700, textTransform: "uppercase" }}
                >
                  MEUS ENDEREÇOS
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography variant="body1" color="rgba(0, 0, 0, 0.7)">
                  Cadastre aqui seus endereços
                </Typography>
              </React.Fragment>
            }
          />
          <StyledArrow active={activeMenuItem === "Meus Endereços"} />
        </ListItemButton>
        <ListItem>
          <Button
            onClick={handleLogout}
            sx={{
              width: "228px",
              height: "48px",
              margin: "auto",
              fontSize: "14px",
              background: "#FFFFFF",
              color: "#F08C10",
              border: "1px solid #F08C10",
              "&:hover": {
                background: "#F08C10",
                color: "#FFFFFF",
              },
            }}
          >
            Sair
          </Button>
        </ListItem>
      </List>
    </Container>
  );
};

export default SideBar;
