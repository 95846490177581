import styled from 'styled-components';
import { Grid, Box } from "@mui/material";

export const GridPayment = styled(Grid)`
  padding-top: 30px !important;
`
export const DivPayment = styled.div`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    @media (max-width: 425px) {
      width: 300px;
      margin: auto;
  }
`

export const BoxDivider = styled(Box)`
  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
  text-transform: uppercase;

  justify-content: center !important;
  display: flex !important;

  img {
    margin: 8px;
    display: flex;
    width: 46px;
  }

  p {
    font-family: 'Red Hat Display', sans-serif !important;
    font-weight: 700;
  }
`
export const Copyright = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
  font-family: 'Red Hat Display', sans-serif;
  display: flex;
  justify-content: center;
  padding: 10px;

  span {
    font-family: Red Hat Display,sans-serif!important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #636363 !important;
    text-align: center;
  }
`;

