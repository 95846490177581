export const primeiraSecao = [
    {
        titulo: 'QUEM SOMOS',
        url: '/quem-somos',
        target: '',
    },
    {
        titulo: 'CARNÊ DO BAÚ',
        url: '/sobre-bau',
        target: '',
    },
    {
        titulo: 'PROGRAMAS DE TV',
        url: '/programas-de-tv',
        target: '',
    },
    {
        titulo: 'PRÊMIOS',
        url: '/premios',
        target: '',
    },
    {
        titulo: 'SEJA CONSULTORA',
        url: 'https://institucional.jequiti.com.br/seja-uma-consultora/',
        target: '_blank',
    },
];


export const segundaSecao = [
    {
        titulo: 'JEQUITI',
        url: 'https://www.jequiti.com.br/a-jequiti',
        target: '_blank',
    },
    {
        titulo: 'SBT',
        url: 'https://www.sbt.com.br/',
        target: '_blank',
    },
    {
        titulo: 'LIDERANÇA',
        url: 'https://www.lidercap.com.br/',
        target: '_blank',
    },
    {
        titulo: 'TRABALHE CONOSCO',
        url: 'https://jequiti.gupy.io/',
        target: '_blank',
    },
];

export const terceiraSecao = [
    {
        titulo: 'POLÍTICA DE TROCAS',
        url: '/politicas-legais',
        target: '',
    },
    {
        titulo: 'TERMOS E CONDIÇÕES',
        url: '/politicas-legais',
        target: '',
    },
    {
        titulo: 'PRAZOS DE ENTREGA',
        url: '/politicas-legais',
        target: '',
    },
    {
        titulo: 'POLÍTICA DE PRIVACIDADE',
        url: '/politicas-legais',
        target: '',
    },
];

export const quartaSecao = [
    {
        titulo: 'Fale Conosco',
        url: '/fale-conosco',
        target: '',
    },
    {
        titulo: 'PERGUNTAS FREQUENTES',
        url: '/perguntas-frequentes',
        target: '',
    },

];

export const socialMedia = [
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Facebook.svg`,
        name: 'Facebook',
        url: 'https://www.facebook.com/baudafelicidadejequiti',
        target: '_blank',
    },
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Instagram.svg`,
        name: 'Instagram',
        url: ' https://www.instagram.com/baudafelicidadejequiti/',
        target: '_blank',
    },
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Youtube.svg`,
        name: 'Youtube',
        url: 'https://www.youtube.com/@BaudaFelicidadeJequiti/',
        target: '_blank',
    },
    // {
    //     imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Twitter.svg`,
    //     name: 'Twitter',
    //     url: 'https://twitter.com/BauJequiti',
    //     target: '_blank',
    // },
];

export const payment = [
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Mastercard.svg`,
        name: 'Mastercard',
    },
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Visa.svg`,
        name: 'Visa',
    },
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Diners.svg`,
        name: 'Diners',
    },
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Boleto.svg`,
        name: 'Boleto',
    },
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Elo.svg`,
        name: 'Elo',
    },
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Pix.svg`,
        name: 'Pix',
    },
];

export const securitySelo = [
    {
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}Cybersource.png`,
        name: 'Cybersource',
    },
];

export const menuNav = [
    {
        name: 'Pagar Parcela',
        url: '/',
    },
    {
        name: 'Cadastrar Carnê',
        url: '/'
    },
    {
        name: 'Resgatar produtos',
        url: '/',
    },
];

export const menuLogin = [
    {
        label: 'perfil',
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}perfil.svg`,
        url: '/login',
    },
    {
        label: 'sacola',
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}sacola.svg`,
        url: '',
        badge: true,
    },
];

export const timeCarousel = 3000;


export const delivery = [
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}entrega.png`,
        name: 'entrega',
        title: 'FRETE GRÁTIS',
        text: 'Entregamos seu carnê e/ou resgate com frete grátis para todo o Brasil.',
    },
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}operator.png`,
        name: 'atendimento',
        title: 'ATENDIMENTO AO CLIENTE',
        text: `Atendimento 0800 545 2121. De segunda a sábado: das 8:00 às 22:00 e domingos e feriados: das 9:00 às 21:00.

        WhatsApp ( 11 ) 9 5302 - 0985 `,
    },
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}goback.png`,
        name: 'resgate',
        title: 'RECEBA MAIS DO QUE PAGOU',
        text: 'Pagando 12ª parcelas do carnê (R$ 216), receba R$ 240 em produtos Jequiti.',
    },
];

export const cardDepoimento = [
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}card-depoimento.svg`,
    }
]

export const depoimento = [
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}img-depo-1.svg`,
        name: 'Reginaldo Antonio Correia',
        text: 'Meu pai já ganhou com o carnê do Baú e agora eu também fui sorteado.',
        value: 'R$ 4 mil',
        city: 'Apucarana - PR',
    },
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}img-depo-2.svg`,
        name: 'Maria da Paz',
        text: 'Eu ganhei no meu primeiro carnê um carro 0 km do Báu!',
        value: 'CARRO 0KM',
        city: 'Trindade – GO',
    },
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}img-depo-3.svg`,
        name: 'Juliana Muckler',
        text: 'Eu ganhei R$ 20 mil e ainda ganhei uma viagem de avião para conhecer o SBT!',
        value: 'R$ 20 mil',
        city: 'Paraíso do Sul – RS',
    },
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}img-depo-4.svg`,
        name: 'Eliane de Fatima da Silva Firme',
        text: 'Quando o Baú da Felicidade me ligou quase não acreditei, eu ganhei!',
        value: 'R$ 9 mil',
        city: 'Mantena – MG',
    },
];

export const campanha = [
    {
        // image: `${process.env.REACT_APP_IMAGE_SERVER_URL}/roleta.svg`,
        title: 'Cupom Premiado',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac justo aliquet, blandit mauris non, finibus metus. Etiam vulputate dui id blandit laoreet.',
        url: 'Saiba mais',
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}roleta-03.svg`,
    },
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}roleta-02.svg`,
        title: 'Carro da felicidade',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac justo aliquet, blandit mauris non, finibus metus. Etiam vulputate dui id blandit laoreet.',
        url: 'Saiba mais',
    },
    {
        image: `${process.env.REACT_APP_IMAGE_SERVER_URL}roleta.svg`,
        title: 'Vem aí',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac justo aliquet, blandit mauris non, finibus metus. Etiam vulputate dui id blandit laoreet.',
        url: 'Saiba mais',
    },
];

export const step = [
    {
        label: 'peça aqui',
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}pecaAqui.svg`,
        text: `<p><strong><a target='_blank' href='${process.env.REACT_APP_LINK_KITS}carne-do-bau-da-felicidade-jequiti-2022/p'>Peça aqui</a></strong> o seu carnê. O Baú vai enviá-lo para sua casa.</p>`,
    },
    {
        label: 'cadastre',
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}cadastre.svg`,
        text: `<p><strong><a href='#'>Cadastre aqui</a></strong> o seu carnê para localizarmos você caso for sorteado.</p>`,
    },
    {
        label: 'escolha',
        imagem: `${process.env.REACT_APP_IMAGE_SERVER_URL}escolha.svg`,
        text: `<p><strong><a href='#'>Escolha aqui </a></strong>as suas mercadorias e receba em casa.</p>`,
    },
];