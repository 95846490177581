import React, { FC, useState, useEffect, useContext } from "react";
import { Grid, InputBase, InputLabel, TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import {
  validateName,
  formatCpf,
  validateCpf,
  validateCreditCard,
  validateCVV,
  validateDateCreditCard,
} from "../../utils/utils";
import { CartContext } from "../../context/CartProvider";

const Label = styled(InputLabel)(({ theme }) => ({
  fontFamily: "Red Hat Display, sans-serif",
}));

type Props = {
  setSendPayment: any;
};

const CreditCard: FC<Props> = ({ setSendPayment }) => {
  const [carNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [fullName, setFullName] = useState("");
  const [isError, setIsError] = useState(false);
  const [cpf, setCpf] = useState("");
  const [errorCpf, setErrorCpf] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [check, setCheck] = useState(false);
  const [date, setDate] = useState("");

  const [errorCard, setErrorCard] = useState(false);
  const [errorCvv, setErrorCvv] = useState(false);
  const [errorDateCard, setErrorDateCard] = useState(false);
  const { setDisableButton } = useContext(CartContext)!;

  useEffect(() => {
    const dadosCartao = {
      nomeTitularCartao: fullName,
      numeroCartao: carNumber.replace(/\s/g, ""),
      mesVencimento: date.slice(0, 2),
      anoVencimento: date.slice(3),
      codigoSeguranca: cvv,
    };
    setSendPayment(dadosCartao);
  }, [carNumber, cvv, fullName, date]);

  useEffect(() => {
    const allFieldsAreValid =
      !errorCard &&
      !errorCvv &&
      !errorDateCard &&
      !isError &&
      !errorCpf &&
      carNumber &&
      cvv &&
      fullName &&
      date &&
      cpf;

    setDisableButton(!allFieldsAreValid);
  }, [
    carNumber,
    cvv,
    fullName,
    date,
    errorCard,
    errorCvv,
    errorDateCard,
    isError,
    errorCpf,
    cpf,
    setDisableButton,
  ]);

  const handleCard = (event: any) => {
    const value = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4")
      .slice(0, 19);
    setCardNumber(value);
    setErrorCard(!validateCreditCard(event.target.value));
  };

  const handleInputName = (event: any) => {
    const value = event.target.value.replace(/[0-9]/g, "");
    const values = event.target.value.trim();
    const [firstName, ...rest] = values.split(" ");
    const secondName = rest.join(" ");
    setFirstName(firstName);
    setSecondName(secondName);
    setFullName(value.slice(0, 50));
    setCheck(false);
    setIsError(!validateName(event.target.value));
  };

  const handledate = (event: any) => {
    const value = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{2})/, "$1/$2")
      .slice(0, 5);
    setDate(value);
    setErrorDateCard(!validateDateCreditCard(value));
  };

  const handleCvv = (event: any) => {
    const value = event.target.value.replace(/\D/g, "").slice(0, 3);
    setCvv(value);
    setErrorCvv(value.length === 3 ? false : !validateCVV(value));
  };

  const handleChangeCpf = (event: any) => {
    const value = event.target.value;
    setCpf(value);
    setCheck(false);
    setErrorCpf(!validateCpf(event.target.value) || !event.target.value);
  };

  return (
    <div>
      <div className="flex flex-col gap-3 md:flex-row">
        <div className="w-full md:w-1/2">
          <Label>Número Cartão</Label>
          <TextField
            name="Credit Card"
            value={carNumber}
            onChange={handleCard}
            placeholder="0000 0000 0000 0000"
            required
            fullWidth
            error={errorCard}
            helperText={
              errorCard ? "Por favor, insira um número de cartão válido" : ""
            }
          />
        </div>
        <div className="flex w-full gap-3 md:w-1/2">
          <div>
            <Label>Validade</Label>
            <TextField
              name="Ano"
              placeholder="MM/AA"
              value={date}
              onChange={handledate}
              required
              fullWidth
              error={errorDateCard}
              helperText={errorDateCard ? "Informe uma data válida" : ""}
            />
          </div>
          <div className="w-full ">
            <Label>Cód. Segurança</Label>
            <TextField
              name="CVV"
              placeholder="000"
              value={cvv}
              onChange={handleCvv}
              required
              fullWidth
              error={errorCvv}
              helperText={errorCvv ? "Por favor, insira um CVV válido." : ""}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-9 md:flex-row">
        <div className="w-full">
          <Label>Nome do titular</Label>
          <TextField
            placeholder="Nome impresso no cartão"
            type="text"
            name="name"
            value={fullName}
            onChange={handleInputName}
            required
            fullWidth
            error={isError}
            helperText={isError ? "Nome e sobrenome são obrigatórios" : ""}
          />
        </div>
        <div className="w-full">
          <Label>CPF do titular do cartão</Label>
          <TextField
            name="number"
            placeholder="000.000.000-00"
            value={formatCpf(cpf)}
            onChange={handleChangeCpf}
            error={errorCpf}
            helperText={errorCpf ? "CPF inválido" : null}
            required
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
