import { FC } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import { formatCarneResgatado, formatarValorEmReais } from "../../utils/utils";
import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../DataTable";
import { pagarCarne } from "../../utils/meuscarnes";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useIsMobile } from "../../utils/utils";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#F08C10",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

type Props = {
  view: boolean;
  setView: any;
  handleDrawer: any;
};

const TableCarnes: FC<Props> = ({ view, setView, handleDrawer }) => {
  const isMobile = useIsMobile();
  const handleClick = () => {
    setView(true);
  };
  const renderTypography = (headerName: any) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography
        component="p"
        variant="subtitle1"
        fontWeight={700}
        color="#000000"
        sx={{
          fontSize: "10px",
          textTransform: "uppercase",
        }}
      >
        {headerName}
      </Typography>
    </div>
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Série | Número-DV",
      width: 150,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "valorDisponivel",
      headerName: "Valor Disponível",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "recorencia",
      headerName: "Pagar em Recorrência",
      width: 100,
      sortable: false,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
      disableColumnMenu: true,
      renderCell: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AntSwitch inputProps={{ "aria-label": "ant design" }} />
        </div>
      ),
    },
    {
      field: "consulta",
      headerName: "Consulta Parcelas",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
      renderCell: () => (
        <Typography
          variant="subtitle1"
          onClick={handleClick}
          color="#1C1C7B"
          sx={{
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 700,
            textDecoration: "underline",
          }}
        >
          Detalhes
        </Typography>
      ),
    },
    {
      field: "button",
      headerName: " ",
      width: 115,
      sortable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              width: "105px",
              height: "30px",
              color: "#F08C10",
              fontSize: "10px",
              border: "1px solid #F08C10",
              borderRadius: 0,
              background: "#FFFFFF",
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
          >
            Resgatar Pedido
          </Button>
        </div>
      ),
    },
  ];

  const rows: any[] = pagarCarne.map((row) => ({
    id: formatCarneResgatado(row.codigoBarras),
    tipo: row.tipo,
    valorDisponivel: formatarValorEmReais(parseInt(row.value)),
    status: row.status,
  }));

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "center", md: "left" },
          width: `${isMobile ? "300px" : "100%"}`,
        }}
      >
        <Box
          sx={{
            flexDirection: "column",
            display: `${isMobile && "flex"}`,
            alignItems: `${isMobile && "center"}`,
            justifyContent: `${isMobile && "center"}`,
          }}
        >
          <div>
            <Typography variant="h1">
              {isMobile && (
                <ArrowBackIosIcon
                  onClick={handleDrawer}
                  sx={{ fontSize: "32px" }}
                />
              )}
              Consultar Carnês
            </Typography>
            <Typography
              variant="subtitle1"
              color="#000000"
              sx={{ marginBottom: "20px" }}
            >
              <b>Acompanhe</b> seus carnês, acessando os detalhes. Caso queira{" "}
              <b>Registrar um novo carnê</b>,
              <b style={{ color: "#F08C10" }}> clique aqui. </b>
              <b>Pague</b> seu carnê em recorrência no cartão de crédito, ou
              realize o pagamento individual de suas parcelas.
            </Typography>
          </div>
          <DataTable rows={rows} columns={columns} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TableCarnes;
