import { makeStyles } from "@mui/styles";

interface StyleProps {
  color: string;
}

const useStyles = makeStyles({
  card: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: (props: StyleProps) => props.color,
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
});

export default useStyles;
