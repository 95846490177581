import React from "react";
import CardList from "../../components/CardList";
import CardListMobile from "../../components/CardListMobile";
import ContainerBox from "../../components/ContainerBox";
import { arrDescription } from "../../utils/quemsomos";
import { useIsMobile } from "../../utils/utils";
import { Typography } from "@mui/material";

const QuemSomos = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <ContainerBox
        sx={{ marginTop: { sm: "83px", md: "152px", lg: "172px" } }}
      >
        <Typography variant="h1" sx={{ margin: 0 }}>
          Quem Somos
        </Typography>
        <Typography
          variant="subtitle1"
          color="#000000"
          sx={{
            lineHeight: "22px",
            marginTop: "18px",
          }}
        >
          Conheça nossa história.
        </Typography>
      </ContainerBox>
      {isMobile ? (
        <ContainerBox>
          <CardListMobile arrays={arrDescription} />
        </ContainerBox>
      ) : (
        <CardList arrays={arrDescription} />
      )}
    </>
  );
};

export default QuemSomos;
