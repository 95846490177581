import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import TagManager from "react-gtm-module";

// Função para inicializar o Google Tag Manager
const initializeTagManager = () => {
  const tagManagerArgs = {
    gtmId: "GTM-MM56265",
  };
  TagManager.initialize(tagManagerArgs);
};

initializeTagManager();

const rootElement = document.getElementById("root");

if (rootElement) {
  // Se o elemento existe, cria a raiz do React e renderiza o aplicativo
  const root = ReactDOM.createRoot(rootElement as HTMLElement);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  reportWebVitals();
} else {
  console.error("Elemento root não encontrado no DOM.");
}
