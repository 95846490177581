import { styled } from "@mui/material/styles";

export const Header = styled("div")({
  width: "100%",
  height: "5rem",
  backgroundColor: "#F08C10",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& svg": {
    width: "3rem", // Exemplo de largura para o SVG
    height: "3rem", // Exemplo de altura para o SVG
    fill: "#FFFFFF", // Cor de preenchimento
  },
});

export const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem 4rem",
});
