import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import SetPagination from '../../components/SetPagination'
import { styled } from '@mui/material/styles';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import {
  useTheme,
} from '@mui/material/styles';

type Props = {
  arrays: any[];
};

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    height: '110px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const AccordionList: FC<Props> = ({ arrays }) => {
  const [expanded, setExpanded] = useState<string | false>('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const pageCount = Math.ceil(arrays.length / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const theme = useTheme();

  const handleChange = (open: string, index: number) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? open : false);
    setActiveIndex(newExpanded ? index : null);
  };

  const currentItems = arrays.slice(startIndex, endIndex);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      {currentItems.map((items: any, index: any) => (
        <Accordion
          key={index}
          expanded={expanded === `open${index}`}
          onChange={handleChange(`open${index}`, index)}
        >
          <AccordionSummary
            expandIcon={activeIndex === index ? <RemoveIcon /> : <AddIcon />}
            aria-controls={`panel-contente${index}`}
            id={`panel-header${index}`}
          >
            <Typography
              variant="subtitle1"
              fontWeight={theme.typography.fontWeightBold}
              color="#000000"
            >
              {items.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="subtitle1"
              color="#000000"
              dangerouslySetInnerHTML={{ __html: items.description }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '30px 0',
        }}
      >
        <SetPagination page={page} countPage={pageCount} handle={handleChangePage}  />
      </div>
    </>
  );
};

export default AccordionList;
