import { FC } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Typography, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CarouselArrows } from "./CardListMobile.styled";

type Props = {
  arrays: any[];
};

const CardListMobile: FC<Props> = ({ arrays }) => {
  const navigate = useNavigate();

  const handleClick = (value: string) => {
    const id = value;
    navigate("/regulamento", { state: { id } });
  };

  return (
    <CarouselArrows indicators={false} interval={null} variant="dark">
      {arrays.map((items: any, index: any) => (
        <Carousel.Item key={index} style={{ marginTop: 35 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card
              sx={{
                width: 211,
                height: 200,
                boxShadow: "none",
              }}
            >
              <img
                src={items.image}
                alt={items.label}
                style={{ height: "200px", width: "211px" }}
              />
            </Card>
          </div>
          <CardContent>
            <Typography
              variant="h2"
              color="#000000"
              sx={{
                lineHeight: "24px",
                fontSize: "18px",
              }}
            >
              {items.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="#000000"
              sx={{
                textAlign: "justify",
                margin: "32px 0 92px 0",
                a: {
                  textDecorationLine: "underline",
                  color: "#000000",
                },
              }}
              dangerouslySetInnerHTML={{ __html: items.description }}
            />
            {items.link && (
              <Typography
                variant="subtitle1"
                component="button"
                color="#F08C10"
                onClick={() => handleClick(items.id)}
                sx={{
                  fontWeight: 700,
                  textDecoration: "underline",
                  border: "none",
                  background: "#FFFFFF",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#F08C10",
                  },
                }}
              >
                {items.link}
              </Typography>
            )}
          </CardContent>
        </Carousel.Item>
      ))}
    </CarouselArrows>
  );
};

export default CardListMobile;
