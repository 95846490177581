import React, { useEffect } from "react";

import useStyles from "./styles";
import banner from "../../../../../assets/img/banner.jpeg";
import bannerResponsivo from "../../../../../assets/img/banner-responsivo.png";
import bannerResgateDisponivel from "../../../../../assets/img/banner-resgate-diponivel.png";
import bannerResgateDisponivelResponsivo from "../../../../../assets/img/resgate-available.png";
import bannerCarneResgatado from "../../../../../assets/img/banner-resgatado.png";
import bannerCarneResgatadoResponsivo from "../../../../../assets/img/resgate-done.png";
import bannerDigital from "../../../../../assets/img/banner-digital.png";
import bannerDigitalResponsivo from "../../../../../assets/img/banner-digital-responsivo.png";
import bannerResgateEmAndamento from "../../../../../assets/img/resgate_em_andamento.png";
import bannerResgateEmAndamentoResponsivo from "../../../../../assets/img/resgate_em_andamento_ mobile.png";

import { Box, Card } from "@mui/material";

interface CardImageProps {
  status?: string;
  type?: string;
}
export const CardImage: React.FC<CardImageProps> = ({ status, type }) => {
  const color = "#ffff";
  const classes = useStyles({ color });

  return (
    <Card className={classes.card} style={{ backgroundColor: color }}>
      {type === "Digital" &&
        status !== "Quitado" &&
        status !== "Resgate Iniciado" &&
        status !== "Resgatado" && (
          <>
            <img
              src={banner}
              alt="logo"
              className="hidden min-w-full md:block"
            />
            <img
              src={bannerResponsivo}
              alt="logo"
              className="block h-full min-w-full md:hidden"
            />
          </>
        )}

      {type == "Físico" &&
        status !== "Quitado" &&
        status !== "Resgate Iniciado" &&
        status !== "Resgatado" && (
          <>
            <img
              src={bannerDigital}
              alt="logo"
              className="hidden min-w-full md:block"
            />
            <img
              src={bannerDigitalResponsivo}
              alt="logo"
              className="block h-full min-w-full md:hidden"
            />
          </>
        )}

      {status === "Resgate Iniciado" && (
        <>
          <img
            src={bannerResgateEmAndamento}
            alt="Capa Carnê Digital"
            className="hidden min-w-full md:block"
          />
          <img
            src={bannerResgateEmAndamentoResponsivo}
            alt="logo"
            className="block h-full min-w-full md:hidden"
          />
        </>
      )}

      {status === "Quitado" && (
        <>
          <img
            src={bannerResgateDisponivel}
            alt="Capa Carnê Digital"
            className="hidden min-w-full md:block"
          />
          <img
            src={bannerResgateDisponivelResponsivo}
            alt="logo"
            className="block h-full min-w-full md:hidden"
          />
        </>
      )}

      {status === "Resgatado" && (
        <>
          <img
            src={bannerCarneResgatado}
            alt="Capa Carnê Digital"
            className="hidden min-w-full md:block"
          />
          <img
            src={bannerCarneResgatadoResponsivo}
            alt="logo"
            className="block h-full min-w-full md:hidden"
          />
        </>
      )}
    </Card>
  );
};
