import React, { createContext, useState, useEffect } from "react";
import { Product, CartContextProps, CartProviderProps } from "./types";
import { PaymentCredit, PaymentPix, PaymentInfo, SegundaVia } from "./utils";

export const CartContext = createContext<CartContextProps | undefined>(
  undefined
);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState<Product[]>([]);
  const [payment, setPayment] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [parcelaItem, setParcelaItem] = useState([]);
  const [idToken, setIdToken] = useState([]);
  const storedItems = localStorage.getItem("selectedItems");

  const updateCartFromLocalStorage = () => {
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setCartItems(parsedItems);
    }
  };

  useEffect(() => {
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setCartItems(parsedItems);
    }
    updateCartFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const creditPayment = async (data: any, token: any) => {
    const response = await PaymentCredit(data, token);
    return response;
  };

  const pixPayment = async (data: any, token: any) => {
    const response = await PaymentPix(data, token);
    return response;
  };

  const infoPayment = async (data: any, token: any) => {
    const response = await PaymentInfo(data, token);
    return response;
  };

  const boleto2Via = async (data: any, token: any) => {
    try {
      const response = await SegundaVia(data, token);
      return response;
    } catch (error: any) {
      throw error.error;
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        parcelaItem,
        setParcelaItem,
        payment,
        setPayment,
        creditPayment,
        pixPayment,
        idToken,
        setIdToken,
        updateCartFromLocalStorage,
        infoPayment,
        boleto2Via,
        setDisableButton,
        disableButton,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
