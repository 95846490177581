import { Grid } from "@mui/material";
import ListaFooter from "../ListaFooter";
import {
  primeiraSecao,
  segundaSecao,
  quartaSecao,
  socialMedia,
} from "../../utils/data";
import { politicas } from "../../utils/politicas";
import { Container, RodapeSocialMedia } from "./LinkFooterStyles";

const LinkFooter = () => {
  const renderListaFooter = (secao: any, titulo: any) => (
    <Grid item lg={3} md={3} sm={12} xs={12}>
      {ListaFooter(secao, titulo)}
    </Grid>
  );

  const renderQuartaSecaoComSocialMedia = () => (
    <Grid item lg={3} md={3} sm={12} xs={12}>
      <Container>
        {renderListaFooter(quartaSecao, "ATENDIMENTO")}
        <p className="p-social-media">Acompanhe o Baú nas redes sociais.</p>
      </Container>
      <RodapeSocialMedia>
        {socialMedia.map((url, index) => (
          <a href={url.url} key={index} target={url.target}>
            <img src={url.imagem} alt={url.name} />
          </a>
        ))}
      </RodapeSocialMedia>
    </Grid>
  );

  return (
    <div style={{ margin: "50px 0", display: "block" }}>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {renderListaFooter(primeiraSecao, "Sobre Baú")}
        {renderListaFooter(segundaSecao, "GRUPO SILVIO SANTOS")}
        {renderListaFooter(politicas, "POLÍTICAS LEGAIS")}
        {renderQuartaSecaoComSocialMedia()}
      </Grid>
    </div>
  );
};

export default LinkFooter;
