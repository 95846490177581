import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";

export const CarouselArrows = styled(Carousel)`
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .carousel-indicators {
    top: 100%;
    margin: 12px;
    background-color: #ebedf1;
    button {
      height: 6px;
      width: 39px;
      border-radius: 22px;
      border: 8px;
    }
    button[aria-current="true"] {
      background-color: #f08c10;
    }

    button[aria-current="false"] {
      background-color: #d9d9d9;
    }
  }
`;
