import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardDetails: {
      width: "100%",
      height: "100%",
      backgroundColor: "#F8F8F8",
      padding: "15px 30px 15px 30px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme?.breakpoints?.down("sm")]: {
        height: "14rem",
      },
    },

    title: {
      padding: 0,
      margin: 0,
      fontWeight: 700,
      fontSize: 10,
    },

    subTitle: {
      color: "#000",
      fontFamily: "Red Hat Display, sans-serif",
      fontWeight: 500,
    },

    number: {
      padding: 4,
      margin: 0,
      border: "1px solid #F08C10",
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 30,
      "& p": {
        margin: 0,
        padding: 0,
      },
    },
    status: {
      margin: 0,
      border: "1px solid #F08C10",
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // height: 30,
    },
    containerNumbers: {
      display: "flex",
      gap: 10,
    },

    main: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    text: {
      padding: "2px 10px",
      border: "1px solid #F08C10",
      borderRadius: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& p": {
        margin: 0,
        padding: 0,
        color: "#F08C10",
      },
    },

    details: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      "& p": {
        margin: 0,
        padding: 0,
        fontWeight: 700,
        color: "#F08C10",
        textDecoration: "underline",
      },
    },
    buy: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F08C10",
      borderRadius: 50,
      padding: "3px 10px",
      "& p": {
        color: "#FFFFFF",
        margin: 0,
        padding: 0,
      },
    },
    arrow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #293EA8",
      width: 40,
      borderRadius: 5,
    },
  })
);

interface CustomButtonProps {
  disabled: boolean;
}

export const CustomButton = styled.button<CustomButtonProps>`
  background-color: ${(props) => (props.disabled ? "#BDBDBD" : "#F08C10")};
  border-radius: 15px;
  border: none;
  color: #fff;
  padding: 10px;
  line-height: 10px;
`;
