export { default as MainContent } from "./MainContent";
export { default as MinhaConta } from "./MinhaContaPerfil";
export { default as MeusPedidos } from "./MeusPedidos";
export { default as MeusCarnes } from "./MeusCarnes";
export { default as TablePedidos } from "./TablePedidos";
export { default as MeusEnderecos } from "./MeusEnderecos";
export { default as MeusDados } from "./MeusDados";
export { default as RegistrarCarne } from "./RegistrarCarne";
export { default as ResgatarProdutos } from "./ResgatarProdutos";
export { default as PagarCarne } from "./PagarCarne";
export { default as Parcelas } from "./Parcelas";
export { default as TableCarnes } from "./TableCarnes";
export { default as DataTableCarnes } from "./DataTableCarnes";
