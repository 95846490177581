import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Container } from "./CustomModal.styled";

interface CustomModalProps {
  show: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  body?: React.ReactNode;
  footerButtons?: React.ReactNode;
  showFooter?: boolean;
  customWidth?: string;
  customHeight?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  show,
  onClose,
  title = "Titulo",
  body = "Conteúdo do modal",
  footerButtons,
  showFooter = true,
  customWidth = "500px",
  customHeight = "auto",
}) => {
  return (
    <>
      <Container
        show={show}
        onHide={onClose}
        centered
        customWidth={customWidth}
        customHeight={customHeight}
      >
        <Modal.Header style={{ borderBottom: "none" }} closeButton />
        <Modal.Body className="modal-body">
          <p>{title}</p>
          <p>{body}</p>
        </Modal.Body>
        {showFooter && (
          <Modal.Footer className="modal-footer">
            {footerButtons || (
              <Button variant="secondary" onClick={onClose}>
                Fechar
              </Button>
            )}
          </Modal.Footer>
        )}
      </Container>
    </>
  );
};

export default CustomModal;
