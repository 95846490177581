export const question = [
  {
    question: 'Onde compro o carnê do Baú da Felicidade Jequiti?',
    description: `
      <p>
        Você poderá comprar o seu carnê do Baú da Felicidade Jequiti por meio:
      </p>
      <p>
        <strong>Consultoras Jequiti:</strong>  Comprando o carnê com um(a) consultor(a), 
        o carnê vem com a 1ª parcela paga. O cliente precisa pagar o valor referente a 1ª parcela ao consultor(a).
      </p>
      <p>
        <strong>Casas Lotéricas:</strong> Nas lotéricas o cliente paga a 1ª parcela no ato da compra.
      </p>
      <p>
        <strong>Agências dos Correios:</strong> Nos Correios o cliente paga a 1ª parcela no ato da compra. 
      </p>
      <p>
        <strong>Lojas Pernambucanas:</strong> Na Pernambucanas o cliente paga a 1ª parcela no ato da compra.
      </p>
      <p>
        <strong>Lojas Lebes (Rio Grande do Sul):</strong> Na Lojas Lebes o cliente paga a 1ª parcela no ato da compra. 
      </p>
      <p>
        <strong>Central de atendimento:</strong> 0800 545 2121. Seu carnê já vai cadastrado ao CPF.
      </p>
      <p>
        <strong>Por meio do site:</strong> <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a> e o carnê já vai cadastrado ao CPF.
      </p>
      <p>
        <strong>WhatsApp Oficial do Baú:</strong> <a href='https://wa.me/11953020985' target='_blank'>(11) 95302 0985</a> e o carnê já vai cadastrado ao CPF.
      </p>
      <p>
        <strong>Importante:</strong> é necessário vincular o carnê do Baú ao nome do comprador no site do Baú, ou por meio dos canais oficiais 
        de atendimento, quando a compra for realizada em alguma rede de varejo credenciada.
      </p>
    `,
  },
  {
    question: 'Recebi meu carnê do Baú da Felicidade Jequiti. O que devo fazer?',
    description: `
      <p>
        Se você comprou seu carnê do Baú da Felicidade Jequiti com um Consultor(a), nas <strong>Casas Lotéricas</strong>, nas <strong>Agências dos Correios</strong>, nas 
        <strong>Lojas Pernambucanas</strong> ou <strong>Lojas Lebes</strong> você precisará <strong>fazer o cadastro do carnê</strong> nos seguintes canais:
      </p>
      <p>
        - <strong>WhatsApp:</strong> <a href='https://wa.me/11953020985' target='_blank'>(11) 95302 0985</a><br><br>

        - <strong>Central de atendimento:</strong> 0800 545 2121<br><br>

        - <strong>Site:</strong> <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a><br><br>

        - Você pode preencher a folha de cadastro que vem no carnê e enviar para a <strong>Caixa Postal 05909-960</strong> ou depositar nas urnas disponíveis nas Lotéricas, 
        Correios, Pernambucanas e Lojas Lebes.<br>
      </p>
      <p>
        Se você comprou o seu carnê <strong>pelo site</strong>, ou <strong>pelo 0800, WhatsApp, NÃO</strong> é necessário fazer o cadastro, pois já está vinculado ao seu CPF.
      </p>
    `,
  },
  {
    question: 'Por que é importante fazer o cadastro do carnê do Baú da Felicidade Jequiti?',
    description: `
      <p>
        Se você realizar o pagamento do seu carnê e por algum motivo você não realizar o resgate dos produtos Jequiti, 
        e se seu carnê estiver próximo do vencimento, a Jequiti poderá enviar ao seu endereço um kit feminino ou masculino 
        correspondente ao valor proporcional que você pagou, mediante tabela constante no regulamento no próprio carnê 
        (resgate parcial), preservando o seu direto.
      </p>
      <p>
        <strong>Importante:</strong><br>
        - Ao se cadastrar pelo site, o cliente receberá uma senha automaticamente. Esta senha poderá ser utilizada para acompanhamento 
        das baixas dos pagamentos (extrato) ou para realizar o resgate após a quitação do carnê.<br><br>
        
        - O envio do cadastro pela caixa postal ou pelas urnas dependerá da disponibilidade do cadastramento interno.<br><br>
        
        - O cadastro dos dados pessoais e de endereço é feito uma única vez, mas a cada carnê adquirido, você deverá solicitar o "vínculo" 
        do novo carnê ao seu CPF, ou seja, você deve informar ao sistema que aquele novo carnê também é seu.<br><br>
        
        - Se você solicitou um carnê pelo 0800, ou site Baú, o cadastro dos seus dados ou o "vínculo" do carnê não se faz necessário porque para 
        enviarmos o carnê pelos Correios precisaremos cadastrá-lo (a) e o vínculo ao seu CPF ocorrerá automaticamente.
        
      </p>
    `,
  },
  {
    question: 'Como saberei se fui sorteado?',
    description: `
      <p>
        Se você for sorteado para participar de algum programa do SBT, um colaborador Jequiti entrará em contato com você por telefone, 
        por isso é importante manter sempre os dados cadastrais atualizados.
      </p>
      <p>
        Se você <strong>consultor(a)</strong> for sorteada, a equipe de Prêmios enviará um e-mail para sua Gerente de Vendas, que em seguida ligará para você.
      </p>
      <p>
        Por isso é importantíssimo que você faça o cadastro do seu carnê e mantenha os seus dados sempre atualizados.
      </p>
    `,
  },
  {
    question: 'Terminei de pagar meu carnê, como resgatar os produtos?',
    description: `
      <p>
        Você deverá solicitar o resgate dos seus produtos por meio:<br>
        - <strong>Site:</strong> <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a><br>
        - <strong>WhatsApp:</strong> <a href='https://wa.me/11953020985' target='_blank'>(11) 95302 0985</a><br>
        - <strong>Central de Atendimento do Baú:</strong> 0800 545 2121<br>
        <strong>Importante:</strong> Você pode optar por KITS exclusivos ou selecionar seus produtos de acordo com a sua escolha.<br> 
        Tenha em mãos no momento do resgate seu CPF, o seu carnê o os códigos dos produtos ou KITs que deseja resgatar.
      </p>
    `,
  },
  {
    question: 'Qual a validade do carnê do Baú da Felicidade Jequiti?',
    description: `
      <p>
        A validade do carnê é de 12 meses, a contar da data do pagamento da primeira parcela.
      </p>
    `,
  },
  {
    question: 'Onde eu pago o meu carnê do Baú da Felicidade Jequiti?',
    description: `
      <p>
        Você poderá efetuar o pagamento do seu carnê nas <strong>Casas Lotéricas, Agências dos Correios, Lojas Pernambucanas, 
        Lojas Lebes</strong>, no <strong>site do Baú:</strong> <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a> 
        <strong>com seu cartão de Crédito</strong> e por meio do <strong>PIX</strong> presente em todas as parcelas.
      </p>
    `,
  },
  {
    question: 'Se eu programar o pagamento do meu carnê do Baú da Felicidade Jequiti com Cartão de Crédito, isso vai comprometer meu limite?',
    description: `
      <p>
        Não, será debitado mensalmente apenas R$ 18,00 e o processo de recorrência não comprometerá o limite do seu cartão.<br><br>        
        Você pode cancelar o pagamento mensal quando quiser ligando na central de atendimento 0800 545 2121.
      </p>
    `,
  },
  {
    question: 'Posso pagar meu carnê do Bau da Felicidade Jequiti com Cartão de Débito?',
    description: `
      <p>
        Não, o carnê do Baú da Felicidade Jequiti é considerado uma poupança popular programada, por isso o código de barras não é cadastrado na FEBRABAN 
        é a FEBRABAN que autoriza que os códigos de barras sejam pagos por meio de internet banking.
      </p>
      <p>
        Para sua comodidade o BAÚ criou o PIX para pagamento das parcelas por meio de QR Code. Este código se encontra dentro de todos os carnês em todas 
        as parcelas a partir de dezembro de 2022.
      </p>
    `,
  },
  {
    question: 'O valor dos produtos para resgate é referente ao episódio atual?',
    description: `
      <p>
        Sim, os produtos são atualizados no sistema assim que ocorrer a troca do episódio.
      </p>
    `,
  },
  {
    question: 'Quando é feito a retirada dos cupons nas urnas das Casas Lotéricas, Agências dos Correios, Lojas Pernambucanas e Lojas Lebes?',
    description: `
      <p>
        <strong>Lotéricas: </strong>O distribuidor de cada região faz a coleta dos cupons dentro de uma determinada periodicidade e encaminha para o SBT e estes 
        cupons são depositados nas urnas B A U presentes nos programas de TV do SBT.
      </p>
      <p>
        <strong>Correios: </strong>Os Correios tem um procedimento interno, onde cada agência envia os cupons para o centro de distribuição deles, o Baú faz a 
        retirada e deposita estes cupons nas urnas B A U presentes nos programas de TV do SBT. 
      </p>
      <p>
        <strong>Pernambucanas: </strong>A coleta é efetuada mensalmente, os cupons seguem para o SBT e estes cupons são depositados nas urnas B A U presentes 
        nos programas de TV do SBT. 
      </p>
      <p>
        <strong>Lojas Lebes:</strong> A coleta é efetuada mensalmente, os cupons seguem para o SBT e estes cupons são depositados nas urnas B A U presentes 
        nos programas de TV do SBT.
      </p>
    `,
  },
  {
    question: 'Quanto tempo os cupons ficam dentro da urna para sorteio?',
    description: `
      <p>
        Os cupons ficam nas urnas B A U por 1 ano.
      </p>
    `,
  },
  {
    question: 'O carnê do Baú da Felicidade Jequiti que vem dentro da caixa do resgate precisa ser cadastrado?',
    description: `
      <p>
        Não, o carnê que vai dentro da caixa do resgate já está vinculado ao CPF do cliente que solicitou o resgate.
      </p>
    `,
  },
  {
    question: 'Qualquer pessoa pode comprar o carnê do Baú da Felicidade Jequiti? Existe alguma restrição?',
    description: `
      <p>
        Sim, qualquer pessoa pode comprar o carnê do Baú da Felicidade Jequiti, desde que more dentro do território nacional, 
        exceto os colaboradores da Jequiti e menores de 18 anos.
      </p>
    `,
  },
  {
    question: 'Quantas parcelas tem o carnê do Baú da Felicidade Jequiti?',
    description: `
      <p>
        O carnê do Baú da Felicidade Jequiti tem 12 parcelas iguais no valor de R$ 18,00 cada.<br>
        Existe a 13ª parcela onde explicamos melhor no item 19 desta FAQ.      
      </p>
    `,
  },
  {
    question: 'O que é considerado "pagamento em dia"?',
    description: `
      <p>
        É considerado "pagamento em dia" quando você paga suas parcelas todos os meses, entre o dia 1 e 31 de cada mês.
      </p>
    `,
  },
  {
    question: 'Quando devo enviar os cupons para participar dos programas?',
    description: `
      <p>
        Sempre que você pagar a parcela do mês.<br><br>
        Todas as vezes que você efetuar os pagamentos da sua parcela, você 
        deverá depositar imediatamente os seus cupons devidamente preenchidos 
        nas urnas que estão presentes nas Casas Lotéricas, Agências dos Correios, 
        Lojas Pernambucanas e Lojas Lebes.
      </p>
    `,
  },
  {
    question: 'Como eu envio os cupons para participar dos programas?',
    description: `
      <p>
        Você poderá depositar seus cupons nas urnas disponíveis nas Casas Lotéricas, Agências dos Correios, 
        Lojas Pernambucanas e Lojas Lebes. Se preferir, poderá enviar os cupons pelos Correios na Caixa Postal 05909-960.
      </p>
    `,
  },
  {
    question: 'Para que serve a 13ª Parcela?',
    description: `
      <p>
        A 13ª parcela serve para complementar o valor do seu resgate.
      </p>
      <p>
        Se no momento que você for fazer o seu resgate você se encantar com os nossos produtos e 
        quiser incluir mais itens e seu valor ultrapassar o saldo de R$ 240,00, você poderá pagar a
         diferença na 13ª parcela nas Casas Lotéricas, Agências dos Correios, Lojas Pernambucanas, 
         Lojas Lebes e também por meio do cartão de crédito ou PIX no site do Baú.
      </p>
      <p>
        Pagando cada uma das 12 parcelas no valor de R$18,00 você terá pago no total R$216,00 e o Baú 
        disponibilizará mais R$24,00 de bônus totalizando R$ 240,00 para resgatar em ótimos produtos Jequiti.
      </p>
    `,
  },
  {
    question: 'Eu posso quitar o meu carnê de uma única vez?',
    description: `
      <p>
        Sim, basta você ir até uma Casa Lotérica, Agência dos Correios, Loja Pernambucanas, 
        Lojas Lebes ou pagar com o seu cartão de crédito no site do Baú, <a href='https://bau.jequiti.com.br/' target='_blank'>www.bau.jequiti.com.br</a>.
        Dica: É possível pagar as 12 mensalidades de uma única (R$240,00) vez através da 13ª parcela.      
      </p>
    `,
  },
  {
    question: 'Se eu pagar todas as parcelas de uma única vez, já posso realizar o resgate?',
    description: `
      <p>
        Sim, após 24 horas do pagamento você já pode fazer o resgate dos produtos (veja item 5).
      </p>
    `,
  },
  {
    question: 'Posso resgatar por meio de um(a) consultor(a) Jequiti?',
    description: `
      <p>
        <strong>Não</strong>, o resgate deverá ser feito por meio dos nossos canais de atendimento de resgate (veja item 5)
      </p>
    `,
  },
  {
    question: 'Eu pago frete para receber os meus produtos de resgate?',
    description: `
      <p>
        Após você efetuar o pagamento das 12 mensalidades do seu carnê do Baú da Felicidade Jequiti você 
        <strong>NÃO</strong> pagará nenhuma taxa de frete para receber os produtos de resgate.
      </p>
    `,
  },
  {
    question: 'Se eu desistir de pagar o carnê do Baú da Felicidade Jequiti eu recebo o valor que paguei em produtos?',
    description: `
      <p>
        Se por algum motivo você desistir de pagar o Carnê, você terá direito a resgatar um valor parcial, 
        conforme tabela abaixo:
      </p>
      <img src="${process.env.REACT_APP_IMAGE_SERVER_URL}tabela_frete.svg" alt="search" />
    `,
  },
  {
    question: 'Qual é o valor do frete que vou pagar se eu desistir entre a 3ª e a 9ª parcela?',
    description: `
      <p>
        Para calcular o frete do seu resgate parcial entre em contato com a central de atendimento 0800 545 2121.
      </p>
    `,
  },
  {
    question: 'Eu posso utilizar parte do valor do resgate para pagar o frete?',
    description: `
      <p>
        Não, você deverá resgatar o valor disponível em produtos. O valor do frete para a desistência entre a 3ª e 9ª
        parcela deverá ser pago a parte.
      </p>
    `,
  },
  {
    question: 'Eu posso utilizar a 13ª parcela para pagar o valor do frete?',
    description: `
      <p>
        Não, a 13ª parcela é exclusiva para complementar o pedido de resgate se você desejar mais produtos que o 
        valor do carnê permite. O valor do frete para a desistência entre a 3ª e 9ª parcela <u>deverá ser pago a parte</u>.
      </p>
    `,
  },
  {
    question: 'Como faço para realizar o pagamento do frete se eu desistir do carnê entre a 3ª e a 9ª parcela?',
    description: `
      <p>
        Você deverá entrar em contato com a nossa central de atendimento do Baú: <b>0800 545 2121</b> 
        e solicitar as orientações para o depósito do valor correspondente à sua região.
      </p>
      <p>
        Após realizar o depósito, enviar o comprovante para o e-mail <a href="mailto:comprovantesbau@jequiti.com.br">comprovantesbau@jequiti.com.br</a>, 
        informando seu nome, CPF e para qual Carnê é o valor depositado, Série + carnê + digito.
      </p>
    `,
  },
  {
    question: 'Se eu for sorteado para participar dos programas, tenho alguma despesa?',
    description: `
      <p>
        Não, todas as despesas de viagem, hospedagem e alimentação são por conta do Baú da Felicidade Jequiti.
      </p>
    `,
  },
  {
    question: 'Se eu for sorteado e não puder comparecer, posso enviar um representante?',
    description: `
      <p>
        Sim, você poderá enviar um representante. No momento do contato da central de prêmio do Baú, 
        você deverá informar quem é a pessoa que irá <u>representa-lo (a)</u>. O atendente dará a orientação 
        de como deverá proceder e que documentos deverá apresentar.
      </p>
    `,
  },
  {
    question: 'Se eu perder o carnê do Baú da Felicidade Jequiti e solicitar a segunda via da parcela, eu recebo também os cupons para sorteio?',
    description: `
      <p>
        Se perder o carnê do BAÚ, você deverá ligar na central de atendimento 0800 545 2121, informar o extravio ou 
        perda e fazer uma solicitação de um novo carnê.
      </p>
    `,
  },
  {
    question: 'Se eu pagar o carnê do Baú da Felicidade Jequiti com o Cartão de Crédito e PIX, é necessário o envio do cupom físico?',
    description: `
      <p>
        Não, a equipe do Baú cuidará da impressão e envio do cupom para as urnas do B A U.<br><br>
        Ainda ficou com alguma dúvida? Ligue 0800 545 2121 (ligação gratuita)
      </p>
    `,
  },
  {
    question: 'Se eu for sorteado meus cupons são descartados?',
    description: `
      <p>
        Não. Eles voltam para a urna para que você participe dos próximos sorteios. Cada cupom tem validade de 1 ano.
      </p>
    `,
  },
]