import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
  InputBase,
  Typography,
  SelectChangeEvent,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ItemBox from "../ItemBox";
import { StyledBadge, StyledMenu, UserName } from "./NavBarStyle";
import ContainerBox from "../../components/ContainerBox";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { CartContext } from "../../context/CartProvider";
import MiniCart from "../../components/MiniCart";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { userStore } from "../../store/user/user";

const Navbar = () => {
  const auth = useAuth();
  const [validate, setValidate] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const theme = useTheme();
  const [quantity, setQuantity] = useState("1");
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const { cartItems, setCartItems, updateCartFromLocalStorage } =
    useContext(CartContext)!;
  const { clearUser } = userStore();

  const { handleSideBar, toggleSubMenu, setActiveMenuItem } =
    useHandleSideBar();
  const token = localStorage.getItem("backOfficeId");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenRedirect, setTokenRedirect] = useState("");
  const user = auth?.user;

  useEffect(() => {
    if (token) setValidate(true);
  }, [token]);

  const handleSearch = () => {
    const id = searchTerm;
    navigate("/perguntas-frequentes", { state: { id } });
    setIsSearching(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !isSearching) {
      event.preventDefault();
      setIsSearching(true);
      handleSearch();
      setSearchTerm("");
    }
  };

  const handleMiniCartOpen = () => {
    setIsCartOpen(true);
  };

  const handleLogoffMagento = (item: string) => {
    window.open(`${item}`);
  };

  const handleLogout = () => {
    validate ? handleLogoutBackOffice() : handleLogoutClient();
  };

  const handleLogoutClient = () => {
    auth?.logout();
    clearUser();
    setCartItems([]);
  };

  const handleLogoutBackOffice = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedItems");
    localStorage.removeItem("dataClient");
    setCartItems([]);
    clearUser();
    navigate("/autenticar/login");
  };

  const handleMiniCartClose = () => {
    setIsCartOpen(false);
  };

  const removeItem = (productId: number) => {
    setIsCartOpen(true);
    const updatedCart = cartItems.filter((item: any) => item.id !== productId);
    setCartItems(updatedCart);
    localStorage.setItem("selectedItems", JSON.stringify(updatedCart));
    updateCartFromLocalStorage();
    setAlertMessage("Item removido com sucesso!");
    setAlertStatus("success");
    setAlertOpen(true);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setQuantity(event.target.value);
  };

  const handleNavButtonClick = (buttonName: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click",
      click_category: "header",
      click_text: buttonName,
    });
  };

  const handleClick = (nameMenuItem: string) => {
    handleNavButtonClick(nameMenuItem);
    validate ? navigate("/autenticar/minha-conta") : navigate("/minha-conta");
    setActiveMenuItem(nameMenuItem);
    handleSideBar(nameMenuItem);
  };

  const menuNav = [
    {
      id: "menu-pagar-parcelas",
      name: "Pagar Parcelas",
      url: () => handleClick("Pagar Parcelas"),
    },
    {
      id: "menu-cadastrar-carne",
      name: "Cadastrar Carnê",
      url: () => {
        handleClick("Cadastrar Carnê");
      },
    },
    {
      id: "menu-resgatar-produtos",
      name: "Resgatar Produtos",
      url: () => {
        handleClick("Resgatar Produtos");
      },
    },
    ...(user
      ? [
          {
            id: "menu-consultar-carnes",
            name: "Consultar Carnês",
            url: () => {
              handleClick("Consultar Carnês");
            },
          },
        ]
      : []),
    {
      id: "menu-meus-cupons",
      name: "Meus Cupons",
      url: () => {
        handleClick("Meus Cupons");
      },
    },
  ];

  useEffect(() => {
    const tokenRedirect = localStorage.getItem("token");
    if (tokenRedirect) {
      handleDescript(tokenRedirect);
      setTokenRedirect(tokenRedirect);
    }
  }, []);

  const handleDescript = async (item: any) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(item);
        handleData(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleData = async (token: any) => {
    const login = token.login;
    try {
      if (auth) {
        const request = await auth?.searchClient(login);
        await setDataUser(request, login);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleEncrypt = async (cpf: any, nascimento: any) => {
    const data = {
      cpf: cpf,
      nascimento: nascimento,
    };
    try {
      if (auth) {
        const request = await auth?.encryptToken(data);
        handleDataUser(request);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleDataUser = (data: any) => {
    localStorage.removeItem("dataClient");
    localStorage.setItem("dataClient", data);
  };

  const setDataUser = async (inputDate: any, login: any) => {
    const dateObj = new Date(inputDate.nascimento);
    const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateObj.getDate().toString().padStart(1, "0")}`;

    await handleEncrypt(login, formattedDate);
  };

  const handleMenuClick = (itemMenu: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click",
      click_category: "header",
      click_text: itemMenu,
    });
    // if (itemMenu === "carne digital") {
    //   window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti-2024-digital.html`;
    // } else if (itemMenu === "carne fisico") {
    //   window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`;
    // }
    const tokenRedirectMagento = localStorage.getItem("dataClient");
    if (tokenRedirectMagento !== null && tokenRedirectMagento !== "") {
      if (itemMenu === "carne digital") {
        window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti-2024-digital.html?token=${tokenRedirectMagento}`;
      } else if (itemMenu === "carne fisico") {
        window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html?token=${tokenRedirectMagento}`;
      }
    } else {
      if (itemMenu === "carne digital") {
        window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti-2024-digital.html`;
      } else if (itemMenu === "carne fisico") {
        window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`;
      }
    }
  };

  return (
    <>
      <div style={{ marginBottom: "180px" }}>
        <AppBar
          elevation={0}
          sx={{
            backgroundColor: "#1c1c7b",
            minHeight: "180px",
            position: "fixed",
            top: 0,
            zIndex: 1000,
          }}
        >
          <MiniCart
            open={isCartOpen}
            arrCart={cartItems}
            remove={removeItem}
            quantity={quantity}
            change={handleChange}
            changeClose={handleMiniCartClose}
          />
          <ContainerBox sx={{ width: "1159px", margin: "auto auto 5px auto" }}>
            <Toolbar
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "-webkit-box" },
                }}
              >
                <a href="/">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_SERVER_URL}logoJequiti.svg`}
                    alt="logo-jequiti"
                    style={{
                      height: "48px",
                      width: "138px",
                      marginRight: "25px",
                    }}
                  />
                </a>
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    justifyContente: "center",
                    alignItems: "center",
                    minWidth: 710,
                    height: 42,
                    margin: "0px 20px",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1, fontStyle: "italic" }}
                    placeholder="O que você procura?"
                    inputProps={{ "aria-label": "search-input" }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                <PopupState variant="popover" popupId="popup-login">
                  {(popupLogin) => (
                    <React.Fragment>
                      <div style={{ display: "flex", marginRight: 15 }}>
                        <IconButton color="inherit" id="user-header-link">
                          <PersonOutlineOutlinedIcon
                            {...bindTrigger(popupLogin)}
                            sx={{
                              marginLeft: 0.5,
                              fontSize: 35,
                            }}
                          />
                        </IconButton>
                        <UserName> {user && "Olá, " + user.nome} </UserName>
                      </div>
                      <Menu
                        {...bindMenu(popupLogin)}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            backgroundColor: "#F08C10",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              backgroundColor: "#F08C10",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        {auth?.isAuthenticated() ? (
                          <div>
                            <MenuItem onClick={popupLogin.close}>
                              <Typography
                                component="a"
                                href={`${
                                  validate
                                    ? "/autenticar/minha-conta"
                                    : "/minha-conta"
                                }`}
                                variant="subtitle2"
                                color="#FFFFFF"
                                sx={{
                                  textDecoration: "none",
                                  "&:hover": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                Minha Conta
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                popupLogin.close();
                                handleLogout();
                              }}
                            >
                              <Typography
                                component="a"
                                variant="subtitle2"
                                color="#FFFFFF"
                                sx={{
                                  textDecoration: "none",
                                  "&:hover": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                Sair
                              </Typography>
                            </MenuItem>
                          </div>
                        ) : (
                          <MenuItem onClick={popupLogin.close}>
                            <Typography
                              id="login-header-link"
                              component="a"
                              href={`${
                                validate ? "/autenticar/login" : "/login"
                              }`}
                              variant="subtitle2"
                              color="#FFFFFF"
                              sx={{
                                textDecoration: "none",
                                "&:hover": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              Faça <strong>login </strong>e aproveite todos
                              <br /> os benefícios do Baú.
                            </Typography>
                          </MenuItem>
                        )}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                <IconButton
                  aria-label="sacola"
                  color="inherit"
                  onClick={handleMiniCartOpen}
                >
                  <StyledBadge
                    badgeContent={cartItems.length}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ShoppingCartOutlinedIcon
                      sx={{
                        fontSize: 35,
                      }}
                    />
                  </StyledBadge>
                </IconButton>
              </Box>
            </Toolbar>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8px",
                paddingBottom: 0,
              }}
            >
              <ItemBox>
                <PopupState variant="popover" popupId="popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <StyledMenu>
                        <Typography
                          className="menu-item c-carne"
                          id="menu-comprar-carne"
                          variant="subtitle2"
                          color="#FFFFFF"
                          fontWeight={theme.typography.fontWeightBold}
                          sx={{
                            fontSize: "12px",
                            "&:hover": {
                              color: "#F08C10",
                            },
                          }}
                          {...bindTrigger(popupState)}
                        >
                          Comprar Carnê
                        </Typography>
                      </StyledMenu>
                      <Menu
                        {...bindMenu(popupState)}
                        PaperProps={{
                          style: {
                            backgroundColor: "#F08C10",
                          },
                        }}
                      >
                        <MenuItem>
                          <Typography
                            id="submenu-carne-fisico"
                            component="span"
                            onClick={() => handleMenuClick("carne fisico")}
                            variant="subtitle2"
                            color="#FFFFFF"
                            fontWeight={theme.typography.fontWeightBold}
                            sx={{
                              fontSize: "12px",
                              textDecoration: "none",
                              "&:hover": {
                                color: "#ffff",
                              },
                            }}
                          >
                            Comprar carnê físico
                          </Typography>
                        </MenuItem>
                        <MenuItem>
                          <Typography
                            id="submenu-carne-digital"
                            component="span"
                            onClick={() => handleMenuClick("carne digital")}
                            variant="subtitle2"
                            color="#FFFFFF"
                            fontWeight={theme.typography.fontWeightBold}
                            sx={{
                              fontSize: "12px",
                              textDecoration: "none",
                              "&:hover": {
                                color: "#ffff",
                              },
                            }}
                          >
                            Comprar carnê digital
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </ItemBox>
              {menuNav.map((menu: any, index: any) => (
                <ItemBox key={index}>
                  <StyledMenu>
                    <Typography
                      className="menu-item"
                      id={menu.id}
                      component="a"
                      href={menu.url}
                      variant="subtitle2"
                      color="#FFFFFF"
                      fontWeight={theme.typography.fontWeightBold}
                      sx={{
                        textDecoration: "none",
                        fontSize: "12px",
                        "&:hover": {
                          color: "#F08C10",
                        },
                      }}
                      onClick={() => {
                        handleClick(menu.name);
                      }}
                    >
                      {menu.name}
                    </Typography>
                  </StyledMenu>
                </ItemBox>
              ))}
            </div>
          </ContainerBox>
        </AppBar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={alertOpen}
          autoHideDuration={5000}
          onClose={() => setAlertOpen(false)}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertOpen(false)}
            severity={alertStatus || "info"}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default Navbar;
