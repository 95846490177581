import React from "react";
import CardListProgramas from "../../components/CardListProgramas";
import CardListMobile from "../../components/CardListMobile";
import { useIsMobile } from "../../utils/utils";
import { programas } from "../../utils/programas";
import ContainerBox from "../../components/ContainerBox";
import { Typography } from "@mui/material";

const Programas = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <hr
        style={{
          width: "100%",
          border: "none",
          height: "1px",
          backgroundColor: "#000",
          position: "absolute",
          marginTop: `${isMobile ? "120px" : "83px"}`,
        }}
      />
      <ContainerBox
        sx={{
          marginTop: { sm: "83px", md: "172px", lg: "172px" },
        }}
      >
        <Typography variant="h1" sx={{ margin: 0 }}>
          Programas de TV
        </Typography>
        <Typography
          variant="subtitle1"
          color="#000000"
          sx={{
            lineHeight: "22px",
            marginTop: "18px",
          }}
        >
          Conheça os principais programas do Baú da Felicidade.
        </Typography>
      </ContainerBox>
      {isMobile ? (
        <ContainerBox>
          <CardListMobile arrays={programas} />
        </ContainerBox>
      ) : (
        <CardListProgramas arrays={programas} />
      )}
    </>
  );
};

export default Programas;
