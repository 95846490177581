import { Carousel } from "react-bootstrap";
import { useIsMobile } from "../../utils/utils";
import { CarouselArrows } from "../../components/CarouselArrows/CarouselArrows";
import ContainerBox from "../ContainerBox";
import { timeCarousel } from "../../utils/data";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useNavigate } from "react-router-dom";
import { MenuList } from "@mui/material";

const CarouselItem = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();

  const handleClick = (nameMenuItem: string, url: string | (() => void)) => {
    if (nameMenuItem === "Comprar Carnê") {
      window.location.href = url as string;
    } else {
      navigate("/minha-conta");
      toggleSubMenu(true);
      handleSideBar(nameMenuItem);
    }
  };

  const baseImageName = isMobile ? "mobile-banner" : "banner";

  const carousel = [
    {
      img: `${process.env.REACT_APP_IMAGE_SERVER_URL}${baseImageName}-01.svg`,
      label: "Comprar Carnê",
      url: `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`,
    },
    {
      img: `${process.env.REACT_APP_IMAGE_SERVER_URL}${baseImageName}-02.svg`,
      label: "Pagar Parcelas",
      url: () => {
        handleSideBar("Pagar Parcelas");
      },
    },
    {
      img: `${process.env.REACT_APP_IMAGE_SERVER_URL}${baseImageName}-03.svg`,
      label: "Resgatar Produtos",
      url: () => {
        handleSideBar("Resgatar Produtos");
      },
    },
    {
      img: `${process.env.REACT_APP_IMAGE_SERVER_URL}${baseImageName}-04.svg`,
      label: "Pagar Parcelas",
      url: () => {
        handleSideBar("Pagar Parcelas");
      },
    },
  ];

  return isMobile ? (
    <>
      <ContainerBox
        sx={{
          marginTop: "10px",
          width: "auto",
        }}
      >
        <CarouselArrows variant="dark" indicators>
          {carousel.map((rotate: any, index: any) => (
            <Carousel.Item
              interval={timeCarousel}
              key={index}
              style={{ cursor: "pointer" }}
            >
              {rotate.url ? (
                <>
                  <MenuList
                    onClick={() => handleClick(rotate.label, rotate.url)}
                  >
                    <img
                      className="d-block w-100"
                      style={{
                        padding: "0px 20px 0px 20px",
                        height: "334px",
                        objectFit: "cover",
                      }}
                      src={rotate.img}
                      alt={rotate.label}
                    />
                  </MenuList>
                </>
              ) : (
                <img
                  className="d-block w-100"
                  style={{ height: "334px", objectFit: "cover" }}
                  src={rotate.img}
                  alt={rotate.label}
                />
              )}
            </Carousel.Item>
          ))}
        </CarouselArrows>
      </ContainerBox>
    </>
  ) : (
    <>
      <ContainerBox
        sx={{
          marginTop: "10px",
          width: "1129px",
        }}
      >
        <CarouselArrows variant="dark" indicators>
          {carousel.map((rotate: any, index: any) => (
            <Carousel.Item
              interval={timeCarousel}
              key={index}
              style={{ cursor: "pointer" }}
            >
              {rotate.url ? (
                <>
                  <MenuList
                    onClick={() => handleClick(rotate.label, rotate.url)}
                  >
                    <img
                      className="d-block w-100"
                      style={{
                        padding: "0px 20px 0px 20px",
                        width: "100%",
                        height: "334px",
                        objectFit: "cover",
                      }}
                      src={rotate.img}
                      alt={rotate.label}
                    />
                  </MenuList>
                </>
              ) : (
                <img
                  className="d-block w-100"
                  style={{
                    width: "100%",
                    height: "334px",
                    objectFit: "cover",
                    padding: "0px 20px 0px 20px",
                  }}
                  src={rotate.img}
                  alt={rotate.label}
                />
              )}
            </Carousel.Item>
          ))}
        </CarouselArrows>
      </ContainerBox>
    </>
  );
};

export default CarouselItem;
