import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
`

export const LinkButton = styled.button`
   color: #F08C10;
    background-color: transparent;
    border: none;
    text-decoration-line: underline;
    font-weight: 500;
`
