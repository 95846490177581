import { Typography, Box, Grid } from "@mui/material";
import { useIsMobile } from "../../utils/utils";
import { Container } from "./CardBanner.styled";

const CardBanner = () => {
  const isMobile = useIsMobile();

  const handleClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "link_click",
      click_category: "Consultor",
      click_label: "Quero Ser Consultor(a)",
    });
    window.location.href =
      "https://institucional.jequiti.com.br/seja-uma-consultora/";
  };

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          margin: "40px 0",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={`${process.env.REACT_APP_IMAGE_SERVER_URL}img-consultora.svg`}
              alt={"carne"}
              style={{
                width: `${isMobile ? "300px" : "552px"}`,
                height: `${isMobile ? "auto" : "408px"}`,
              }}
            />
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: `${isMobile ? "center" : "flex-start"}`,
              flexDirection: "column",
              alignItems: `${isMobile ? "center" : "flex-start"}`,
              textAlign: `${isMobile ? "center" : "flex-start"}`,
              flexGroup: 1,
            }}
            className="grid"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: "27px", sm: "38px" },
                fontWeight: 700,
                textTransform: "uppercase",
                margin: `${isMobile ? "20px 15px" : "75px auto 40px auto"}`,
                maxWidth: "550px",
              }}
            >
              REALIZE SEUS SONHOS COM A{" "}
              <span style={{ color: "#F08C10" }}>JEQUITI</span>
            </Typography>
            <Typography
              variant="subtitle1"
              color="#000000"
              sx={{
                fontSize: { xs: "16px", sm: "18px" },
                maxWidth: "550px",
                margin: `${isMobile && "5px"}`,
              }}
            >
              Ser um(a) consultor(a) <strong>Jequiti</strong> é fazer parte de
              uma marca que cresce diariamente.
            </Typography>
            <Typography
              variant="button"
              component="a"
              onClick={handleClick}
              color="#FFFFFF"
              sx={{
                width: "272px",
                height: "48px",
                margin: `${isMobile ? "24px auto" : "20px 0px"}`,
                fontSize: { xs: "16px", sm: "18px" },
                "&: hover": {
                  color: "#FFFFFF",
                },
              }}
            >
              QUERO SER CONSULTOR(A)
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CardBanner;
