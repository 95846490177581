import { Container, LinkButton } from "./MsgBoasVindasPerfil.styled";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useIsMobile } from "../../utils/utils";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import AtualizacaoDados from "./PopupInfoDadosDesatualizados";

const MsgBoasVindasPerfil = () => {
  const isMobile = useIsMobile();
  const { setActiveMenuItem, setIsDrawerOpen, toggleSubMenu, handleSideBar } =
    useHandleSideBar();
  const userNameString = localStorage.getItem("userId");
  const userNameObject = userNameString ? JSON.parse(userNameString) : null;

  const name = userNameObject ? userNameObject.nome : "";

  const handleClick = (section: string) => {
    toggleSubMenu(true);
    handleSideBar(section);
    setActiveMenuItem(section);
    if (isMobile) {
      setIsDrawerOpen(true);
    }
  };

 const dado = localStorage.getItem("invalidData")
  
  return (


    <Container>
      {!isMobile &&  (
        <>

      { dado !== "" && (
        <AtualizacaoDados></AtualizacaoDados>)}
          <div style={{ display: "flex", alignItems: "center" }}>
            {isMobile && (
              <ArrowBackIosIcon
                // onClick={handleDrawer}
                sx={{ fontSize: "32px", color: "#1C1C7B" }}
              />
            )}
            <Typography variant="h1">Olá, {name}</Typography>
          </div>
          <Typography
            component="p"
            variant="subtitle1"
            color="#000000"
            sx={{
              fontSize: "16px",
              marginTop: 4,
            }}
          >
            Agora que você já realizou seu cadastro, você poderá
            <LinkButton onClick={() => handleClick("Cadastrar Carnê")}>
              cadastrar aqui
            </LinkButton>{" "}
            seu carnê. Você também poderá visualizar e editar todos os dados da
            sua conta, como:
            <LinkButton onClick={() => handleClick("Meus Pedidos")}>
              seus pedidos
            </LinkButton>
            ,
            <LinkButton onClick={() => handleClick("Meus Dados")}>
              atualizar suas informações
            </LinkButton>
            ou
            <LinkButton onClick={() => handleClick("Consultar Carnês")}>
              acompanhar
            </LinkButton>
            e
            <LinkButton onClick={() => handleClick("Consultar Carnês")}>
              gerenciar seus carnês
            </LinkButton>
            e muito mais!
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            color="#000000"
            sx={{
              fontSize: "16px",
              marginTop: 2,
            }}
          >
            Minha Conta: Onde você pode consultar as informações da sua conta.
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            color="#000000"
            sx={{
              fontSize: "16px",
              marginTop: 2,
            }}
          >
            Carrinho. Veja os produtos que você já adicionou à sua sacola de
            compras.
            <br /> <br />E muito mais!
          </Typography>
        </>
      )}
    </Container>
  );
};

export default MsgBoasVindasPerfil;
